#customer-management-filter-neww .container-checkbox {
  display: inline;
  line-height: 30px;
  padding-left: 25px;
}

#customer-management-filter-neww .checkmark {
  display: inline;
  line-height: 30px;
  height: 17px !important;
  width: 17px !important;
  border: 1px solid #f7b924 !important;
  border-radius: unset !important;
}
.date-pickerr{
  width: 220.17px !important;
}
#customer-management-filter-neww .container-checkbox .checkmark:after {
  left: 5px;
  top: 2px;
}

#customer-management-filter-neww .container-checkbox input:checked~.checkmark {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Style the checkmark/indicator */

#customer-management-filter-neww .container-checkbox .checkmark:after {
  left: 5px;
  top: 0.9px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* dropdown checkbox */
#customer-management-filter-neww .custom-drop-down .container-checkbox  .checkmark:after{
  left: 3px;
  top: 0px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

#customer-management-filter-neww .custom-drop-down .checkmark {
  display: inline;
  line-height: 30px;
  height: 13px !important;
  width: 13px !important;
  border: 1px solid #f7b924 !important;
  border-radius: unset !important;
}

#customer-management-filter-neww input::placeholder {
  text-transform: none !important;
}

#customer-management-filter-neww .control-input {
  width: 100%;
  height: 46px;
}

#customer-management-filter-neww .filter-section-title {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#customer-management-filter-neww .padding-top-bottom-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

#customer-management-filter-neww .field-label {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-top: 15px;
}

#customer-management-filter-neww .option-text {
  font-family: Helvetica;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 10px; */
  letter-spacing: normal;
  color: #495057;
}

#customer-management-filter-neww .border-yellow {
  border-color: #fdbb1f;
  margin-bottom: 10px;
  margin-top: 10px;
}

#customer-management-filter-neww .report-search {
  position: absolute;
  top: 43px;
  right: 25px;
}

#customer-management-filter-neww .radio-center {
  display: flex;
  align-items: center;
}

#customer-management-filter-neww .select {
  position: relative;
  -webkit-appearance: none;
  background: url("/images/down-arrow.svg") no-repeat right #fff;
  background-position-x: 96%;
  font-family: Helvetica;
}

#customer-management-filter-neww .filter-section-sub-title {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #838c95;
  margin-top: 4px;
}

#customer-management-filter-neww .calendar-icon {
  height: 22px;
  width: 21px;
  padding-top: 5px;
}

#customer-management-filter-neww .input-group-text {
  border-left: 0px;
  padding: 0px;
  background-color: white;
}

#customer-management-filter-neww .calendar-input {
  /* border-right: 0px; */
  width: 122px;
}

#customer-management-filter-neww .form-group {
  margin-bottom: 0px;
}

#customer-management-filter-neww .padding-left-3 {
  padding-left: 3px;
}

#customer-management-filter-neww .link-blue {
  font-weight: bold;
}

#customer-management-filter-neww .vehicle-type-checkbox-div {
  min-width: 78px;
  width: auto;
  display: inline-block;
  padding-right: 10px;
}

#customer-management-filter-neww .usage-type-checkbox-div {
  width: auto;
  display: inline-block;
  padding-right: 10px;
}

#customer-management-filter-neww .data-container {
  overflow-y: auto;
  height: 82%;
  height: calc(100% - 126px);
}

#customer-management-filter-neww .freeze-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

#customer-management-filter-neww .manual-customer {
  /* width: 505px; */
  /* height: 210px; */
  opacity: 0.4;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: white;
  /* position: absolute; */
}

#customer-management-filter-neww .drop-down-control {
  font-size: 12px !important;
}

#customer-management-filter-neww .error-height {
  height: 25px;
}

#customer-management-filter-neww .react-datepicker-popper {
  left: 0px !important;
}

#customer-management-filter-neww .react-datepicker__day-name,
#customer-management-filter-neww .react-datepicker__day,
#customer-management-filter-neww .react-datepicker__time-name {
  width: 22px;
  line-height: 17px;
  font-size: 12px;
}

#customer-management-filter-neww  .custom-drop-down{
  position: absolute;
  z-index: 105;
  background-color: white;
  width: max-content;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.37);
  padding: 5px;
  margin-top: 3px;
  border-radius: 5px;
}

#customer-management-filter-neww  .custom-drop-down-arrow{ 
    position: absolute;
    top: 6px;
    right: 11px;
}

#customer-management-filter-neww .width-75{
  width:75%
}

#customer-management-filter-neww .display-inline{
  display:inline-block;
}

#customer-management-filter-neww .switch-data{
  margin-top: 0px;
  display: inline-block;
  width: 50%;
  height: 100%;
  cursor:pointer;
}

#customer-management-filter-neww .switch-container{
  width: 50%;
  height: 42px;
  border:1px solid black;
  padding:5px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  text-align:center;
  line-height: 27px;
  margin-bottom: 15px;
  color:#495057;
}

#customer-management-filter-neww .active-switch{
 color:white;
 font-weight:bold;
 background-color:#fdbb20;
 border-radius:5px;
}

#customer-management-filter-neww .margin-top-29{
  margin-top: 29px;
}

#customer-management-filter-neww .container-rb{
  display:inline-block;
  line-height: 15px;
}

#customer-management-filter-neww .ratings-selected {
  padding: 5px 9px;
    background-color: #f7b924;
    border-radius: 50%;
    margin-right: 8px;
    color:#495057;
    border:1px solid #f7b924;
}

#customer-management-filter-neww .ratings {
  padding: 5px 9px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 8px;
  color:#495057;
  border:1px solid #ced4da;
  cursor:pointer
}

#customer-management-filter-neww ::-webkit-scrollbar {
  width: 7px;
}
#customer-management-filter-neww .overdue-note{
  font-family: Helvetica;
font-style: italic;
font-weight: normal;
font-size: 12px;
line-height: 136.3%;
color: #495057;
}