.merchandise-wallet-count-card {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(120deg, #ffdf42, #fdbb1f);
  min-width: 223px;
  min-height: 130px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.merchandise-wallet-count-lbl-wrapp {
  padding: 20px;
  padding-left: 30px;
  padding-top: 30px;
}

.merchandise-wallet-coins-lbl {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.merchandise-wallet-coins-count {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-top: 5px;
}

.merchandise-wallet-col-count {
  padding-left: 7px !important;
  padding-right: 7px !important;
  min-width: 223px;
  min-height: 130px;
}

.cart-count {
  opacity: 0.4;
  font-family: Helvetica;
  font-size: 23.4px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
  margin-left: 10px;
}

.Add-items-to-your-ca {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.cart-count-wrapp {
  padding-top: 50px;
}

.Add-items-cart-wrapp {
  padding: 30px;
}

.Car-Charger {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.coins-per-piece {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.Add-items-cart-img {
  width: 136px;
  height: 135px;
  object-fit: contain;
  margin: auto;
}

.add-to-cart-btn {
  margin-left: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
  width: 100%;
}

.Add-items-cart-col {
  background: #ffffff;
  min-width: 270px;
  margin-top: 15px;
  min-height: 380px;
}

.Minimum-Qty {
  font-family: Helvetica;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
  margin-top: 11px;
  margin-right: 8px;
}

.up-down-img {
  height: 13px;
  margin: auto;
  width: 20px;
}

.min-input-qty {
  width: 65px;
}

.Merchandise-catalogu-link {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
  margin-top: 5px;
}

.cart-td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
  border: solid 0.9px #e9ecef;
}

.cart-th {
  border: solid 0.9px #e9ecef;
  background-color: #f6f6f6;
}

.cart-lbl {
  width: 37px;
  height: 22px;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

.Select-Shipping-Addr {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  margin-top: 5px;
}

.Select-Shipping-Addr-header {
  border-bottom: solid 1px #ced4da;
  padding: 17px;
  padding-bottom: 7px;
  display: flex;
  justify-content: space-between;
}

.Select-Shipping-Addr-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  margin-top: 5px;
}

.Select-Shipping-Addr-header-add-address {
  border-bottom: solid 1px #ced4da;
  padding-top: 7px;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 7px;
}

.ShippingAddressTitle {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #343a40;
}

.addressTitle {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  max-width: 300px;
}

.ShippingAddressTitleNote {
  font-family: Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #343a40;
}

.shippingNoteTextColor {
  color: #545cd8;
  font-weight: 500;
  font-size: 14px;
}

.ShippingAddress {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  margin-left: 10px;
  max-width: 300px;
}

.Add-New-Address {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

@media screen and (min-width: 220px) and (max-width: 991px) {
  .merchandise-wallet-col-count {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .ShippingAddress {
    max-width: 100%;
  }
}

#opt-model .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

#opt-model .modal-body {
  width: 100%;
  position: relative;
  flex: 1 1 auto;
  padding: 0px;
}