/* ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

ul li {
  margin: 3px; */

/* } */

.logout {
  /* position: fixed; */
  bottom: 0px;
  /* margin-left: 14px; */
}

.side-nav-profile {
  min-height: 103px;
  display: flex;
  background-color: #fdbb1f;
  margin-left: -24px;
  margin-right: -24px;
}

.side-nav-employee {
  min-height: 103px;
  display: flex;
  background-color: #fdbb1f;
  margin-left: -24px;
  margin-right: -24px;
}

.sidebar-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-nav-profile-img {
  width: 64px;
  height: 64px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 25px;
  object-fit: contain;
}

.side-nav-profile-name {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.25;
  letter-spacing: 0.13px;
  color: #4a5157;
}

.side-nav-profile-view {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: #4a5157;
  margin-top: -7px;
}

.side-nav-profile-name-wrapp {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.whats-new-nav-link {
  height: 47px !important;
  border-radius: 1px;
  background-color: #feedce;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a5157 !important;
}

a:hover {
  color: blue;
}

/* =================================================================== */

.vertical-nav-menu .metismenu-container .metismenu-link:hover {
  background: none !important;
}

.hr-side-nav {
  display: none;
}

/* .app-sidebar:hover .metismenu-container .visible {
  border-bottom: 1px #fdbb1f solid;
  border-top: 1px #fdbb1f solid;
} */

.metismenu-container .visible {
  position: relative !important;
}

.app-sidebar .metismenu-container.visible::before {
  content: "" !important;
  width: 85% !important;
  height: 1px !important;
  background-color: #fdbb1f !important;
  position: absolute !important;
  left: 30px !important;
  top: 0;
}

.app-sidebar .metismenu-container.visible::after {
  content: "" !important;
  width: 85% !important;
  height: 1px !important;
  background-color: #fdbb1f !important;
  position: absolute !important;
  left: 30px !important;
  bottom: 1px;
}

.vertical-nav-menu .metismenu-container .metismenu-container.visible:before {
  /* display: none; */
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-container.visible {
  padding: 0;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-container.visible>.metismenu-item>.metismenu-link {
  padding: 0;
}

.metismenu-container .visible {
  margin-left: 0px !important;
  /* margin-top: 20px !important; */
  padding: 0 !important;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  margin-bottom: 15px !important;
}

.title {
  height: 18px !important;
  font-family: Helvetica !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #4a5157;
  margin-top: 5px;
  cursor: pointer;
}

.title-withought-bold {
  margin-top: 44px;
  height: 18px !important;
  font-family: Helvetica !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #4a5157;
  margin-top: 5px;
  cursor: pointer;
}

.logout_title {
  height: 18px !important;
  font-family: Helvetica !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #4a5157;
  margin-top: 10px;
  cursor: pointer;
}

.sub-menu {
  width: 261px;
  /* white-space: pre-wrap; */
  height: 18px;
  font-family: Helvetica;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #838c95;
  margin-top: "-12px";
}

/* .arrow {
  width: 8px;
  height: 16px;
  transform: rotate(90deg);
  border: solid 2px var(--orangey-yellow);
} */

.vertical-nav-menu .metismenu-container i.metismenu-state-icon, .vertical-nav-menu .metismenu-container i.metismenu-icon {
  opacity: 10000 !important;
}

.metismenu-state-icon .rotate-minus-90 {
  color: #fdbb1f !important;
}

.sub-menu-title {
  width: 126px;
  height: 17px;
  opacity: 0.8;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a5157;
  cursor: pointer;
  margin-top: 7px;
}

.margin-top-22 {
  margin-top: 20px !important;
}

.vertical-nav-menu .metismenu-container .metismenu-container {
  display: none;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-container.visible {
  display: block;
}

.vertical-nav-menu .metismenu-container i.metismenu-state-icon.rotate-minus-90 {
  color: #fdbb1f !important;
  transform: rotate(-360deg) !important;
}

.rotate-to-right {
  transform: rotate(-90deg);
}

.vertical-nav-menu {
  width: 350px;
}

.vertical-nav-menu .metismenu-container i.metismenu-state-icon, .vertical-nav-menu .metismenu-container i.metismenu-icon {
  font-size: 2.4rem !important;
}

.pe-7s-angle-down {
  transform: rotate(-90deg) !important;
}

.closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-link {
  text-indent: initial;
  padding: 0 1.5rem 0 35px !important;
}

/* .metismenu-link > .metismenu-state-icon {
  font-size: 20px !important;
} */

.metismenu-item>.metismenu-link>.sub-menu-title+.metismenu-state-icon {
  font-size: 1.8rem !important;
}

.active-color {
  color: #fdbb1f;
}

.vertical-nav-menu {
  margin-bottom: 30px;
}