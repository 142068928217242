.min-width-105px {
  max-width: 120px;
}

.min-width-100px {
  min-width: 100px
}

.vehicle-feild {
  width: 100px;
  max-width: 100px;
  padding-right: 10px;
  padding-bottom: 12px;
}

.load-type {
  width: 150px;
  max-width: 150px;
  padding-right: 10px;
  padding-bottom: 12px;
}

.load-type .form-control {
  font-size: 14px !important;
}

@media (max-width: 500px) {
  .vehicle-feild {
    width: 100%;
    max-width: 100%;
    padding-right: 10px;
  }
  .load-type {
    width: 100%;
    max-width: 100%;
    padding-right: 10px;
    font-size: 14px !important;
  }
}