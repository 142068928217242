.link-warm-blue {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545cd8;
}

.entries-selected {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

.entries-selected .color-757575 {
  color: #757575;
}

.customer-mg-card .entries-selected {
  margin-top: 10px;
  margin-left: 8px;
}

.entries-selected:hover {
  cursor: pointer;
}

.Rows-per-page {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9e9e9e;
  margin-top: 10px;
  padding-right: 5px;
}

.custom-rc-tab-active {
  background-color: #fdbb1f;
  padding: 12px;
  width: max-content;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 16px 26px -10px rgba(253, 187, 31, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(253, 187, 31, 0.2);
}

.custom-rc-tab-deactive {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  padding-top: 10px;
}

.custom-rc-tab-deactive:hover {
  cursor: pointer;
}

.group-chip {
  height: 19px;
  border-radius: 9.5px;
  background-color: #eeeeee;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
  padding: 4px;
  padding-left: 8px;
  padding-right: 9px;
  margin-right: 6px;
}

.group_count {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
}

.add-new-vehicle-model .dropdown-menu {
  min-width: 11.4rem;
}

.add-new-vehicle-model .rbt-input {
  padding-right: 30px;
}

.tbl-add-new-vehicle th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

.tbl-add-new-vehicle td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

.filter-type-lbl {
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #475466;
  text-transform: capitalize;
}

.disable-color {
  color: #495057;
}

.customer-mgt-filter .form-control:disabled {
  color: #6e777f;
  opacity: 0.4;
}

.customer-mgt-filter .disable-lbl {
  color: #6e777f;
  opacity: 0.4;
}

.customer-list-row {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.customer-list-table-header {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

.border-1-5 {
  border: solid 1.5px #e9ecef;
}

.no-data-hypen {
  width: 6px;
  height: 0;
  border: 1px solid #757575;
  display: flex;
}

.rows-per-page-select {
  width: 46px !important;
  font-size: 14px !important;
  padding: 0 !important;
  height: 26px !important;
  margin-top: auto !important;
  padding-left: 5px !important;
  margin-bottom: auto !important;
}

.css-kj6f9i-menu {
  position: absolute;
}

.add-customer-date .react-datepicker-wrapper {
  z-index: unset;
}

.block-customer-entry {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

.add-to-group-modal {
  position: Fixed !important;
  width: 500px;
  max-width: 90%;
  overflow-y: hidden !important;
}

.date-picker-left-15 .react-datepicker-popper {
  left: -15px !important;
}

.date-picker-left-plus-15 .react-datepicker-popper {
  left: 30px !important;
}

.custom-drawer-content-wrapper {
  height: 74%;
  overflow: auto;
}

.search-input input::placeholder {
  text-transform: none !important;
}

.apply-filter-dot {
  margin-top: 6px;
  margin-left: 12px;
  height: 5px;
  position: absolute;
  width: 5px;
}

.footer-drawer {
  margin-top: 5px;
  background-color: whitesmoke;
  padding-top: 12px;
  padding-bottom: 7px;
}

.sub-value-table {
  font-family: Helvetica;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-top: 5px;
  letter-spacing: normal;
}

.sub-value-table-purpose {
  color: #495057;
}

.sub-value-table-service {
  color: #838c95;
}

.sub-value-more {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

.customer-list-th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

.customer-list-td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

.customer-list-action-th {
  padding-left: 4px !important;
  padding-right: 0px !important;
  width: 60px;
}

.customer-list-action-menu {
  position: absolute;
  top: 34px;
  left: -91px;
  box-shadow: 1px 1px 16px 2px rgba(0, 0, 0, 0.1);
  z-index: 0;
  background-color: white;
}

.customer-list-action-menu-content {
  padding: 10px;
  /* background-color: white; */
  z-index: 10;
  font-family: Helvetica;
  font-size: 12px;
  text-align: center;
  color: #495057;
}

.group-count {
  cursor: pointer;
  color: #545cd8;
  font-weight: bold;
}

.customer-mg-card .filter-icon {
  height: 34px;
}

.customer-mg-card .filter-icon-selected {
  height: 34px;
}

.question-mark {
  color: #FDBB1F;
  font-size: 16px;
  font-weight: bold;
}



.__react_component_tooltip.type-dark {
  background-color: #000000;
  /* font-weight: bold; */
}

#customer-list-tooltip [data-tooltip] {
  cursor: pointer !important;
  display: inline-block;
  position: relative;
  white-space: pre;
}
/* Tooltip styling */
#customer-list-tooltip [data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.4;
  /* width: 185px; */
  /* max-width: 100px; */
  text-align: left;
  border-radius: 4px;
  padding-left: 10px;
    padding-right: 15px;
}
/* Dynamic horizontal centering */
#customer-list-tooltip [data-tooltip-position="top"]:before,
#customer-list-tooltip [data-tooltip-position="bottom"]:before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
#customer-list-tooltip [data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 6px;
}

/* Tooltip arrow styling/placement */
#customer-list-tooltip [data-tooltip]:after {
  content: '';
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
/* Dynamic horizontal centering for the tooltip */
#customer-list-tooltip [data-tooltip-position="top"]:after,
#customer-list-tooltip [data-tooltip-position="bottom"]:after {
  left: 50%;
  margin-left: -6px;
}

#customer-list-tooltip [data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}
/* Show the tooltip when hovering */
#customer-list-tooltip [data-tooltip]:hover:before,
#customer-list-tooltip [data-tooltip]:hover:after {
  font-size: 11px;
  display: block;
  z-index: 50;
}
