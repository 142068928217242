/* .Rectangle {
  width: 346px;
  height: 38px;
  border-radius: 5px;
  border: solid 1px #ced4da;
  background-color: #ffffff;
} */

#forgot-password .welcome-text {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
  padding-left: 13px;
}

#forgot-password .sign-in-subtitle-text {
  /* width: 232px; */
  height: 20px;
  font-family: Helvetica;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  padding-left: 13px;
}

#forgot-password .Recover-Password {
  width: 126px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

#forgot-password .Rectangle-button {
  width: 140px;
  height: 35px;
  border-radius: 17.5px;
}

#forgot-password .send-recovery-link {
  height: 35px;
  border-radius: 17.5px;
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
  padding: 2px 30px;
}

#forgot-password .login-left-img{
 /* height: -webkit-fill-available; */
 width: 100%;
}

#forgot-password{
  background-color: white;
  height: -webkit-fill-available;
}

#forgot-password .forgot-password-container{
  display: flex !important; 
  align-items: center !important; 
  justify-content: center !important;
}

#forgot-password .forgot-password{
  /* width: 155px; */
  height: 35px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 2px 30px;
}

#forgot-password .link{
  text-decoration: underline;
  color: #545cd8;
}