.title-small {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  color: '#49505';
}

.Note-This-figures-s {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: oblique;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  padding: 10px;
  padding-right: 20px;
  border-top: solid 1px #979797;
}

.heigh-95-percentage {
  height: 95%;
}

.tbl-HODashboardClassWiseCard th {
  vertical-align: text-top !important;
}

.custom-rc-tab-active {
  background-color: #fdbb1f;
  padding: 33px;
  width: max-content;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 16px 26px -10px rgba(253, 187, 31, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(253, 187, 31, 0.2);
}

.custom-rc-tab-deactive {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  padding-top: 10px;
}

.custom-rc-tab-deactive:hover {
  cursor: pointer;
}

.Product-wise {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 8px;
  color: var(--gunmetal);
}

.custom-rc-tab {
  margin-left: 15px;
}

.title-class-wise {
  font-size: 12px;
  color: #495057;
}

.hyper-link {
  color: #545cd8;
  font-family: Helvetica;
  text-decoration: underline;
}

.width-44px {
  width: 44px
}

.width-172px {
  width: 172px;
}

.width-128px {
  width: 128px;
}

.padding-right-35px {
  padding-right: 35px !important;
}

.width-55px {
  width: 55px;
}

.border-right-217 {
  border-right: 1px solid rgb(217, 217, 217);
}

