#primary-point-all-delear-report .card-margin{
margin: 18px 0px;
}

#primary-point-all-delear-report .card-bg-image{
  background-image: url('/images/card-bg.svg');
  background-size: 192%;
  background-position: center;
}

#primary-point-all-delear-report  th,tr{
  width: 100px !important;
  border: 1px solid rgb(0,0,0,0.1);
}

#primary-point-all-delear-report  td{
  border: 1px solid rgb(0,0,0,0.1);
}

#primary-point-all-delear-report .padding-2{
  padding: 2px;
}

#primary-point-all-delear-report th{
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color:#f6f6f6
}

#primary-point-all-delear-report .table-header{
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}


#primary-point-all-delear-report td{
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#primary-point-all-delear-report tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

/* .table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
} */

/* .table-striped tbody tr:nth-of-type(odd) {
  background-color: white;
} */