
.profile-sender-id-note{
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  line-height: 146%;
  /* or 15px */
  
  
  color: #495057;
}
.margin-top-n10px{
  margin-top: -10px;
}