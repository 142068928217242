#custom-ho-user-filter .checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-radius: 2px;
}

#custom-ho-user-filter  .container-checkbox input:checked~.checkmark {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Show the checkmark when checked */

#custom-ho-user-filter .container-checkbox input:checked~.checkmark:after {
  display: block;
}

#custom-ho-user-filter .file-upload-file-name {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  /* text-align: center; */
  color: #6e777f;
  display:inline-block;
  width: calc(100% - 110px);
}

#custom-ho-user-filter .download-upload-excel-button{
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  color: #545cd8;
  cursor:pointer;
}