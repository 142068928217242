#redeem-report .filter-lbl {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #495057;
    /* height: 17px; */
}

#redeem-report .filter-lbl-bold {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#redeem-report .input-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#redeem-report .disabled-input-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#redeem-report .disabled-input-value {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#redeem-report .custom-textarea:focus {
  border-color: #fdbb1f !important;
  box-shadow: 0 1px 1px white inset, 0 0 8px white !important;
  outline: 0 none !important;
  background-color: rgba(253, 187, 31, 0.1) !important;
}

#redeem-report .textarea:focus,
textarea:focus,
input[type="text"]:focus,
input[type="textarea"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: #fdbb1f;
  box-shadow: 0 1px 1px white inset, 0 0 8px white;
  outline: 0 none;
  background-color: rgba(253, 187, 31, 0.1);
}

#redeem-report .year-date-wrapp,
.year-date-wrapp .react-datepicker,
.year-date-wrapp input[type="text"] {
  width: 100%;
}

#redeem-report .text-overflow-dots {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#redeem-report .filled-data-field {
  border-color: #fdbb1f;
  box-shadow: 0 1px 1px white inset, 0 0 8px white;
  outline: 0 none;
}

#redeem-report .box-shadow-apply {
  box-shadow: 0 0.46875rem 2.1875rem rgba(29, 20, 0, 0.03),
    0 0.9375rem 1.40625rem rgba(29, 20, 0, 0.03),
    0 0.25rem 0.53125rem rgba(29, 20, 0, 0.05),
    0 0.125rem 0.1875rem rgba(29, 20, 0, 0.03);
}

#redeem-report .box-shadow-remove {
  box-shadow: unset !important;
}

#redeem-report .input-focus-white:focus {
  background-color: white !important;
}

#redeem-report .err-msg {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: red;
}

#redeem-report .filter-type-lbl-fr {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #475466;
  text-transform: capitalize;
}

#redeem-report .coupon-list-table-header {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

#redeem-report .coupon-list-row {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#redeem-report .label-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

#redeem-report .filter-select {
  height: 40px;
  border-radius: 5px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

#redeem-report .filter-bolde-label {
    height: 17px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--gunmetal);
}

#redeem-report .react-datepicker__month-select, .react-datepicker__year-select {
  color: #141313;
  background-color: white;
  /* border: 1px solid yellow; */
}

#redeem-report .react-datepicker__day--selected {
  background-color: #fdbb1f !important;
  color: #fff !important;
}

#redeem-report .react-datepicker__day--keyboard-selected {
  background-color: #fdbb1f !important;
  color: #fff;
}

#redeem-report .form-group-select {
  font-size: 14px!important;
}

#redeem-report .input-group-text {
  position: absolute !important;
  top: 3px !important;
  right: 4px !important;
  z-index: 101 !important;
  background-color: white !important;
  border: 0px !important;
}

#redeem-report .mail-label {
  height: 19px;
  font-family: Helvetica;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#redeem-report .input-group-text { 
  color: #fdbb1f !important;
}

#redeem-report .css-tlfecz-indicatorContainer {
  color: #fdbb1f !important;
}

#redeem-report [data-tooltip] {
  display: inline-block;
  position: relative;
  cursor: help;
  padding: 4px;
}
/* Tooltip styling */
#redeem-report [data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.4;
  width: max-content;
  /* min-width: 100px; */
  text-align: center;
  border-radius: 4px;
}
/* Dynamic horizontal centering */
#redeem-report [data-tooltip-position="top"]:before,
#redeem-report [data-tooltip-position="bottom"]:before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
/* Dynamic vertical centering */
/* #redeem-report [data-tooltip-position="right"]:before,
#redeem-report [data-tooltip-position="left"]:before {
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
} */
#redeem-report [data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 6px;
}

/* Tooltip arrow styling/placement */
#redeem-report [data-tooltip]:after {
  content: '';
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
/* Dynamic horizontal centering for the tooltip */
#redeem-report [data-tooltip-position="top"]:after,
#redeem-report [data-tooltip-position="bottom"]:after {
  left: 50%;
  margin-left: -6px;
}
/* Dynamic vertical centering for the tooltip */
/* #redeem-report [data-tooltip-position="right"]:after,
#redeem-report [data-tooltip-position="left"]:after {
  top: 50%;
  margin-top: -6px;
} */
#redeem-report [data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}
/* Show the tooltip when hovering */
#redeem-report [data-tooltip]:hover:before,
#redeem-report [data-tooltip]:hover:after {
  font-size: 11px;
  display: block;
  z-index: 50;
}

#redeem-report .hover-download {
  cursor:pointer;
}

#redeem-report .react-datepicker__input-container ::placeholder {
  text-transform: capitalize !important;
}

#redeem-report .select {
  position: relative;
  -webkit-appearance: none;
  background: url("/images/down-arrow.svg") no-repeat right #fff;
  background-position-x: 96%;
  font-family: Helvetica;
}

#redeem-report .textwrap {
  line-height: 1.5;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}