#count-dashboard-mobile .category {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fdbb1f;
}

#count-dashboard-mobile .offtake-color {
  width: 39px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 35px;
  letter-spacing: normal;
  color: #92969a;
}

#count-dashboard-mobile .points{
    font-family: Helvetica;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #495057;
  }

  #count-dashboard-mobile .image{
    object-fit: contain;
    width: 88px;
    height: 46px;
  }