#special-engagement .modal-title {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#special-engagement.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
  /* position: absolute !important; */
}

#special-engagement .modal-content {
  border-radius: 8px !important;
}

/* #special-engagement.modal-dialog-centered {
  display: flex;
  align-items: baseline;
  min-height: calc(100% - 1rem);
} */

#special-engagement .modal-title-border {
  border-bottom: 1px solid #ced4da;
}

#special-engagement .cancel-button {
  width: 116px;
  height: 35px;
  border-radius: 17.5px;
  background-color: #e0e2e4;
  border: none;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6e777f;
}

#special-engagement .send-button {
  width: 116px;
  height: 32px;
  border-radius: 17.5px;
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
}


#special-engagement .title {
  font-size: 14px;
  font-weight: bold;
}

#special-engagement .description {
  font-size: 16px;
}

#special-engagement .question {
  font-size: 14px;
  /* text-align: justify; */
}

#special-engagement .container-rb {
  font-size: 17px;
  font-weight: bold;
  line-height: 17px;
}

#special-engagement .container-quiz-font {
  font-size: 17px;
  font-weight: normal !important;
  line-height: 1.6;
}


/* radio button */

#special-engagement .checkmark-rb {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}

#special-engagement .container-rb .checkmark-rb:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fdbb1f;
}


#special-engagement .img {
  height: 90px;
  border-radius: 5px;
}

#special-engagement .note {
  font-size: 12px;
  color: #2a2e6c;
  font-weight: bold;
  text-align: justify;

}

.sp-en-swal {
  width: 340px !important;
}

#special-engagement .option-css {
  display: flex;
  flex-direction: row;
}

#special-engagement .question-font {
  font-size: 16px;
  font-weight: bold;
}


/* quiz radio button  */

#special-engagement .container-rb-quiz {
  display: inline;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 14px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#special-engagement .checkmark-rb-quiz {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

#special-engagement .container-rb-quiz:hover input~.checkmark-rb-quiz {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

#special-engagement .container-rb-quiz input:checked~.checkmark-rb-quiz {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

#special-engagement .checkmark-rb-quiz:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

#special-engagement .container-rb-quiz input:checked~.checkmark-rb-quiz:after {
  display: block;
}

/* Style the indicator (dot/circle) */

#special-engagement .container-rb-quiz .checkmark-rb-quiz:after {
  top: 4px;
  left: 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdbb1f;
}

#special-engagement .container-rb-quiz input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}