#analysisPage .filterHeading2 {
  margin: 10px;
  text-align: center;
  width:80px;
  font-weight: 600;
}
#analysisPage .filterHeading {
  text-align: center;
  width: 100px;
  font-weight: 600;
  margin: 10px;
}

#analysisPage .alnalysisAreaWrap .anlysisStyle {
  /* padding: 15px;
  min-height: 440px; */
  padding: 10px;
}

#analysisPage .alnalysisAreaWrap .graphAnalysis1 .loader {
  background-color: transparent !important;
}
#analysisPage .alnalysisAreaWrap .graphAnalysis2 .loader {
  background-color: transparent !important;
}
#analysisPage .alnalysisAreaWrap .graphAnalysis1 {
  /* min-height: 410px; */
  display: none;
}

#analysisPage .alnalysisAreaWrap .graphAnalysis2 {
  /* min-height: 410px; */
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) {
  #analysisPage .alnalysisAreaWrap .graphAnalysis1 {
    display: block !important;
  }

  #analysisPage .alnalysisAreaWrap .graphAnalysis2 {
    display: none !important;
  }

}

#analysisPage .filterWrap .filter-body {
  padding: 0 20px;
  max-height: 580px;
  overflow-y: auto;
  border-bottom: 2px solid #ddd;
}

#analysisPage .filterWrap .filter-body p{
padding: 13px 20px;
  font-size: 18px;
  /* margin-bottom: 25px; */
}

#analysisPage .filterWrap .filter-body select {
  position: relative;
  -webkit-appearance: none;
  background: url("/images/down-arrow.svg") no-repeat right #fff;
  background-position-x: 92%;
  font-family: Helvetica;
  margin-bottom: 10px;
  cursor: pointer;
}

#analysisPage .filterWrap .filter-heading {
  font-size: 20px;
  font-weight: 600;

  padding: 5px 30px;
  border-bottom: 2px solid #ddd;
  margin-bottom: 0;
  text-align: center;
}

#analysisPage .filterWrap .row {
  margin: 0 -5px;
}

#analysisPage .filterWrap .row [class*="col-"],
#analysisPage .filterWrap .row [class*="col"] {
  padding: 0 5px;
}

#analysisPage .filterWrap {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  height: 100%;
}

#analysisPage .filterWrap .filterTitle {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

/* Hide the browser's default radio button */

#analysisPage .radioBlockWrap {
  display: flex;
  position: relative;
  /* border: 1px solid #ddd; */
}

#analysisPage .radioBlockWrap .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

#analysisPage .radioBlockWrap .container {
  padding-left: 22px;
  cursor: pointer;
  /* font-size: 12px; */
}

/* Create a custom radio button */

#analysisPage .radioBlockWrap .checkmark {
  position: absolute;
  /* top: 0;
  left: 0; */
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */

#analysisPage .radioBlockWrap .container:hover input ~ .checkmark {
  background-color: #fbe8df;
  /* color:#495057; */
}

/* When the radio button is checked, add a blue background */

/* #analysisPage .radioBlockWrap .container input:checked ~ .checkmark {
  background-color: #fff;
} */

/* Create the indicator (the dot/circle - hidden when not checked) */

#analysisPage .radioBlockWrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

#analysisPage .radioBlockWrap .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

#analysisPage .radioBlockWrap .container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdbb1f;
}

#analysisPage .radioInnerTitle {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
  mix-blend-mode: normal;
}

#analysisPage .font-bold {
  font-weight: 600;
}

#analysisPage .shade {
  border: 1px solid #ddd;
  background-color: rgb(223, 223, 223);
  /* font-weight: 500; */
  cursor: pointer;
  padding: 8px 20px 8px 10px;
  margin: 0;
}
#analysisPage .shade2 {
  /* background-color: rgba(253, 197, 13, 0.25); */
  background-color: #ffd89f;
  /* background-color: rgb(240 211 167); */
  border: 1px solid #ddd;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 20px 8px 10px;
  margin: 0;
}

#analysisPage .alnalysisAreaWrap {
  margin-top: 20px;
}

#analysisPage .alnalysisAreaWrap .analysisBlock {
  background-color: #fff;
  border: 1px solid #ddd;
  /* border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; */
  border-top: none;
  padding: 20px;
  /* position: relative; */
}

#analysisPage select:focus,
.analysisBlock input:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: orange !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(253, 197, 13, 0.25) !important;
}
