.view-vehicle {
  width: 600.5px;
  height: 148px;
  border-radius: 6px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
}

.ADV0363 {
  width: 81px;
  height: 47px;
  object-fit: contain;
  margin-top: 22px;
}

.email-address {
  width: 336px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.select-group {
  width: 160px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.padding-top-0px {
  padding-top: 0% !important;
}

.Fix-height {
  min-height: calc(80vh - 100px);
  max-height: calc(80vh - 100px);
  overflow-y: scroll;
}

.align-center {
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}