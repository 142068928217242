.modal-title {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}
.modal-title-border {
  border-bottom: 1px solid #ced4da;
}
.section-border {
  margin: 10px -5px 0px -5px;
  padding: 13px;
  border-radius: 8px;
  border: solid 1px #ced4da;
}
.padding-left-0 {
  padding-left: 0px;
}
.padding-right-0 {
  padding-right: 0px;
}
.poster-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#poster-preview-modal {
  width: fit-content !important;
  height: auto !important;
}

#poster-preview-modal .modal-content {
  width: auto;
}
#poster-preview-modal .modal-dialog {
  max-width: max-content;
}
