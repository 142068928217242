#poster .category-bg {
  background-color: rgba(241, 244, 246, 0.8);
}

#poster .menu-title {
  width: 36px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#poster .category-title {
  text-align: center;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 29px;
  letter-spacing: normal;
  color: #495057;
  margin-left: -15px;
  padding-right: 15px;
  width: 100%;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(110, 119, 127, 0.2);
}

#poster .category {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#poster .sub-category {
  margin-left: 10px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057db;
}

#poster .category-selected {
  font-weight: bold;
}

#poster .gift-bg {
  background-color: white;
  margin-top: -29px;
  padding-top: 30px;
}

#poster .logo-thumb-img {
  height: 260px;
  object-fit: contain !important;
  margin-top: 15px;
}

#poster .poster-preview {
  position: Fixed !important;
  width: 450px;
  max-width: 90%;
  overflow-y: unset !important;
}

#poster .point-bg {
  width: 125px;
  height: 18px;
  border-radius: 9px;
  background-color: #fdbb1f63;
  border-radius: 9px 0px 0px 9px;
  line-height: 18px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.37px;
  color: #495057;
  position: absolute;
  right: -5px;
  top: -11px;
  text-align: center;
}

#poster .label-bg {
  width: 50px;
  height: 18px;
  border-radius: 9px;
  background-color: #fdbb1f;
  border-radius: 0px 9px 9px 0px;
  line-height: 18px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.37px;
  color: #495057;
  position: absolute;
  left: -5px;
  top: -11px;
  text-align: center;
}

#poster .label-date {
  width: 50px;
  height: 18px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-radius: 0px 9px 9px 0px;
  line-height: 18px;
  font-family: Helvetica;
  font-size: 12px;
  /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.37px;
  color: #495057;
  position: absolute;
  left: -5px;
  top: -11px;
  text-align: center;
}

#poster .card-title {
  /* width: 144px; */
  /* height: 34px; */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.44px;
  margin-top: 20px;
  color: #495057;
}

#poster .content-center {
  /* display: flex;
    justify-content: center;
    align-items: center; */
  margin: auto;
  padding: 0px;
}

#poster .code {
  /* width: 103px; */
  height: 12px;
  font-family: Helvetica;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#poster .description {
  /* width: 103px; */
  /* height: 12px; */
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#poster .rc-slider {
  width: 89%;
}

#poster .card-shadow {
  box-shadow: 0 0 5px 0 #6e777f;
  margin-left: 5px;
  margin-right: 5px;
}

#poster .back-to-top-button {
  position: fixed;
  top: 100px;
  z-index: 100;
}

#poster .more-css {
  font-size: 14px;
  text-decoration: underline;
}

#poster .redeem-points {
  text-align: center;
  color: #545cd8;
  line-height: 18px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
}

/* .input-range__label-container {
  left: -50%;
  position: relative;
  font-family: Helvetica;
}

.input-range__slider-container {
  transition: none !important;
}

.input-range__track {
  transition: none !important;
}

.input-range__slider {
  transition: none !important;
  background: #fff;
  border-color: #4950572e;
  transform: scale(1.3);
}

.input-range__label {
  color: #495057;
}

.input-range {
  width: 90%;
}

.input-range__track--active {
  background: #fdbb1f;
}
.input-range__label--min {
  left: 0;
  display: none;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
  display: none;
}

.min-input-range {
  margin-right: 10%;
}

.max-input-range {
  margin-left: 10%;
}

.infinite-loader-css {
  width: 100%;
  text-align: center;
  margin-top: 18px;
  color: #fdbb1f;
} */

#EntryScreenCatelog .modal {
  display: block;
  max-height: 98%;
  overflow: hidden;
}
/* .highlight-text {
  color: #fdbb1f;
}

.points-range {
  padding: 0px;
  margin-top: 20px;
  margin-left: -30px !important;
} */

#inputRange .input-range__label-container {
  display: none;
}

/* .minValue {
  font-size: 10px;
  margin-left: -3px;
}

.maxValue {
  font-size: 10px;
  float: right;
}

.error-msg {
  font-size: 12px;
  color: red;
  font-family: Helvetica;
  margin-top: -10px;
  margin-left: 15px;
} */

#poster .note {
  font-size: 12px !important;
}

#poster .title {
  font-weight: bold;
}

#poster .new-tag {
  padding-left: 10px;
  border: 1px solid red;
  border-radius: 13px;
  width: fit-content;
  padding-right: 10px;
  color: red;
}

#poster textarea::placeholder {
  font-family: Helvetica;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}
