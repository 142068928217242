.AddWhatsappTemplates{
  .whatsapp-message  {
    background-color: #fff;
    width: 100%;
    height: 100%;
    // display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .whatsapp-message .msg-header {
    display: none;
    background-color: #ccd0d5;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80px 80px;
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 207px;
    min-height: 125px;
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
    position: relative;
    word-wrap: break-word;
    margin-bottom: 4px;
  }
  .whatsapp-message .whatsapp_widget_chat_wrapper{
      background-color: rgb(187, 187, 187);
      height: 560px;
            overflow: auto;
      background: url("/images/wa_bg.jpg");
  }
  
  .whatsapp-message .whatsapp_widget_chat_wrapper_inner{
    height: max-content;
  }
  .whatsapp-message  .whatsapp_widget_footer{
    display: none;
  }
  .whatsapp-message .whatsapp_widget_icon{
    display: none;
  }
  .whatsapp-message  .whatsapp_widget_header_close_btn{
    display: none;
  }
  // .whatsapp_widget_wrapper{
  //   height: 665px;
  // }
  .media_type{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    line-height: 1.4285em;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    visibility: visible;
    font-size: 1rem;
    -webkit-box-direction: normal;
    box-sizing: inherit;
    background-color: #fff;
    border: 1px solid #dadde1;
    border-radius: 10px;
    border-width: 2px;
    margin-right: 12px;
    cursor: pointer;
    height: 160px;
    max-width: 160px;
    min-width: 160px;
    justify-content: center;
    padding: 0px !important;
    color: rgba(0, 0, 0, 0.85);
    position: relative;
    width: 6.25%;
    vertical-align: top;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    -webkit-box-flex: 1;
    flex-grow: 1;
    .segment{
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-text-size-adjust: 100%;
      line-height: 1.4285em;
      font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      visibility: visible;
      -webkit-box-direction: normal;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 300;
      cursor: pointer;
      box-sizing: inherit;
      background: #fff;
      margin: 1rem 0;
      border-radius: .28571429rem;
      font-size: 1rem;
      background-color: transparent !important;
      border: 0px !important;
      box-shadow: none !important;
      position: relative;
      padding: 5px !important;
      margin-bottom: 0;
      text-align: center;
    }
    .media_type_label{
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-text-size-adjust: 100%;
      line-height: 1.4285em;
      font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      visibility: visible;
      -webkit-box-direction: normal;
      color: rgba(0, 0, 0, 0.85);
      box-sizing: inherit;
      max-width: 100%;
      margin-bottom: 5px;
      display: block;
      position: relative;
      border-radius: 10px;
      font-weight: 300;
      font-size: 1.35em;
      -webkit-transition: all 0.25s linear;
      z-index: 9;
      cursor: pointer;
      height: 160px;
      padding: 16px 24px;
    }
    .media_desc{
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-text-size-adjust: 100%;
      visibility: visible;
      -webkit-box-direction: normal;
      cursor: pointer;
      box-sizing: inherit;
      font-family: Roboto, Arial, sans-serif;
      font-size: 0.875rem;
      line-height: 1.42857;
      letter-spacing: normal;
      font-weight: bold;
      overflow-wrap: normal;
      text-align: left;
      color: inherit;
      display: inline-block;
      margin-top: 10px;
    }
     
  }
  .whatsapp_widget_wrapper{
    position:  inherit !important;
    bottom: 35px !important;
    margin-top: -30px;
  }
  .left-panel{
    display: flex;
    overflow: auto;
    max-height: 76vh;
    flex-direction: column;
    padding-bottom: 40px;
  }
  .right-panel{
    // display: flex;
    // overflow: auto;
    // max-height: 76vh;
    // flex-direction: column;
    padding-bottom: 40px;
  }
  .whatsapp_widget_header{
    padding: 10px;
  }
  .whatsapp_widget_header_icon{
    background-color: inherit !important;
  }
}