#target-user-modal .modal-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  color: #495057;
}

#target-user-modal .modal-title-border {
  border-bottom: 1px solid #ced4da;
}

#target-user-modal .request-details {
  width: 99px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  color: #495057;
}

#target-user-modal textarea::placeholder {
  font-family: Helvetica;
  font-size: 14px;
  color: #6e777f;
}

#target-user-modal .cancel-button {
  width: 116px;
  height: 35px;
  border-radius: 17.5px;
  background-color: #e0e2e4;
  border: none;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #6e777f;
}

#target-user-modal .send-button {
  width: 116px;
  height: 35px;
  border-radius: 17.5px;
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #495057;
}

#target-user-modal .container-cb {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
}

/* Hide the browser's default checkbox */
#target-user-modal .container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
#target-user-modal .checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
#target-user-modal .container-cb:hover input ~ .checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
#target-user-modal .container-cb input:checked ~ .checkmark {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Create the checkmark/indicator (hidden when not checked) */
#target-user-modal .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#target-user-modal .container-cb input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#target-user-modal .container-cb .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#target-user-modal th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  color: #545a60;
  border: 1px solid #e9ecef;
}

#target-user-modal td {
  font-family: Helvetica;
  font-size: 12px;
  color: #545a60;
  border: 1px solid #e9ecef;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#target-user-modal thead {
  background-color: rgb(246, 246, 246);
}

#target-user-modal .modal-content {
  width: 600px;
  height: auto;
}

.Download-icon {
  /* margin-left: 73%; */
  margin-left: auto;
  margin-top: 3%;
  cursor: pointer;
  float: right;
  margin-right: 8px;
}

.Group-6 {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.Download-Report {
  width: 100px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: blue;
  margin-left: 4px;
}
