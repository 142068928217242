#banner-list th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  color: #545a60;
  border: 1px solid #e9ecef;
}

#banner-list td {
  font-family: Helvetica;
  font-size: 12px;
  color: #545a60;
  border: 1px solid #e9ecef;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#banner-list thead {
  background-color: rgb(246, 246, 246);
}

#banner-list .filter-container {
  width: 379px;
  border-radius: 5px;
  border: solid 1px #ced4da;
  background-color: #fff;
  padding: 16px;
  position: absolute;
}

#banner-list .margin-top-12{
  margin-top:12px;
}


#banner-list .button {
  width: 48%;
  height: 35px;
  border-radius: 17.5px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #495057;
}


#banner-list .create-button {
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
}

#banner-list .cancel-button {
  border: solid 1px #e0e2e4;
  background-color: #e0e2e4;
}


#banner-list .filter-icon {
  height: 34px;
}

#banner-list .filter-icon-selected {
  height: 34px;
}

#banner-list .padding-left-0{
  padding-left:0px !important;
}

 #banner-list .count{
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  color: #545cd8;
  cursor:pointer;
}

.banner-list-tooltip {
  /* left: 30% !important; */
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 12px;
  color: #cfaaaa;
  width: 250px;
  white-space: break-spaces;
  padding: 10px;
  text-align: left;
  background-color: #000000;
  font-size: 11px;
  opacity: 1 !important;
}
