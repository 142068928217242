.heading {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 18px;
  color: #495057;
  margin: 5px;
}
.chart_heading {
  font-size: 18px;
  font-weight: bold;
  font-family: Helvetica;
  font-style: normal;
  line-height: 21px;
  color: #495057;
}
.chart_duration_heading {
  font-size: 12px;
  font-weight: bold;
  font-family: Helvetica;
  font-style: normal;
  line-height: 14px;
  color: #7f8489;
  padding: 4px 8px;
}

.chart_container{
  margin-bottom:'10px'
}
.custom-x-axis{
  border:1px solid #f1f0f0;
  position: inherit;
  bottom: 92px;
  margin: 0 25px;
}
.custom-legend{
  position: absolute;
  background: white;
  bottom: 28px;
  left:0;
  height: 20px;
  width: 100%;
}

.offtake_circle{
  width:12px;
  height:12px;
  background-color:#949AF6;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 6px;
}

.warranty_circle{
  width:12px;
  height:12px;
  background-color:#FDC321;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 6px;
}

.legend_text{
font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
color: #495057;
}

@media screen and (max-width: 360px) {
  .chart_container{
    margin-bottom:'10px'
  }
}