#trade-scheme-dealer-report th{
  color: #545a60;
  border: solid 0.9px #e9ecef;
}


#trade-scheme-dealer-report th{
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color:#f6f6f6;
}

#trade-scheme-dealer-report td{
  /* width: 25px;
  height: 14px; */
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
  border: solid 0.9px #e9ecef;
}

#trade-scheme-dealer-report input::placeholder {
  text-transform: capitalize !important;
}