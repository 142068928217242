.tab-menu {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

#campaign .container {
  display: inline;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

#campaign .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Hide the browser's default radio button */

#campaign .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

/* 
#campaign .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
} */

/* On mouse-over, add a grey background color */

#campaign .container:hover input~.checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

#campaign .container input:checked~.checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

/* #campaign .checkmark:after {
  content: "";
  position: absolute;
  display: none;
} */

/* Show the indicator (dot/circle) when checked */

/* #campaign .container input:checked~.checkmark:after {
  display: block;
} */

/* Style the indicator (dot/circle) */

/* #campaign .container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdbb1f;
} */

#campaign .block-border {
  border: 1px solid black;
}

#campaign .Rectangle {
  width: 387px;
  height: 50px;
  border: solid 1px #e0e0e0;
  background-color: var(--orangey-yellow);
}

#campaign .RectangleRow {
  width: 358px;
  height: 50px;
  border: solid 1px #e0e0e0;
  background-color: var(--white);
}

#campaign .RectangleYellow {
  width: 179px;
  height: 50px;
  border: solid 1px #e0e0e0;
  padding-top: 12px;
  padding-left: 45px;
  color: #56585a !important;
  background-color: #fdcd1f;
}

#campaign .campaign-tab {
  width: 179px;
  height: 65px;
  border: solid 1px #e0e0e0;
  padding-top: 20px;
  /* padding-left: 80px; */
  text-align: center;
  color: #56585a !important;
}

#campaign .campaign-tab:hover {
  cursor: pointer;
}

.campaign-active-tab {
  background-color: #fdcd1f;
}

#campaign .Rectanglecompleted {
  width: 179px;
  height: 50px;
  border: solid 1px #e0e0e0;
  padding-top: 12px;
  padding-left: 45px;
  color: #56585a !important;
}

#campaign .Scheduled-campaigns {
  /* height: 158px; */
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: oblique;
  line-height: 1.28;
  letter-spacing: normal;
  color: #6e777f;
  padding-top: 30px;
  padding-left: 0px;
}

#campaign .Campaign-Management {
  /* width: 270px; */
  height: 29px;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  /* margin-left: 2px; */
  margin-bottom: 8px;
}

#campaign .Click-on-the-Create {
  width: 703px;
  height: 24px;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: oblique;
  line-height: 1;
  letter-spacing: normal;
  color: var(--gunmetal);
  scroll-padding-left: 10px;
  padding-left: 10px;
  padding: 20px;
}

#campaign .rectangleButton {
  width: 189px;
  height: 35px;
  border-radius: 17.5px;
  border: solid 1px var(--orangey-yellow);
  background-color: var(--orangey-yellow);
  background-color: #fdbb1f;
  color: #56585a !important;
}

#campaign .card-body {
  flex: 1 1 auto;
  padding-left: 15px;
}

#campaign .inputbox {
  width: 340px;
  height: 92px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
  position: absolute;
}

#campaign .Rectangletag {
  /* width: 107px; */
  height: 30px;
  border-radius: 15px;
  background-color: #eeeeee;
  margin-left: 10px;
  padding: 5px;
}

#campaign .scheduledcheckbox {
  width: 13px;
  height: 13px;
  border: solid 0.8px #fdbb1f !important;
}

#campaign .container-cc {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
}

#campaign .container-cb {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
  float: right;
  padding-right: 22px;
}

#campaign .container-cc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Hide the browser's default checkbox */

#campaign .container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

#campaign .checkmarkrectangle {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-color: #fdbb1f;
}

/* On mouse-over, add a grey background color */

#campaign .container-cb:hover input~.checkmarkrectangle {
  background-color: #ffffff;
}

/* On mouse-over, add a grey background color */

#campaign .container-cc:hover input~.checkmarkrectangle {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */

#campaign .container-cb input:checked~.checkmarkrectangle {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* When the checkbox is checked, add a blue background */

#campaign .container-cc input:checked~.checkmarkrectangle {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Create the checkmark/indicator (hidden when not checked) */

#campaign .checkmarkrectangle:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

#campaign .container-cb input:checked~.checkmarkrectangle:after {
  display: block;
}

/* Show the checkmark when checked */

#campaign .container-cc input:checked~.checkmarkrectangle:after {
  display: block;
}

/* Style the checkmark/indicator */

#campaign .container-cb .checkmarkrectangle:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the checkmark/indicator */

#campaign .container-cc .checkmarkrectangle:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#campaign .mkcpyfrme {
  padding-left: 20px;
}

/* #campaign .sms-preview {
  background-image: url(./../../assets/images/iphonexcampaign.png);
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: calc(100% + 50px);
  float: right;
  padding-bottom: 200px;
} */

/* #campaign .chatbox-wrapper {
  background-color: #ededed;
  padding: 7px;
  font-weight: 900;
  color: #fff;
  position: relative;
  padding-top: 6px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242;
  border-radius: 8px;
  word-break: break-word;
  min-height: 114px;
  max-height: 250px;
} */

#campaign input, textarea {
  outline: none;
}

#campaign .chatbox-dot:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #ededed;
  border-right: 10px solid transparent;
  border-top: 10px solid #ededed;
  border-bottom: 10px solid transparent;
  left: 19px;
  bottom: -19px;
}

#campaign .chatbox-inner {
  overflow-y: auto;
  max-height: 280px;
}

/* #campaign .sms-content-preview {
  width: 104%;
  font-size: 12px;
  margin-top: 16%;
  padding-left: 27px;
  padding-right: 34px;
  padding-top: 23px;
  padding-bottom: 100px;
} */

/* 
#campaign .sms-content-preview-data {
  height: 115px;
  border-radius: 8px;
  padding: 5px;
  width: -webkit-fill-available;
  background-color: #ededed;
} */

/* #campaign .sms-preview {
  width: 389px;
  height: 448px;
} */

#campaign .dropbox {
  width: 180px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
  color: #495057;
}

/* #campaign .Preview-Message {
  width: 108px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575; */

/* } */

#campaign .noofwords {
  width: 31px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  position: absolute;
  padding-top: 72px;
  margin-left: 293px;
}

#campaign .dropbox option {
  width: 214px;
  height: 212px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #ced4da;
  background-color: var(--white);
}

#campaign .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

#campaign .choose_file {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  border: #c0c0da solid 1px;
  width: 110px;
  padding: 4px 6px 4px 8px;
  font: normal 14px Myriad Pro, Verdana, Geneva, sans-serif;
  color: #7f7f7f;
  margin-top: 2px;
  background: white;
}

#campaign .choose_file input[type="file"] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

#campaign #editorimage {
  padding-left: 4px;
  height: 11px;
  cursor: pointer;
}

/* #campaign .entermsgcontent {
  width: 228px;
  height: 219px;
  border-radius: 5px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  position: absolute;
  z-index: 100;
  margin-left: 116px;
  display: none;
} */

#campaign .otherlangcontent {
  width: 228px;
  height: 219px;
  border-radius: 5px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  position: absolute;
  z-index: 100;
  margin-left: 116px;
  /* display: none; */
}

#campaign .otherlang:hover+.otherlangcontent {
  display: block;
}

#campaign #editorimage:hover+.entermsgcontent {
  display: block;
}

#campaign .data {
  width: 263px;
  height: 58px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#campaign .contentdiv {
  display: inline;
}

#campaign .picturedemo {
  width: 70px;
  height: 70px;
  opacity: 0.7;
  border-radius: 5px;
  background-color: #000000;
  padding-bottom: 2px;
}

#campaign .picturedit {
  position: absolute;
  z-index: 1;
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-left: -18px;
  margin-top: 4px;
}

#campaign .Search {
  width: 59px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#campaign .Group-3 {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-top: 10px;
  margin-left: -25px;
  /* margin-left: 220px; */
}

#campaign .Group-33 {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-top: 10px;
  margin-left: 23px;
}

#campaign .Combined-Shape {
  min-height: 110px;
  /* background-color: rgba(255, 230, 171, 0.66);  */
  margin-left: -25px;
  margin-right: -15px;
  margin-top: 25px;
  cursor: pointer;
}

.background-color-yellow {
  background-color: rgba(255, 230, 171, 0.66);
  /* height: 110px; */
  margin-left: -25px;
  margin-right: -15px;
  margin-top: 25px;
  cursor: pointer;
}

.background-color-yellow {
  background-color: rgba(255, 230, 171, 0.66);
}

#campaign .Combined-Shape-whitebackground {
  height: 110px;
  margin-right: -15px;
  margin-left: -25px;
  margin-top: 19px;
}

#campaign .Campaign1 {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  padding-left: 6%;
  padding-top: 22px;
  padding-bottom: 13px;
}

#campaign .Birthday-Wish {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  display: flex;
  justify-content: space-between;
  padding-right: 29px;
  padding-left: 6%;
}

#campaign .Showing-scheduled-campaigns {
  margin-left: -25px;
  margin-right: -15px;
  background-color: rgba(241, 244, 246, 0.8);
  margin-top: 0px;
  margin-bottom: -26px;
  position: relative;
}

#campaign .All-500-Customers {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  margin-left: 14%;
}

#campaign .Fill-arrow {
  /* transform: rotate(-270deg); */
  background-color: var(--orangey-yellow);
  /* margin-left: 20%; */
}

#campaign .datefor {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
  margin-left: 6%;
  margin-top: 2%;
}

#campaign .scheduledfor {
  color: #a6aaae;
  font-weight: normal;
}

.campaign-list {
  overflow: auto;
  height: 500px;
  width: 100%;
  margin-top: 25px;
}

.marginTop-0 {
  margin-top: 0;
}

#campaign .campaign-data {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: oblique;
  line-height: 1.28;
  letter-spacing: normal;
  color: #6e777f;
  padding-left: 0px;
  text-align: left;
  padding: 28px;
}

#campaign .create-btn {
  background-color: #a63232;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 88px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 2px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding-bottom: 4px;
}

#campaign .clock-btn {
  position: absolute;
  padding-top: 34px;
  margin-left: 73px;
}

.Balance-SMS-Credits {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #838C95;
}