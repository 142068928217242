#pcr-scheme .page-title {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#pcr-scheme .data-card-title {
  /* width: 151px;
  height: 25px; */
  font-family: Helvetica;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

#pcr-scheme .data-card-label {
  width: 81px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

#pcr-scheme .data-card-number{
  width: 21px;
  height: 22px;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}


#pcr-scheme .data-card-background {
  /* width: 323px;
  height: 205px; */
  object-fit: contain;
  /* opacity: 0.6; */
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-image:linear-gradient(122deg, #ffdf42a1 19%, #fdbb1fa6 92%)
  /* background-image: linear-gradient(122deg, #ffdf42 17%, #fdbb1f 92%); */
}

#pcr-scheme .table-title {
  width: 156px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#pcr-scheme th{
  color: #545a60;
  border: solid 0.9px #e9ecef;
}

#pcr-scheme .table-row-title{
    width: 47px;
    height: 14px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #545a60;
}

#pcr-scheme td{
    width: 25px;
    height: 14px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e777f;
    border: solid 0.9px #e9ecef;
}

#pcr-scheme .scheme-bg{
    background-image: url('/images/scheme-bg.svg');
    background-size: cover;
    min-height: 190px;
}

#pcr-scheme .scheme-card-title {
  /* width: 323px;
  height: 50px; */
  font-family: Helvetica;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}

#pcr-scheme .scheme-card-detail {
  /* width: 146px;
  height: 25px; */
  font-family: Helvetica;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}

#pcr-scheme .or {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}

#pcr-scheme .active-quarter{
  /* width: 109px;
  height: 34px; */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545cd8;
}


#pcr-scheme .inactive-quarter{
  /* width: 109px;
  height: 34px; */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545a60;
}

#pcr-scheme .quarter{
    background-color: white
}

#pcr-scheme .border-top{
  border-top: 1px solid #fdbb1f !important;
}

#pcr-scheme .quarter-container{
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

#pcr-scheme th{
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color:#f6f6f6;
}
