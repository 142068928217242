#manage-group .page-title {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#manage-group .new-group-modal {
  position: Fixed !important;
  width: 550px;
  max-width: 90%;
  overflow-y: hidden !important;
}

/* #manage-group .container-cb {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
} */

/* Hide the browser's default checkbox */

/* #manage-group .container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
} */

/* Create a custom checkbox */

/* #manage-group .checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-radius: 2px;
} */

/* On mouse-over, add a grey background color */

/* #manage-group .container-cb:hover input ~ .checkmark {
  background-color: #ffffff;
} */

/* When the checkbox is checked, add a blue background */

/* #manage-group .container-cb input:checked ~ .checkmark {
  background-color: #f7b924;
  border: solid 1px #f7b924;
} */

/* Create the checkmark/indicator (hidden when not checked) */

/* #manage-group .checkmark:after {
  content: "";
  position: absolute;
  display: none;
} */

/* Show the checkmark when checked */

/* #manage-group .container-cb input:checked ~ .checkmark:after {
  display: block;
} */

/* Style the checkmark/indicator */

/* #manage-group .container-cb .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} */

#manage-group th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color: #f6f6f6;
  /* text-align: center; */
}

#manage-group td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
  /* text-align: center; */
}

#manage-group th,
#manage-group tr {
  /* width: 100px !important; */
  border: 1px solid rgb(0, 0, 0, 0.1);
}

#manage-group td {
  border: 1px solid rgb(0, 0, 0, 0.1);
}

#manage-group tr,
#manage-group td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #6e777f;
}

#manage-group th,
#manage-group td {
  padding: 0.55rem;
}

#manage-group .add-member-sticky-button {
  margin-left: 0px;
  margin-right: 0px;
  /* position: fixed !important; */
  bottom: 0px;
  right: 0px;
  overflow-x: hidden !important;
  background-color: white !important;
}

#manage-group .edit-group-modal {
  position: Fixed !important;
  width: 550px;
  max-width: 90%;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

#manage-group input::placeholder {
  text-transform: none !important;
}

#manage-group .custom-dot {
  height: 8px;
  width: 8px;
  background-color: #fdbb1f;
  border-radius: 50%;
  display: inline-block;
  margin-top: 6px;
}

#manage-group .rectangleButton {
  width: 138px;
  height: 31px;
  border-radius: 17.5px;
  border: solid 1px var(--orangey-yellow);
  background-color: var(--orangey-yellow);
  background-color: #fdbb1f;
  color: #56585a !important;
}
