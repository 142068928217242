#buy-product .checkmark-rb {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}

#buy-product .container-rb .checkmark-rb:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fdbb1f;
}

#buy-product .img {
  height: 90px;
  border-radius: 5px;
}

#buy-product .note {
  font-size: 12px;
  color: #2a2e6c;
  font-weight: bold;
  text-align: justify;
}

.sp-en-swal {
  width: 340px !important;
}

#buy-product .option-css {
  display: flex;
  flex-direction: row;
}

#buy-product .question-font {
  font-size: 16px;
}

.Based-on-GST-Certificate-label {
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #6E777F;
}

.title-confirm {
  font-family: Helvetica;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: #495057;
}

.buy-prod-detail-span {
  font-family: Helvetica;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #495057;
}

.width-300px {
  width: 300px;
}