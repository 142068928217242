#order-history tr, #order-history td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #6e777f;
}

#order-history th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color: #f6f6f6;
}

#order-history td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#order-history th, #order-history tr {
  /* width: 100px !important; */
  border: 1px solid rgb(0, 0, 0, 0.1);
}

#order-history td {
  border: 1px solid rgb(0, 0, 0, 0.1);
}

.margin-TB-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.margin-LR-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.date-picker-left-n-35px .react-datepicker-popper {
  margin-left: -80px;
}