.header-title {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #495057;
  }
  .recce-status-list{
      /* margin-left: 8px; */
      margin-right: 14% !important;
  }
  .status-list{
      height: 100%;
      width: 100%;
      cursor: pointer;
    border-radius: 4px;
    padding-left: 16px;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: 0 !important;
  margin-right: 0 !important;
    /* margin-top: 4px;
    margin-right: 4px; */
  }
  .status-color{
    background: #FDBB1F;
    height: 100%;
      width: 100%;
      cursor: pointer;
    border-radius: 4px;
    padding-left: 16px;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: 0 !important;
  margin-right: 0 !important;
    /* margin-top: 8px;
    margin-right: 4px; */
  }
  /* .search-input{
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 1.04%;
    
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    border-radius: 4px;
    height: 34.64px;
    width: 316px;
    margin-top: 26px;
    margin-left: 24px;
  } */
  /* .loginn-input{
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    border-radius: 4px;
    height: 34.64px;
    width: 316px !important;
    margin-top: 26px;
    margin-left: 34px !important;
  } */
  .background-228B22 td {
    background: #228B22;
    color: white;
    opacity: 1;
    text-transform: capitalize;
  }
  
  .background-red td {
    background-color: #DC143C;
    color: white;
    opacity: 0.8;
    text-transform: capitalize;
  }
  
  .background-edeff0 {
    background-color: #edeff0;
    text-transform: capitalize;
  }
  
  .background-orange td {
    background-color: #fdbb1f;
    color: white;
    text-transform: capitalize;
  }
  
  .background-yellow td {
    background: yellow;
  }
  
  .Projects-Details-process {
    width: 50%;
    padding-right: 20px;
  }
  
  .Projects-Details-process-lbl {
    width: 100%;
  }
  
  .redirect-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  #table .redirect-link {
    text-transform: capitalize;
  }
  
  .redirect-link:hover {
    cursor: pointer;
  }
  
  .btn-new-proposal {
    width: max-content;
    margin-left: auto;
    font-weight: bold !important;
  }
  
  #table tr:nth-child(even) {
    background-color: rgb(246, 246, 246);
  }
  
  #table th {
    background-color: rgb(246, 246, 246);
  }
  
  #table .redirect-link {
    text-align: left;
  }
  
  @media screen and (min-width: 220px) and (max-width: 991px) {
    .Projects-Details-process {
      width: 100%;
      padding-right: 0px;
      margin-bottom: 30px;
    }
    .Projects-Details-process-points {
      flex-flow: wrap;
    }
  }