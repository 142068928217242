
#invite-dealer-modal .modal-title {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#invite-dealer-modal.modal-dialog{
  max-width: 510px !important;
}

#invite-dealer-modal .modal-title-border {
 border-bottom:1px solid #ced4da;
}


#invite-dealer-modal .request-details {
  width: 99px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#invite-dealer-modal textarea::placeholder {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#invite-dealer-modal .cancel-button {
  width: 116px;
  height: 35px;
  border-radius: 17.5px;
  background-color: #e0e2e4;
border: none;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6e777f;
}

#invite-dealer-modal .send-button {
  width: 116px;
  height: 35px;
  border-radius: 17.5px;
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
}


#invite-dealer-modal .container {
  display: inline;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
#invite-dealer-modal .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
#invite-dealer-modal .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
#invite-dealer-modal .container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
#invite-dealer-modal .container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
#invite-dealer-modal .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
#invite-dealer-modal .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
#invite-dealer-modal .container .checkmark:after {
 	top: 3px;
	left: 3px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #fdbb1f;
}

#invite-dealer-modal .nineone{
  min-height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.5rem 0.50rem;
}