@font-face {
  font-family: 'gotham-bold';
  src: url('./font/HomepageBaukasten-Bold.eot');
  /* IE9 Compat Modes */
  src: url('/font/HomepageBaukasten-Bold.eot.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./font/HomepageBaukasten-Bold.woff') format('woff'), /* Modern Browsers */
  url('./font/HomepageBaukasten-Bold.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-book';
  src: url('./font/HomepageBaukasten-Book.eot');
  /* IE9 Compat Modes */
  src: url('/font/HomepageBaukasten-Book.eot.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./font/HomepageBaukasten-Book.woff') format('woff'), /* Modern Browsers */
  url('./font/HomepageBaukasten-Book.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-black';
  src: url('./font/Metropolis-Black.otf');
  /* IE9 Compat Modes */
  font-weight: 700;
  font-style: normal;
}

.footerContainer {
  background-color: #fff000;
  padding: 20px 50px;
  /* height: 50px; */
}

.jktyre-event-logo {
  width: 186px;
  height: 35px;
}

.event-contact-us {
  color: black;
  font-size: 16px;
  font-weight: bold;
  font-family: 'gotham-bold';
  cursor: pointer;
  margin-bottom: 0;
}

.ranger-footer-text {
  color: black;
  font-size: 16px;
  font-weight: bold;
  font-family: gotham-bold;
}

/** Header Css */

.rangerHeaderContainer {
  position: fixed;
  top: 0;
  background: white;
  backdrop-filter: blur(20px);
  box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
  z-index: 9;
  width: 100%;
}

.rangerHeaderNav {
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rangerHeaderLinks {
  font-size: 16px;
  padding: 30px 20px 25px 20px;
  margin-bottom: 0px;
  cursor: pointer;
  border-bottom: 5px solid transparent;
  font-weight: 800;
  font-family: gotham-bold;
  color: black;
}

.rangerHeaderLinks:hover {
  border-color: #999;
  background: #eee;
  color: #333;
  text-shadow: 0px 0px 3px rgb(0 0 0 / 10%);
}

/** Main Content */

.mainEventImage {
  height: 45vh;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.rangercontentHeader {
  background: #fff;
  display: grid;
  grid-template-columns: 30% 1fr;
  margin-top: 80px;
}

.rangerImageContainer {
  padding: 30px;
}

.imagegridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.rangereventImage {
  cursor: pointer;
  border: 2px solid #eee;
  height: 80px;
  width: 80px;
  object-position: center;
  object-fit: contain;
  padding: 2px;
  margin: 0 10px;
  opacity: 1;
  transform: translate(0px, 0px);
}

.rangerimagetext {
  margin-top: 10px;
  font-size: 0.9rem;
  font-style: italic;
  color: black;
  font-family: gotham-book;
}

.eventContent {
  background: #e6e6e6;
  padding: 30px 50px
}

.eventName {
  background: #fff000;
  display: inline;
  padding: 10px 10px 10px 60px;
  margin-left: -50px;
  font-size: 16px;
  color: black;
  font-family: 'gotham-book';
}

.eventTable {
  margin: 30px 0 0;
  text-align: left;
  width: 100%;
  border-collapse: inherit!important;
}

.tableHeading {
  font-family: gotham-bold;
  padding: 10px 0;
  transform-origin: bottom;
  font-size: 16px;
  color: black;
}

.tableBody {
  width: 50%;
  padding: 15px 30px 15px 15px;
  color: #333;
  border-bottom: 1px solid #999;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  font-size: 16px;
  font-family: gotham-book;
}

.eventnote {
  display: block;
  margin-top: 8px;
  font-size: 10px;
  padding-left: 20px;
  font-family: gotham-book;
  color: black;
}

.eventContactButton {
  margin: 30px auto;
  font-weight: bold;
  display: block;
  padding: 12px 20px;
  background: #fff000;
  border: none;
  color: #222;
  letter-spacing: 0.5px;
  font-size: 1.1rem;
  line-height: 1;
  font-family: gotham-black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
  box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
}

.de-active {
  transform: translate(0px, 0px);
}

.active-image {
  transform: translate3d(0px, 0px, 0px);
  animation: changeSize 0.5s ease-in forwards
}

.active-font-table1 {
  /* transform: translate3d(0px, 0px, 0px); */
  animation: changeFont 0.5s ease-in-out forwards;
}

.active-font {
  animation: changeFontTable2 0.5s ease-in-out forwards;
  /* animation-delay: 100ms; */
}

.active-font-note {
  animation: changeOpacity 0.7s linear forwards;
}

@keyframes changeOpacity {
  0% {
    opacity: 0;
    display: none
  }
  25% {
    opacity: 0.1;
    display: block
  }
  50% {
    opacity: 0.2;
    display: block
  }
  75% {
    opacity: 0.3;
    display: block
  }
  90% {
    opacity: 0.5;
    display: block
  }
  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes changeSize {
  0% {
    -webkit-transform: scale(0.9)
  }
  100% {
    -webkit-transform: scale(1)
  }
}

@keyframes changeFont {
  0% {
    -webkit-transform: scale(1, 0.4)
  }
  20% {
    -webkit-transform: scale(1, 0.5)
  }
  50% {
    -webkit-transform: scale(1, 0.6)
  }
  70% {
    -webkit-transform: scale(1, 0.8)
  }
  100% {
    -webkit-transform: scale(1, 1)
  }
}

@keyframes changeFontTable2 {
  0% {
    -webkit-transform: scale(1, 0.4)
  }
  20% {
    -webkit-transform: scale(1, 0.5)
  }
  50% {
    -webkit-transform: scale(1, 0.6)
  }
  70% {
    -webkit-transform: scale(1, 0.8)
  }
  100% {
    -webkit-transform: scale(1, 1)
  }
}

@media screen and (max-width:767px) {
  .lastTableBody {
    border: none!important;
  }
  .eventContactButton {
    margin: auto;
  }
  .mainEventImage {
    height: 35vh;
  }
  .rangerHeaderContainer {
    position: sticky;
  }
  .footerContainer {
    padding: 20px;
  }
  .jktyre-event-logo {
    width: 132px;
    height: 25px;
  }
  .ranger-footer-text {
    font-size: 11px;
  }
  /**Header Media */
  .rangerHeaderNav {
    padding: 15px;
  }
  .rangerHeaderLinks {
    padding: 0
  }
  .rangercontentHeader {
    grid-template-columns: 100%;
    margin-top: 0;
  }
  .rangerImageContainer {
    padding: 30px 10px 10px 5px;
  }
  .rangereventImage {
    margin: 0 4px;
    height: 60px;
  }
  .eventContent {
    padding: 40px 20px;
  }
  .tableBody {
    padding: 15px 10px;
    font-size: 12.6px
  }
  .tableHeading {
    font-size: 14.4px;
  }
}

@media screen and (max-width:1440px) and (min-width:768px) {
  .imagegridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 30px;
  }
  .mainEventImage {
    height: 30vh;
  }
}