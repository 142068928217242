#NotificationPreference .servicespreference {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #4a5157;
}

#NotificationPreference .servicespreference-note {
  width: 239px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #838c95;
}

#NotificationPreference .servicespreference-wrapper {
  background-color: var(--white);
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 95px;
}