#dashboard-web .page-title {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard-web .gradient-title {
  background-image: linear-gradient(to right, rgba(30, 87, 248, 0.55), #491b9f);
  color: white;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* background-color: #5583b3; */
}

#dashboard-web .gradient-sub-title {
  font-family: Helvetica;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

#dashboard-web .warranty-block {
  border-radius: 8px;
  /* background-color: #fdbb1f4d; */
  background-color: #ffff;
  border: 1px solid #f8e184;
  /* background-image: linear-gradient(101deg, #f8e184, #f7d174); */
}

#dashboard-web .warranty-text-bold {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard-web .warranty-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#dashboard-web .text-input {
  border-radius: 24px !important;
  background-color: #ffffff !important;
}

#dashboard-web .dashboard-page-card-title-big {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard-web .dashboard-page-card-title-small {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard-web .content-center {
  display: flex;
  vertical-align: middle;
}

#dashboard-web .seconadary-warranty {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard-web .count-big {
  font-family: Helvetica;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #545cd8;
}

#dashboard-web .dashboard-page-card-title-small-gray {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #92969a;
}

#dashboard-web .dashboard-page-title {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard-web .image {
  width: 54px;
  height: 28px;
  object-fit: contain;
}

#dashboard-web .count-small {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #545cd8;
}

#dashboard-web .card-count {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  /* line-height: 28px; */
  letter-spacing: normal;
  /* text-align: right; */
  color: #495057;
}

#dashboard-web .no-warranty-points {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 300;
  font-style: oblique;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: center;
  color: #6e777f;
}

#dashboard-web .warranty-background {
  background-image: url("/images/group-26.svg");
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: center;
}

.input-group-addon1 {
  padding: 5px 9px;
  margin-left: 1px;
  margin-top: 3px;
  border: 0px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  text-align: center;
  background-color: #ffffff;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  position: absolute;
  /* z-index: 1; */
  font-weight: bolder;
}

#dashboard-web .card-custom-header {
  margin: 0px;
  /* padding: 19px 0px; */
  background-image: linear-gradient(101deg, #f8e184, #f7d174);
  /* background-image: linear-gradient(101deg, #ffdf42, #fdbb1f); */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#dashboard-web .no-content {
  height: 42px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 300;
  font-style: oblique;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #6e777f;
}

#dashboard-web .link {
  text-decoration: underline;
  color: #545cd8;
  font-family: Helvetica;
  cursor: pointer;
}

.prefix .input-group-addon1 {
  border-radius: 2px 0px 0px 2px;
}

.input-group-addon1.prefix {
  border-radius: 4px 0px 0px 4px;
  border-right: 0;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.padding-0 {
  padding: 2px !important;
}

.border-right-dashboard {
  border-right: 1px solid rgb(0, 0, 0, 0.1) !important;
}

.margin-0 {
  margin: 0px !important;
}

.header-padding {
  padding: 24px 17px;
}

.warrenty-card-padding {
  padding: 15px 15px 15px 15px !important;
}

.card-body-warrenty {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}