/* #AddCustomer .row {
  padding-left: 0;
  padding-right: 0;
} */

#add-customer .select-prpse {
  width: 102px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  margin-left: 13px;
  font-weight: bold;
  margin-top: 23px;
}

#add-customer .add-customer-title {
  margin-left: 7px;
}

#add-customer .rectangle-purpose {
  width: 77px;
  height: 74px;
  border-radius: 8.8px;
  border: solid 1.1px #ced4da;
  position: relative;
}

#add-customer .title-purpose {
  width: 86px;
  height: 16px;
  font-family: Helvetica;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  text-align: center;
  margin-top: 8px;
  margin-left: -5px;
}

#add-customer .purpose-image {
  max-width: fit-content;
  height: 37px;
  background-color: var(--gunmetal);
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25% 0 0 -23%;
}

#add-customer .purpose-image-ser {
  width: 38px;
  height: 37px;
  background-color: var(--gunmetal);
  position: absolute;
  top: 55%;
  left: 50%;
  margin: -30% 0 0 -25%;
}

#add-customer .margin-top-23 {
  margin-top: 23px;
}

.purpose-section {
  margin-left: 13px !important;
  margin-top: 14px !important;
  margin-right: 0 !important;
}

#add-customer .country-code {
  width: 43px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #ced4da;
  background-color: #f4f5f5;
  padding: 8px;
}

#add-customer .number {
  width: 200px;
  height: 40px !important;
  border-radius: 5px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

#add-customer .edit-remark {
  width: 360px !important;
  height: 35px !important;
  border-radius: 5px !important;
  border: solid 1px #ced4da !important;
  background-color: var(--white) !important;
}

#add-customer .edit-group {
  float: right;
  margin-top: 3px;
  width: 94px;
  margin-left: auto;
  text-align: right;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

#add-customer .fix-height {
  min-height: calc(82vh - 100px);
  max-height: calc(82vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

#add-customer .selected-inquiry {
  background-color: #feeabb !important;
}

#add-customer .selected-servicing {
  background-color: #feeabb !important;
}

#add-customer .selected-tyre_purchase {
  background-color: #feeabb !important;
}

#add-customer .margin-top-5 {
  margin-top: 5px !important;
}

#add-customer .margin-right-30 {
  margin-left: -30px !important;
}

#add-customer .padd-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#add-customer .service-note {
  width: 227px;
  height: 16px;
  opacity: 0.8;
  font-family: Helvetica;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  margin-left: 13px;
  margin-top: 7px;
}

#add-customer .PL-0 {
  padding-left: 0px !important;
}

#add-customer .L-19 {
  margin-left: 19px;
}

#add-customer .setting-icon {
  width: 14px;
  height: 14px;
  background-color: var(--orangey-yellow);
}

#add-customer .ML-30 {
  margin-left: 20px !important;
}

#add-customer .PL-10 {
  padding-left: 10px !important;
}

.Please-provide-owner {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: oblique;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#add-customer .CD-note {
  width: 95%;
  height: 35px;
  box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff2d3;
  margin-top: 10px;
  border-radius: 5px;
  background-image: url("/images/note_bg_icon.png");
  background-position-x: right;
  background-size: 100% 70px;
}

#add-customer .CD-title {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: oblique;
  line-height: 3;
  letter-spacing: 0.17px;
  color: var(--gunmetal);
  margin-left: 10px;
}

#add-customer .half-yellow {
  width: 21px;
  height: 16px;
  background-color: #fdbb1f;
  float: right;
  margin-top: -35px;
  margin-right: 20px;
}

#add-customer .T-20 {
  margin-top: 20px;
}

#add-customer .T-10 {
  margin-top: 10px;
}

#add-customer .name {
  width: 248px !important;
  height: 35px !important;
  background-color: var(--white) !important;
  color: #495057 !important;
}

#add-customer .edit-name {
  /* width: 170px !important; */
  /* height: 35px !important; */
  background-color: var(--white) !important;
}

#add-customer .edit-Country-code {
  border-radius: 5px;
  border: solid 1px #ced4da;
  background-color: #f4f5f5;
  padding: 9px;
  margin-bottom: 1rem;
  margin-right: -9px;
}

#add-customer .edit-number {
  width: 125px !important;
  height: 35px !important;
  background-color: #f4f5f5 !important;
}

#add-customer .remark {
  width: 557px !important;
  height: 40px !important;
  border-radius: 5px !important;
  border: solid 1px #ced4da !important;
  background-color: var(--white) !important;
}

#add-customer .PDetails {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--gunmetal);
  margin-top: 20px;
  margin-bottom: 20px;
}

/* #cust-detail */

#add-customer
  .input-group
  > .react-datepicker-wrapper
  > .react-datepicker__input-container
  > .form-control {
  border-top-right-radius: 15 !important;
  border-bottom-right-radius: 15 !important;
}

.input-group
  > .react-datepicker-wrapper
  > .react-datepicker__input-container
  > .form-control::placeholder {
  border-top-right-radius: 15 !important;
  border-bottom-right-radius: 15 !important;
  text-transform: capitalize !important;
}

.add-group {
  float: right;
  margin-top: 3px;
  width: 94px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

#add-customer .Usage-Type {
  width: 76px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#veh-details .serach-icon {
  color: #fdbb1f !important;
}

#add-customer .under_reg {
  width: 113px;
  height: 17px;
  opacity: 0.4;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#add-customer .T-35 {
  margin-top: 35px;
}

#add-customer .user-det {
  padding: 15px;
  /* width: 570px; */
  /* height: 80px; */
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.user-det .user-name {
  width: 101px;
  height: 24px;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

#add-customer .service-tag {
  min-width: 85px;
  text-align: center;
  height: 21px;
  border-radius: 14.5px;
  color: #495057;
  background-color: #ededed;
  font-size: 13px;
}

#add-customer .display-F {
  display: flex;
}

#add-customer .p-0 {
  padding: 0px;
}

#add-customer .remark-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: oblique;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  padding: 0px;
  padding-left: 0px !important;
  margin-top: 7px;
}

.add-newVehivle-btn {
  /* width: 275px !important;
  max-width: 275px !important; */
  /* margin-right: 20px; */
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  padding: 7px;
  cursor: pointer;
}

.viewHistory-btn {
  /* width: 275px !important;
  max-width: 275px !important; */
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  padding: 12px;
  cursor: pointer;
}

.ANV {
  height: 14px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545cd8;
}

#add-customer .Fill-1 {
  width: 15px;
  height: 15px;
  object-fit: contain;
  background-color: var(--warm-blue);
}

#add-customer .vehicle-image {
  width: 74px;
  height: 43px;
  object-fit: contain;
}

#add-customer .veh-det-view {
  height: auto;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
  margin-bottom: 15px;
}

#add-customer .veh-det-view-2 {
  /* width: 570px; */
  height: auto;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff1d2;
}

#add-customer .ML-20 {
  margin-left: 20px;
}

#add-customer .p-10 {
  padding: 10px;
}

#add-customer .V-t-card {
  width: 174px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#add-customer .V-t-card-2 {
  /* width: 174px; */
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#add-customer .upcoming-aapoint {
  width: 554px;
  min-height: 42px;
  border-radius: 6px;
  background-color: #fff1d2;
  margin-top: 20px;
  padding-bottom: 10px;
}

#add-customer .upcoming-aapoint-cd {
  width: 572px;
  min-height: 42px;
  border-radius: 6px;
  background-color: #fff1d2;
  margin-top: 20px;
}

#add-customer .V-t-model {
  width: 150px;
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #495057;
}

#add-customer .V-t-number {
  width: 126px;
  /* height: 40px; */
  margin-top: 0px;
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #838c95;
}

#add-customer .del-img {
  width: 40px;
  height: 13px;
  opacity: 0.7;
  cursor: pointer;
}

#add-customer .border-card {
  border-top: solid #c6cacf 1px;
  margin-top: 10px;
}

#add-customer .border-card-1 {
  border-top: solid #c6cacf 1px;
  margin-left: 15px;
  margin-top: 5px;
}

#add-customer .label-t {
  font-size: 12px;
}

#add-customer .veh-det-view-3 {
  width: 570px !important;
  min-height: 85px;
  /* height: 124px; */
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
}

#add-customer .T-5 {
  margin-top: 5px;
}

#add-customer .detail-img {
  width: 16px;
  cursor: pointer;
}

#add-customer .P-L-0 {
  padding-left: 0px !important;
}

#add-customer .P-L-15 {
  padding-left: 15px !important;
}

#add-customer .T10 {
  margin-top: -10px;
}

.show_error {
  /* margin-left: 15px; */
}

#add-customer .l-35 {
  margin-left: 35px;
}

#add-customer .w-180 {
  width: 180px !important;
}

#add-customer .visit-history {
  margin-left: auto;
  margin-right: 30px;
  margin-top: 25px;
}

#add-customer .pL-18 {
  padding-left: 18px;
}

#add-customer .Upcoming-up-card {
  height: 40px;
  margin-right: 0px;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin: -6px;
  margin-top: 0;
}

#add-customer .edit-veh-det-view {
  height: auto;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

#add-customer .edit-uA {
  margin-bottom: auto;
  margin-top: auto;
  color: #545cd8;
  font-weight: bold;
  padding-left: 10px;
}

#add-customer .circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #545cd8;
  display: grid;
  justify-content: center;
  align-items: center;
}

#add-customer .circle-number {
  color: white;
}

#add-customer .VPD {
  color: #545cd8;
  font-size: 12px;
  margin-top: -10px;
  font-weight: bold;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: #5f67dd;
}

#add-customer .right-arrow {
  float: right;
}

#add-customer .add-alignment {
  color: #545cd8;
  /* margin-top: 30px; */
  margin-bottom: 8px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}

#add-customer .is-disabled {
  cursor: auto !important;
  /* background-color: #aaa !important; */
  opacity: 0.5 !important;
  color: var(--gunmetal) !important;
}

#add-customer .ML-0 {
  margin-left: 0px !important;
}

#add-customer .form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}

#add-customer .diable-mo-number {
  opacity: 0.5 !important;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal) !important;
}

#add-customer .add-customer-wrapper {
  margin: 10px !important;
  margin-right: 5px !important;
  margin-left: 20px !important;
}

#add-customer .p-10-px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

#add-customer .p-left-25px {
  padding-left: 25px !important;
}

#add-customer .add-customer-lbl {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

/* 
#add-customer .visit-history .row {
  margin-left: 0;
  margin-right: 0;
} */

.display-botttom-datepicker {
  width: 100%;
}

.display-botttom-datepicker .react-datepicker-popper {
  transform: initial !important;
  margin-top: 35px;
}

#add-customer .Success-tyre-purchase {
  font-family: Helvetica;
  font-size: 19.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--gunmetal);
  margin-top: 15px;
  margin-bottom: 15px;
}

#add-customer .Tyre-Purchase-visit {
  font-family: Helvetica;
  font-size: 16.8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--gunmetal);
  margin-bottom: 20px;
}

#add-customer .How-would-you-like-t {
  font-family: Helvetica;
  font-size: 16.8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--gunmetal);
  margin-top: 15px;
}

#add-customer .star-rating-wrapper {
  margin-top: 15px;
  justify-content: center;
  display: flex;
}

#add-customer .star-rating-data span {
  margin-right: 10px;
}

.Set-Service-Reminder {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  margin-top: 2px;
}

.add-tyre-purchase-Services-List {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--gunmetal);
}

.tbl-vehicle-detail {
  border-collapse: unset;
}

.tbl-vehicle-detail td {
  vertical-align: top;
  border: none;
}

.borde-bottom-EFF1F3 {
  border-bottom: 1.5px solid #eff1f3 !important;
}

.Ask-for-Reference-lbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #545cd8;
}

.ADD-REFERENCE-Title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

.add-customer-react-select:nth-child(2) {
  box-shadow: none !important;
}

.z-index-102 {
  z-index: 102;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.trade-history-reamrk-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

/* 
.css-2ozrsa-control {
  box-shadow: none;
  border-color: #ced4da !important;
  border: 1px solid #ced4da;
} */

.box-shadow-10px {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25) !important;
}

.margin-bottom-0-4rem {
  margin-bottom: 0.4rem !important;
}

.opacity-0-5 {
  opacity: 0.5;
}

.rating-round {
  background: #545cd8;
  color: #ffffff;
  border-radius: 50%;
  font-size: 11px;
  height: 17px;
  text-align: center;
  width: 17px;
}

.trade-history-tooltip-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  color: #ffffff;
  text-align: left;
}

.rating-tooltip {
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
}

.upcoming-tyre-replacement {
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 10px !important;
  margin-right: 5px !important;
  margin-left: 20px !important;
}

.upcoming-tyre-replacement-note {
  font-family: Helvetica;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  margin: 10px !important;
  margin-right: 5px !important;
  margin-left: 20px !important;
}
