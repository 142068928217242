#banner-list th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  color: #545a60;
  border: 1px solid #e9ecef;
}

#banner-list td {
  font-family: Helvetica;
  font-size: 12px;
  color: #545a60;
  border: 1px solid #e9ecef;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#banner-list thead {
  background-color: rgb(246, 246, 246);
}

#banner-list .filter-container {
  width: 379px;
  border-radius: 5px;
  border: solid 1px #ced4da;
  background-color: #fff;
  padding: 16px;
  position: absolute;
}

#banner-list .margin-top-12 {
  margin-top: 12px;
}


#banner-list .button {
  width: 48%;
  height: 35px;
  border-radius: 17.5px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #495057;
}


#banner-list .create-button {
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
}

#banner-list .cancel-button {
  border: solid 1px #e0e2e4;
  background-color: #e0e2e4;
}


#banner-list .filter-icon {
  height: 34px;
}

#banner-list .filter-icon-selected {
  height: 34px;
}

#banner-list .padding-left-0 {
  padding-left: 0px !important;
}

#banner-list .count {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  color: #545cd8;
  cursor: pointer;
}

.create_sideBar_apply_Button {
  position: absolute;
}

/** Email Campaign Table Css starts */
.email_title_container {
  padding: 30px 20px 20px 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.email_campaign_title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #495057;
}

.create_email_container {
  height: calc(100vh - 180px);
  padding: 15px;
  overflow-y: auto;
}

.create_campaign_button {
  padding: 10px 22px;
  border-radius: 50px;
  background-color: #FDBB1F;
  color: #495057;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: none;
}

.email_input_container {
  padding: 0 0 15px 18px;
}

.table_header_text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #545A60;
}

.email_campaign_table_data {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #545A60;
}


.email_campaign_filter_container {
  position: absolute;
  width: 300px;
  height: 190px;
  border-radius: 5px;
  left: -15px;
  top: 15px;
  z-index: 1;
}

.email_campaign_filter_heading {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
}

.email_campaign_date_title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
}

.email_campaign_button {
  padding: 10px 36px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 50px;
}

.create_email_campaign_label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
}

.border_top {
  border: 1px solid #CED4DA
}

.campaign_cancel {
  padding: 8px 55px;
  border-radius: 50px;
  background: #E0E2E4;
  color: '#6E777F';
  font-size: 15px;
  font-weight: 700;
  border: none;
  outline: none;
}

.campaign_save {
  padding: 8px 55px;
  border-radius: 50px;
  background: #FDBB1F;
  color: '#6E777F';
  font-size: 15px;
  font-weight: 700;
  border: none;
  outline: none;
}

#user-update .switch-container {
  /* width: 50%; */
  height: 42px;
  border: 1px solid black;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  text-align: center;
  line-height: 27px;
  margin-bottom: 15px;
  color: #495057;
  margin-left: 20px;
}

#user-update .switch-container.adminSwitchContainer {
  width: fit-content;
  margin-left: 0;
}

#user-update .switch-data {
  margin-top: 0px;
  display: inline-block;
  /* width: 50%; */
  height: 100%;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

#user-update .active-switch {
  color: white;
  font-weight: bold;
  background-color: #fdbb20;
  border-radius: 5px;
}

#user-update .city-input {
  text-transform: capitalize;
  height: calc(1.3em + 0.75rem + 2px)
}

#user-update .margin-left-10 {
  margin-right: 10px;
  margin-left: -4px;
  margin-top: -1px;
}

.downloadSkuRadio .container-rb {
  margin-bottom: 7px;
}

.action-menu.manageSkuActionMenu {
  min-width: 115px;
  padding: 12px 15px;
}

.compulsory {
  color: #fdbb20;
}

.grayNote {
  color: #a9a4a4;
  font-size: 12px;
}