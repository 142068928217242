#DropDownMultiple .down-img{
  height: 6px;
    width: 10px;
}
#DropDownMultiple .dropdown-title-wrapp{
  background: #FFFFFF;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
}
#DropDownMultiple .dropdown-title{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
}
#DropDownMultiple .dropdown-menu.show{
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
#DropDownMultiple .drop-down-item-wrapp{
  display: flex;
  padding: 10px;
}

#DropDownMultiple .drop-down-item-wrapp:hover{
  cursor: pointer;
}

#DropDownMultiple .drop-down-item-value{
font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
color: #495057;
margin-left: 10px;
}