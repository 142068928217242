#create-banner .field-title{
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  color: #495057;
  width:100%
}

#create-banner .field-title-other-fields{
  font-weight: 100;
  
}

#create-banner  .field-title-other-field-container{
  display:flex;
  justify-content:flex-end;
  align-items:center
}
#create-banner .compulsory{
  color:#fdbb20;
}

/* color picker */
#create-banner .color-picker-selected-color {
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  border-radius: 2px;
  border:1px solid rgba(0,0,0,0.1);
  cursor: pointer;
  /* background: this.state.titleColor */
}

#create-banner  .drop-down-control {
  font-size: 12px !important;
}


/* #create-banner .color-picker-selected-color-container {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
  cursor: pointer;
} */

#create-banner .color-picker-popover{
  position: absolute;
  z-index: 2;
}

#create-banner .color-picker-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#create-banner .no-of-input-text{
  text-align: right;
  font-size: 10px;
  color: #888888;
}

#create-banner .banner-background-color-container{
  /* display: inline-block; */
  padding: 6px;
  border: 1px solid #ced4da;
  margin-left: 10px;
  border-radius: 5px;
  height:35px;
  display: inline-flex !important;
  align-items: center !important;
}

#create-banner  .display-inline{
  display:inline-block
}

#create-banner .margin-top-8{
  margin-top:8px;
}

#create-banner .margin-top-12{
  margin-top:12px;
}

#create-banner  .select {
  position: relative;
  -webkit-appearance: none;
  background: url("/images/down-arrow.svg") no-repeat right #fff;
  background-position-x: 96%;
  font-family: Helvetica;
}

#create-banner  .calendar-input {
  /* border-right: 0px; */
  width: 100%;
}

#create-banner  .create-icon-con{
  background-color: #f8f8f8;
  padding: 12px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top:10px;
}

#create-banner  .edit-icon{
  background-color: #ced4da;
  font-weight: bold;
  width: 104px;
  text-align: center;
  height: 33px;
  border-radius: 19px;
  line-height: 32px;
  cursor: pointer;
}

 #create-banner  .upload-image-container{
  width:109px;
  display:inline-block;
}

#create-banner .upload-image-label{
  margin:0px;
  display:flex;
}

#create-banner  .preview-main-container{
  border-radius: 6px;
  border: solid 1px #e2e6e9;
  background-color:#f6f6f6;
  padding:0px;
}

#create-banner .preview-description {
  opacity: 0.7;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #495057;
}

#create-banner .preview-description-title{
  font-size: 16px;
  font-weight:bold;
}

#create-banner  .preview-web-title{
  opacity: 0.75;
  font-size: 14px;
}


#create-banner .preview-web-container{
  max-width: 412px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  
}

#create-banner .preview-mobile{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 228px;
  border-radius: 6px;
  background-image: url('/images/ios-image.png');
  background-position: top;
  padding: 74px 0px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-size: 281px;
}

#create-banner .preview-mobile-container{
  width: 238px;
  height: 76px;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  margin-left:2px;
}

#create-banner .banner-preview-title-web{
  font-family: Helvetica;
  font-size: 16px;
}

#create-banner .banner-preview-description-web{
  font-family: Helvetica;
  font-size: 12px;
  margin-top:5px;
}

#create-banner .banner-preview-title-mobile{
  font-family: Helvetica;
  font-size: 8px;
}

#create-banner .banner-preview-description-mobile{
  font-family: Helvetica;
  font-size: 7px;
  margin-top:5px;
}

#create-banner .preview-image{
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #e6e6e6c7;
}

#create-banner .preview-only-image{
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
}

#create-banner .preview-image-container-mobile{
   padding-right: 6px;
    padding-left: 10px;
    height: 30px;
}

#create-banner .preview-image-container-web{
    padding-right: 8px;
}

#create-banner  .padding-left-0{
  padding-left:0px !important;
}

#create-banner  .padding-right-0{
  padding-right:0px !important;
}

#create-banner   .web-preview-card{
    padding: 13px;
}

#create-banner   .mobile-preview-card{
    padding: 9px;
}
#create-banner .add-description-button{
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  color: #545cd8;
  cursor:pointer;
}

#create-banner .button {
  width: 124px;
  height: 35px;
  border-radius: 17.5px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #495057;
}


#create-banner .create-button {
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
}

#create-banner .cancel-button {
  border: solid 1px #e0e2e4;
  background-color: #e0e2e4;
}

#create-banner .select-user-input{
  height: 35px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  width: 100%;
  background-color: white;
  margin-top:8px;
}

#create-banner .select-user-input:disabled{
  height: 35px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  width: 100%;
  background-color: white;
  margin-top:8px;
  opacity:0.7
}

#create-banner .select-user-input-select {
  font-family: Helvetica;
  font-size: 14px;
  color: #6e777f;
  text-align:left;
  padding-left: 20px;
  padding-right: 0px;
}

#create-banner  .select-user-input-user-count {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  color: #495057;
  text-align:right;

}

#create-banner  .file-upload-image-name {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  /* text-align: center; */
  color: #6e777f;
  display:inline-block;
  width: calc(100% - 110px);
}

#create-banner  .preview-color{
    border: 1px solid #ced4da;
    display: inherit;
    width: 14px;
    height: 14px;
    border-radius:3px;
    cursor:pointer;
}

#create-banner .react-datepicker-wrapper {
  display: block;
  z-index: 0;
}

#create-banner .input-group-text{
  z-index: 0 !important;
  background-color:transparent !important;
  padding: 6px !important;
}

#create-banner  .description-text{
  font-size: 14px;
  font-family: Helvetica;
  width:100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/* option{
  padding:5px;
  background-color: white;
  color: #fdbb20;
}

option:checked{
  background-color: #fdbb20;
  color: white;
}

option:hover{
  background-color: #fdbb20;
  color: white;
}

select:not(:-internal-list-box) {
    overflow: visible !important;
    height:30px;
} */

#create-banner .image-note-text{
  font-size: 12px;
    padding-left: 16px;
    padding-bottom: 2px;
    color: #495057;
    margin-top:-6px;
}