#coupon-verify .scorcard {
  position: absolute !important;
  left: 40%;
  top: 25%;
  z-index: 9999;
  /* right: 29%; */
  width: 20% !important;
}

.label-verify {
  font-size: 14px;
  padding-top: 14px;
  margin-bottom: -6px;
  padding-left: 25px;
}

#coupon-verify .border {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: -15px;
  margin-right: -15px;
}

.label-select {
  font-size: 14px;
  font-family: Helvetica;
  color: #495057;
  /* font-weight: bold; */
}

.label-select-div {
  width: 18%;
  float: left;
}

.label-select-data-div {
  width: 80%;
  float: left;
}

.label-select-data-main {
  font-size: 16px;
  font-family: Helvetica;
  color: #495057;
  font-weight: bold;
  margin-bottom: 15px;
}

.label-select-data {
  font-size: 14px;
  font-family: Helvetica;
  color: #495057;
  font-weight: bold;
  margin-bottom: 15px;
}

.label-select-mssg {
  cursor: pointer;
  font-size: 14px;
  font-family: Helvetica;
  color: #1B6BC1;
  font-weight: bold;
}

.label-send-again {
  cursor: pointer;
  font-size: 13px;
  font-family: Helvetica;
  color: #545CD8;
  font-weight: bold;
}

#coupon-verify .wrapper-scorcard {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

#redeem-coupon .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* border-radius: 10px; */
  outline: 0;
}

.redeem-coupon-warranty .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* border-radius: 10px; */
  outline: 0;
}

#redeem-coupon .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0px;
}

#redeem-coupon-detail .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 495px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* border-radius: 10px; */
  outline: 0;
}

#redeem-coupon-detail .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0px;
}

/* .note-redeem-coupon {
  width: 92%;
  height: 44px;
  border-radius: 8px;
  background-image: url(/images/note_bg_icon.png);
  background-position-x: right;
  margin-bottom: 20px;
  background-size: 100% 205%;
} */

.service-pref-note-redeem {
  /* position: relative; */
  height: 40px;
  float: left;
  width: 95%;
  color: #495057;
  background-color: #fff2d3;
  overflow: hidden;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  /* font-style: oblique; */
  line-height: normal;
  letter-spacing: normal;
  border-radius: 7px;
  border-top-right-radius: 0;
  padding: 12px 1.5em;
  margin-bottom: 20px;
}

.service-pref-note-redeem:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: #fff #fff #fdbb1f #fdbb1f;
  border-style: solid;
  border-width: 0 16px 16px 0;
  background: #fdbb1f;
  display: block;
  width: 0;
  margin-right: 38px;
}