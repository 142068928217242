.tab-menu {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

.container {
  display: inline;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Hide the browser's default radio button */

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

#addVehicle .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f !important;
  border-radius: 50% !important;
}

/* On mouse-over, add a grey background color */

#addVehicle .container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

#addVehicle .container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

#addVehicle .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

#addVehicle .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

#addVehicle .container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdbb1f;
}

.block-border {
  border: 1px solid black;
}

.Rectangle {
  width: 387px;
  height: 50px;
  border: solid 1px #e0e0e0;
  background-color: var(--orangey-yellow);
}

.RectangleRow {
  width: 358px;
  height: 50px;
  border: solid 1px #e0e0e0;
  background-color: var(--white);
}

.RectangleYellow {
  width: 179px;
  height: 50px;
  border: solid 1px #e0e0e0;
  background-color: #fdcd1f;
  padding-top: 12px;
  padding-left: 45px;
  color: #56585a !important;
}

.Rectanglecompleted {
  width: 179px;
  height: 50px;
  border: solid 1px #e0e0e0;
  padding-top: 12px;
  padding-left: 45px;
  color: #56585a !important;
}
.container-cc {
  display: block;
  position: relative;
  padding-left: 19px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
}

.container-cb {
  display: block;
  position: relative;
  padding-left: 19px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  line-height: 17px;
  float: right;
  padding-right: 0px;
}

.container-cc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Hide the browser's default checkbox */

.container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

#addVehicle .checkmarkrectangle {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-color: #fdbb1f;
}

/* On mouse-over, add a grey background color */

#addVehicle .container-cb:hover input ~ .checkmarkrectangle {
  background-color: #ffffff;
}

/* On mouse-over, add a grey background color */

#addVehicle .container-cc:hover input ~ .checkmarkrectangle {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */

#addVehicle .container-cb input:checked ~ .checkmarkrectangle {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* When the checkbox is checked, add a blue background */

#addVehicle .container-cc input:checked ~ .checkmarkrectangle {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Create the checkmark/indicator (hidden when not checked) */

#addVehicle .checkmarkrectangle:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

#addVehicle .container-cb input:checked ~ .checkmarkrectangle:after {
  display: block;
}

/* Show the checkmark when checked */

#addVehicle .container-cc input:checked ~ .checkmarkrectangle:after {
  display: block;
}

/* Style the checkmark/indicator */

#addVehicle .container-cb .checkmarkrectangle:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the checkmark/indicator */

#addVehicle .container-cc .checkmarkrectangle:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dot {
  height: 6px;
  width: 29px;
  background-color: #fdbb1f;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
}

.radio-main-lbl {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

.Fix-height {
  min-height: calc(84vh - 100px);
  max-height: calc(84vh - 100px);
  overflow-y: scroll;
}
