.Warranty-Registration {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.warranty-card {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.note-warranty {
  font-size: 12px !important;
  margin-bottom: 15px;
}

.blue-label {
  text-align: left !important;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 2px;
  color: #545cd8;
  margin: auto;
}

.button-sku {
  width: 55%;
  border-width: 1px;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 5px;
  border-color: #e8e8e8;
  box-shadow: 0 1px 3px 0px rgb(153, 152, 152);
  height: 40px;
}

.warranty-customer-details-label {
  margin-left: 30px;
  padding-top: 16px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.disabled-text-box {
  background-color: #ffffff;
  display: block;
  width: 100%;
  height: 35px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.light-gray-back {
  background-color: lightgray;
}

.disabled-text-box:focus {
  background-color: #ffffff;
  display: block;
  width: 100%;
  height: 35px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  /* border: 1px solid #ced4da; */
  border-radius: 4px;
  /* border-color: #fdbb1f; */
  box-shadow: 0 1px 3px 0px grey;
  outline: 0 none;
}

.disable-text-box-margin {
  margin-top: 15px;
}

.enable-text-box-margin {
  margin-top: -15px;
}

.input-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.disabled-input-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.disabled-input-value {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.required-star {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fdbb1f;
  margin-bottom: 0 !important;
}

.next-btn {
  width: 176px;
  border-radius: 17.5px;
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
}

.custom-textarea:focus {
  border-color: #fdbb1f !important;
  box-shadow: 0 1px 1px white inset, 0 0 8px white !important;
  outline: 0 none !important;
  background-color: rgba(253, 187, 31, 0.1) !important;
}

.textarea:focus,
textarea:focus,
input[type="text"]:focus,
input[type="textarea"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  /* border-color: #fdbb1f; */
  box-shadow: 0 1px 1px white inset, 0 0 8px white;
  outline: 0 none;
  /* background-color: rgba(253, 187, 31, 0.1); */
}

.custom-checkboax--container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  height: 33px;
  width: max-content;
  border: solid 2px #fdbb1f;
}

/* Hide the browser's default checkbox */

.custom-checkboax--container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 29px;
  width: 30px;
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */

.custom-checkboax--container input:checked ~ .checkmark {
  background-color: #fdbb1f;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.custom-checkboax--container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.custom-checkboax--container .checkmark:after {
  left: 12px;
  top: 3px;
  width: 8px;
  height: 19px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkboax1--container {
  /* display: block; */
  position: relative;
  padding-left: 15px;
  margin-bottom: -4px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  height: 20px;
  width: 20px;
  border: solid 2px #fdbb1f;
  background-color: #fdbb1f;
}

/* Hide the browser's default checkbox */

.custom-checkboax1--container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */

.custom-checkboax1--container input:checked ~ .checkmark1 {
  background-color: #fdbb1f;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.custom-checkboax1--container input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the checkmark/indicator */

.custom-checkboax1--container .checkmark1:after {
  left: 5px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkboax-radio--container {
  /* display: block; */
  position: relative;
  padding-left: 15px;
  margin-bottom: -4px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  height: 19px;
  width: max-content;
  border: solid 2px #fdbb1f;
  border-radius: 25px;
}

/* Hide the browser's default checkbox */

.custom-checkboax-radio--container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark-radio {
  position: absolute;
  top: 0px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 25px;
}

/* When the checkbox is checked, add a blue background */

.custom-checkboax-radio--container input:checked ~ .checkmark-radio {
  background-color: #fdbb1f;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.custom-checkboax-radio--container input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the checkmark/indicator */

.custom-checkboax-radio--container .checkmark-radio:after {
  left: 0px;
  top: 0.4px;
  width: 15px;
  height: 14.5px;
  border: 2px solid white;
  /* border-width: 0 3px 3px 0; */
  /* transform: rotate(45deg); */
  border-radius: 25px;
}

.left-tyre-car-checkbox {
  position: absolute;
  margin-top: 20%;
  margin-left: -4%;
}

.front-left-right-tyre-car {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 23%;
  width: 370px;
  margin-top: 0%;
  margin-left: -74px;
  top: 23%;
}

.back-left-right-tyre-car {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 360px;
  margin-top: 0%;
  margin-left: -70px;
  top: 58%;
}

.square-tyre-car {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 230px;
  margin-top: 0%;
  top: 82%;
}

.square-tyre-activa {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 82%;
  margin-top: 0%;
  top: 38%;
}

.front-left-right-tyre-activa {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 55%;
  margin-top: 0%;
  margin-left: 6%;
  top: 102%;
}

.custom-checkbox-lbl {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.horizontal-OR-line {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: auto;
  margin-left: 5px;
}

.serach-icon {
  font-size: 15px;
  position: relative;
  margin-left: -23px;
  margin-top: 7px;
}

.vehical-sku-id-search .rbt {
  width: 100%;
}

.vehical-make-wrapp {
  border-radius: 10.5px;
  border: solid 0.7px #979797;
  height: 123.2px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.vehical-make-img {
  width: 84.2px;
  height: 62px;
  object-fit: contain;
}

.vehical-make-label {
  margin-top: 10px;
  font-family: Helvetica;
  font-size: 11.2px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #6e777f;
  text-align: center;
}

.custom-secondary:hover {
  color: #fff !important;
  background-color: #fdbb1f !important;
  border-color: #fdbb1f !important;
}

.gradient-sub-title {
  font-family: Helvetica;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.custom-secondary-selected {
  color: #fff !important;
  background-color: #fdbb1f !important;
  border-color: #fdbb1f !important;
}

.Note---If-you-can-no {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #6e777f;
}

.year-date-wrapp,
.year-date-wrapp .react-datepicker,
.year-date-wrapp input[type="text"] {
  width: 100%;
}

.vehical-make-wrapp:hover {
  cursor: pointer;
  border-radius: 10.5px;
  border: solid 1.4px #fdeba0;
  background-color: #fdeba0;
}

.active-vehical-make-wrapp {
  border-radius: 10.5px;
  border: solid 1.4px #fdeba0;
  background-color: #fdeba0;
}

.km {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-top: 7px;
  margin-left: 5px;
}

.text-overflow-dots {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filled-data-field {
  border-color: #fdbb1f;
  box-shadow: 0 1px 1px white inset, 0 0 8px white;
  outline: 0 none;
}

.box-shadow-apply {
  box-shadow: 0 0.46875rem 2.1875rem rgba(29, 20, 0, 0.03),
    0 0.9375rem 1.40625rem rgba(29, 20, 0, 0.03),
    0 0.25rem 0.53125rem rgba(29, 20, 0, 0.05),
    0 0.125rem 0.1875rem rgba(29, 20, 0, 0.03);
}

.box-shadow-remove {
  box-shadow: unset !important;
}

.text-align-left {
  text-align: left !important;
}

.input-focus-white:focus {
  background-color: white !important;
}

.registration-number-help-label {
  opacity: 0.4;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  text-align: center;
}

.vehicle-type-image-bike {
  width: 90%;
  object-fit: contain;
  margin-left: 10px;
}

.vehicle-type-image-car {
  width: 100%;
  object-fit: contain;
}

.skuid-example-text {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: #6e777f;
}

.stencil-number-textbox {
  text-transform: capitalize;
  /* width: 86px; */
}

.stencil-number-textbox-rear-right {
  margin-left: 80%;
  display: block;
  width: 66% !important;
}

.err-msg {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: red;
}

.skuIdBack {
  height: calc(1.5em + 0.5rem + 2px);
}

/*Custom Check Box*/

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container label {
  cursor: pointer;
  display: flex;
}

.checkbox-container input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.checkbox-container label::before {
  content: "";
  width: 1em;
  height: 1em;
  /* margin-right: .5em; */
  border: solid 2px #fdbb1f;
  margin-left: auto;
}

.checkbox-container label::after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container label:hover::before,
.checkbox-container input[type="checkbox"]:hover + label::before {
  background-color: #fff;
}

.checkbox-container input[type="checkbox"]:disabled + label,
.checkbox-container input[type="checkbox"]:disabled {
  color: #aaa;
  cursor: default;
}

.checkbox-container input[type="checkbox"]:checked + label::before {
  content: "\002713";
  background-color: #fdbb1f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 0px;
  margin-top: 2px;
}

.checkbox-container input[type="checkbox"]:disabled + label::before {
  background-color: #ccc;
  border-color: #999;
}

.checkbox-container {
  font-size: 2.3em;
}

.checkbox-name-wrapp {
  display: flex;
  flex-direction: column;
}

.checkbox-name {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(73, 80, 87);
}

.product-detail-edit-car-img {
  margin-top: -19px;
}

.checkbox-container-wrapp {
  display: flex;
  flex-direction: column;
}

.checkbox-container-right {
  display: flex;
  justify-content: flex-end;
}

.stencil-number-textbox-car-right-wrapp {
  margin-left: 0%;
  width: 46%;
  margin-top: 12%;
  position: absolute;
}

.stencil-number-textbox-bike-right-wrapp {
  margin-left: 84%;
  width: 30%;
  margin-top: 9%;
  position: absolute;
}

.right-tyre-bike-checkbox {
  width: 470px;
  position: absolute;
  margin-top: 106px;
}

.product-detail-edit-bike-img {
  margin-top: 52px;
  width: 78%;
}

.front-left-right-tyre-bike {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 325px;
  margin-top: 0%;
  margin-left: 45px;
  top: 101%;
}

.bottom-stencil-number-textbox-bike-right-wrapp {
  margin-left: -15%;
  width: 46%;
  margin-top: 13%;
  position: absolute;
}

.stencil-number-textbox-bike-left-wrapp {
  margin-left: -53px;
  width: 140px;
}

.front-left-car-sten-num {
  margin-left: -100px;
}

@media screen and (min-width: 220px) and (max-width: 991px) {
  .front-left-car-sten-num {
    margin-left: 0px;
  }
  .front-left-car-sten-num {
    width: 50%;
  }
  .front-left-right-tyre-car {
    margin-top: 10%;
  }
  .checkbox-container {
    font-size: 1.3em;
  }
  .product-detail-edit-car-img {
    margin-top: 0px;
    max-width: 120px;
  }
  .stencil-number-textbox-car-left {
    font-size: 12px !important;
  }
  .err-msg {
    font-family: Helvetica;
    font-size: 12px;
  }
  .checkbox-container-right input[type="checkbox"]:checked + label::before {
    margin-top: 0px;
  }
  .front-left-right-tyre-car {
    width: 271px;
  }
  .stencil-number-textbox-car-right {
    font-size: 12px !important;
  }
  .stencil-number-textbox-car-right-wrapp {
    margin-left: 1%;
    width: 35%;
    margin-top: 10%;
  }
  .back-left-right-tyre-car {
    width: 271px;
    margin-left: -74px;
    top: 62%;
  }
  .square-tyre-car {
    width: 115px;
    margin-left: unset;
  }
  .product-detail-edit-bike-img {
    margin-top: 52px;
    width: 68%;
  }
  .stencil-number-textbox-bike-right-wrapp {
    margin-left: 77%;
    max-width: 100%;
    margin-top: 9%;
    position: absolute;
    width: 37%;
  }
  .front-left-right-tyre-bike {
    width: 180px;
    margin-left: 5%;
  }
  .stencil-number-textbox-bike-left-wrapp {
    margin-left: -15px;
    width: 80% !important;
  }
  .stencil-number-textbox-bike-left {
    font-size: 12px !important;
  }
  .bottom-stencil-number-textbox-bike-right-wrapp {
    margin-left: 1%;
    width: 70%;
    margin-top: 13%;
  }
  .right-tyre-bike-checkbox {
    width: 79%;
    position: absolute;
    margin-top: 85px;
  }
}

.number-of-tyres-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.number-of-tyres-label-without-bold {
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.disable-vehicle {
  background-color: lightgray;
}

.gradient-title {
  background-image: linear-gradient(to right, rgba(30, 87, 248, 0.55), #491b9f);
  color: white;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* background-color: #5583b3; */
}

.service-pref-note-warranty {
  position: relative;
  height: auto;
  float: left;
  width: 95%;
  color: #495057;
  background-color: #fff2d3;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  /* font-style: oblique; */
  line-height: normal;
  letter-spacing: normal;
  border-radius: 7px;
  border-top-right-radius: 0;
  padding: 16px 2.5em;
  margin-bottom: 20px;
}

.service-pref-note-warranty:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: #fff #fff #fdbb1f #fdbb1f;
  border-style: solid;
  border-width: 0 16px 16px 0;
  background: #fdbb1f;
  display: block;
  width: 0;
  margin-right: 0;
}
