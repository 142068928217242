#invite-dealer .page-title {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#invite-dealer .checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-radius: 2px;
}

#invite-dealer .invite-button{
  min-width: 218px;
  height: 36px;
  /* width: 165px; */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
  padding: 0px 60px;
}

/* On mouse-over, add a grey background color */
#invite-dealer .container-cb:hover input ~ .checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
#invite-dealer .container-cb input:checked ~ .checkmark {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Create the checkmark/indicator (hidden when not checked) */
#invite-dealer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#invite-dealer .container-cb input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#invite-dealer .container-cb .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}




#invite-dealer tr,#invite-dealer td{
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #6e777f;
}

#invite-dealer th{
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color:#f6f6f6;
}


#invite-dealer td{
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#invite-dealer th,#invite-dealer  tr{
  /* width: 100px !important; */
  border: 1px solid rgb(0,0,0,0.1);
}

#invite-dealer td{
  border: 1px solid rgb(0,0,0,0.1);
}

#invite-dealer .link{
  text-decoration: underline;
  color: #545cd8;
  font-family: Helvetica;
  cursor: pointer;
}

#invite-dealer .no-invitee {
  height: 46px;
  font-family: Helvetica;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: oblique;
  line-height: 1.64;
  letter-spacing: normal;
  color: #6e777f;
}
