
.cip-vehicle-tab-active{
  background: #FDBB1F;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-width: 113px;
  height: 38px;
  display: flex;
  text-align: center;
  font-size: 14px;
    color: #495057;
    font-weight: bold;
}

.cip-vehicle-tab-in-active{
  min-width: 113px;
  height: 38px;
  display: flex;
  text-align: center;
  font-size: 14px;
  color: #495057;
}

.cip-vehicle-tab-in-active:hover{
  cursor: pointer;
}
.cip-vehicle-tab-active:hover{
  cursor: pointer;
}

.vehicle-related-tbl,
.vehicle-related-tbl td,
.vehicle-related-tbl th,
.vehicle-related-tbl tr
{
    border: none !important;
}

.vehicle-related-tbl{
  margin-left: 10px;
  margin-right: 10px;
}
.atleast-one-Tyre-Details-note{
  margin-left: 10px;
  margin-top: 25px;
  font-family: Helvetica;
font-style: italic;
font-weight: normal;
font-size: 12px;
line-height: 14px;
color: #838C95;
}

.cip-tyre-releated-tbl th{
  height: 35px;
  padding: 5px;
  font-size: 13px;
  width: 1%;
}
.cip-tyre-releated-tbl td{
  padding: 5px;
  border-right: 1px solid rgb(0,0,0,0.1);
}
 .business-related-detail-td {
  vertical-align: top;
}

.add-more-tyre{
  font-size: 14px;
    color: #545CD8;
    font-weight: bold;
}
/*  
.cip-tyre-releated-tbl tbody{
  display:block;
  overflow:auto;
  width:100%;
}
.cip-tyre-releated-tbl thead tr{
  display:block;
  width: 100% !important;
} */

#CipTyreRelatedDetails{
  max-height: 65vh;
  overflow: auto;
}