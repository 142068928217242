#ho-dashboard .card-custom-header {
  margin: 0px;
  /* padding: 19px 0px; */
  background-image: linear-gradient(101deg, #f8e184, #f7d174);
  /* background-image: linear-gradient(101deg, #ffdf42, #fdbb1f); */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ho-dashboard .dashboard-page-card-title-big {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  color: #495057;
}

#ho-dashboard .dashboard-page-card-title-small {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  color: #495057;
}

#ho-dashboard .count-small {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545cd8;
}

#ho-dashboard .title {
  font-weight: bold;
  font-size: 16px;
}

#ho-dashboard .sub-title {
  font-weight: bold;
  font-size: 12px;
}

#ho-dashboard .page-title {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#ho-dashboard .filter-popup {
  font-family: Helvetica;
  position: absolute;
  z-index: 1000;
  min-width: 319px;
  min-height: 437px;
  border: 1px solid black;
  text-align: left;
  background-color: white;
}

#ho-dashboard .filter-title {
  width: 43px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

#ho-dashboard .label-text {
  width: 50px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

#ho-dashboard .error-container {
  min-height: 15px;
  max-height: 15px;
}

#ho-dashboard .drop-down {
  width: 98%;
  height: 30px;
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 0px;
}

/* checkbox */

#ho-dashboard .container-cb {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
}

/* Hide the browser's default checkbox */

#ho-dashboard .container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

#ho-dashboard .checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */

#ho-dashboard .container-cb:hover input~.checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */

#ho-dashboard .container-cb input:checked~.checkmark {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Create the checkmark/indicator (hidden when not checked) */

#ho-dashboard .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

#ho-dashboard .container-cb input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

#ho-dashboard .container-cb .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* redio button */

#ho-dashboard .radion-container {
  display: inline;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

#ho-dashboard .radion-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

#ho-dashboard .radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

#ho-dashboard .radion-container:hover input~.radio-checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

#ho-dashboard .radion-container input:checked~.radio-checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

#ho-dashboard .radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

#ho-dashboard .radion-container input:checked~.radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

#ho-dashboard .radion-container .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdbb1f;
}

.ho-filter-sidebar {
  position: Fixed !important;
  width: 480px;
  max-width: 90%;
  margin-top: 60px;
  z-index: 2000 !important;
}

#ho-dashboard td {
  color: #495057
}

#ho-dashboard .hyper-link {
  color: #545cd8 !important;
  font-family: Helvetica;
  text-decoration: underline;
}

#ho-dashboard .padding-right-47px {
  padding-right: 47px !important;
}