#primary-point-report-dealer .chart-card-padding {
  /* padding-left: 0px; */
  padding-bottom: 0px;
  /* padding-right: 0px; */
  min-height: 297px;
}

#primary-point-report-dealer .chart-col-padding {
  /* padding-left: 0px;
  padding-right: 0px; */
}

#primary-point-report-dealer .hide-x-axis {
  left: 26px;
  min-width: 85%;
  position: absolute;
  top: 26px;
  background-color: #fff;
  min-height: 5px;
  z-index: 1;
}

#primary-point-report-dealer .chart-title {
  margin-top: -23px;
  margin-bottom: 11px;
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  color: #495057;
}

#primary-point-report-dealer .chart-main-title {
  /* padding-left: 11px; */
  margin-bottom: -18px;
}

#primary-point-report-dealer .title-text {
  font-family: Helvetica;
  font-size: 15px;
  font-weight: bold;
  color: #495057;
}

#primary-point-report-dealer .count-offtake-title {
  opacity: 0.7;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  color: #495057;
}

#primary-point-report-dealer .offtake-card-big-label {
  opacity: 0.7;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  color: #495057;
}

#primary-point-report-dealer .offtake-card-small-label {
  opacity: 0.7;
  font-family: Helvetica;
  font-size: 10px;
  font-weight: bold;
  color: #495057;
}

#primary-point-report-dealer .offtake-card-count {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  color: #495057;
  margin-top: 7px;
}

#primary-point-report-dealer .point-report-button {
  border-radius: 15px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #545cd8;
  background-color: #ffffff57;
  height: 30px;
  width: 100%;
  border: 0px;
  line-height: 30px;
}

#primary-point-report-dealer .point-report-button-container {
  margin-top: auto;
  margin-bottom: auto;
}
#primary-point-report-dealer .offtake-card {
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(126deg, #ffdf42c2 16%, #fdbb1fad 91%);
}

#primary-point-report-dealer .offtake-data-container {
  display: inline-block;
  padding-right: 14px;
  min-width: 33%;
}

#primary-point-report-dealer .title-margin {
  margin-bottom: 25px;
}

#primary-point-report-dealer .offtake-data-container-main {
  border-bottom: 1px solid #ffffff7a;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#primary-point-report-dealer .padding-right-0 {
  padding-right: 0px !important;
}

#primary-point-report-dealer .table-header-text {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  color: #495057;
}

#primary-point-report-dealer .table-data-text {
  font-family: Helvetica;
  font-size: 12px;
  color: #495057;
}

#primary-point-report-dealer .table th {
  background-color: #f1f4f6;
}

#primary-point-report-dealer .table th,
#primary-point-report-dealer .table td {
  border: solid 1.4px #ced4da;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  text-align: center;
}

#primary-point-report-dealer .table tr {
  border: 0px;
}

#primary-point-report-dealer .table-balance {
  font-weight: bold;
  border-right-width: 2px !important;
}

#primary-point-report-dealer .table-left-no-border {
  border-left: 0px !important;
}

#primary-point-report-dealer .table-top-no-border {
  border-top: 0px !important;
}

#primary-point-report-dealer .table-right-no-border {
  border-right: 0px !important;
}

#primary-point-report-dealer .table-bottom-no-border {
  border-bottom: 0px !important;
}

#primary-point-report-dealer .target-achievement-table-card {
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  min-height: 239px;
}

#primary-point-report-dealer .target-achievement-table-card-body {
  padding: 0px;
}

#primary-point-report-dealer .table {
  margin-bottom: 0px;
}

#primary-point-report-dealer .category-width {
  width: 128px;
}

#primary-point-report-dealer .category-cell {
  background-color: white !important;
  border-bottom: 0px !important;
  text-align: left !important;
}

#primary-point-report-dealer .bold-border {
  border-right-width: 2px !important;
}

#primary-point-report-dealer .chart-row {
  min-height: 268px;
}

#primary-point-report-dealer .chart-card-body {
  min-height: 297px;
}

#primary-point-report-dealer .button-link-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding-bottom: 13px !important;
  padding-top: 30px !important;
}

#primary-point-report-dealer .trade-scheme-report-link {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #545cd8;
  cursor: pointer;
}

#primary-point-report-dealer .current-offer-button {
  width: 158px;
  height: 35px;
  border-radius: 17.5px;
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #495057;
  margin-left: 20px;
}

#primary-point-report-dealer .card-radius-4 {
  border-radius: 4px;
}

#primary-point-report-dealer .dealer-type {
  font-size: 14px;
  font-weight: normal;
}

#primary-point-report-dealer .quarter-label {
  font-size: 10px;
  color: #81868a;
}

#primary-point-report-dealer .quarter-apm-col {
  padding-left: 0px;
  padding-right: 0px;
}

#primary-point-report-dealer .target-indicator {
  position: absolute;
  margin-top: -6px;
  color: #fdbb1f;
}

#primary-point-report-dealer .target-indicator-text {
  margin-left: 32px;
  font-size: 12px;
  font-weight: bold;
  color: #495057;
}

#primary-point-report-dealer .target-indicator-container {
  position: relative;
  margin-top: -19px;
}

#primary-point-report-dealer .year-month-drop-down {
  width: 30%;
  display: inline-block;
  background-color: #fff;
  border: 0px;
  color: #545cd8;
  font-weight: bold;
}

#primary-point-report-dealer .month-drop-down {
  width: 48px;
}

#primary-point-report-dealer .year-drop-down {
  width: 58px;
}

.dealer-profile {
  position: Fixed !important;
  width: 60%;
  max-width: 90%;
  /* overflow-y: auto !important; */
}
