/* .bar .Download-icon{
    margin-top: 10px;
    margin-left: 30px;
    align-items: center;
    display: flex;
    gap:5px;
    color: #006aff;
    font-weight: 500;
}
 */

#analysisPage .download-btn {
  /* cursor: pointer;
   border: 1px solid #0d6efd;
   color: #5d37e7;
   background-color: white;
   border-radius: 4px;
   text-align: center; */
   width: fit-content;
  padding: 0px 10px;
  /* min-width: 80px; */
  margin-top: 5px;
}

#analysisPage .analysis-dwn-card1 {
  margin: 10px 0;
}
#analysisPage .analysis-dwn-card2 {
  margin: 10px 0;
}
@media only screen and (min-width: 992px) {
  #analysisPage .analysis-dwn-card1 {
    margin: 0 10px 0 0 !important;
  }
  #analysisPage .analysis-dwn-card2 {
    margin: 0 0 0 10px !important;
  }
}

#analysisPage .dealers-details{
  width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* #analysisPage .download-btn:hover {
    background-color: #ddd;
  } */
