#bulkUpload .custom-dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-top: 6px;
}
.static-image {
  width: 470.2px;
  height: 247px;
  border: solid 1px #ced4da;
}

#bulkUpload .fix-height {
  height: calc(100% - 147px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.textarea-of-upload-file {
  width: 314px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #ced4da;
}

.select-group {
  width: 142px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
  cursor: pointer;
}

.background-image {
  position: absolute;
  margin-top: -44px;
  margin-left: -9px;
}

.upload-image {
  position: absolute;
  margin-top: -28px;
  margin-left: 28px !important;
  cursor: pointer;
}

.font-style {
  font-size: 12px;
  font-weight: bold;
  margin-top: 3px;
}

.No-file-chosen {
  position: absolute;
  margin-top: -25px;
  margin-left: 160px !important;
  width: 150px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: grey;
}
