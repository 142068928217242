html,
body {
  font-family: Helvetica;
  /* text-transform: capitalize; */
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.color-blue {
  color: blue;
}

.font-bold {
  font-weight: bold;
}

.sidenav-link.sidenav-active {
  font-weight: bold;
  font-size: 16px;
  color: #fdbb1f;
  /* background: #e0f3ff; */
}

.showingText {
  font-size: 16px;
}

.active-tbl-row {
  background-color: rgba(255, 230, 171, 0.66);
}

.border-e9ecef {
  border: "solid 1.5px #e9ecef";
}

.sidenav-link {
  transition: transform 0.2s, height 300ms, color 300ms, background-color 300ms;
  display: block;
  line-height: 2.5rem;
  height: 2.5rem;
  /* padding: 0 1.5rem 0 45px; */
  position: relative;
  border-radius: 0.25rem;
  color: #343a40;
  white-space: nowrap;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.94;
  letter-spacing: normal;
  color: #4a5157;
}

.sidenav-icon-padding {
  padding: 0px 21px 0px 13px;
  margin-top: -5px;
}

.nav-item {
  padding: 7px 0px;
}

/* register profile, profile, update profile common css */

.profile-image-mask {
  width: 140px;
  height: 140px;
  border-radius: 70px;
  /* border: solid 4px #ffffff; */
  background-color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
}

.cover-image-container {
  width: 100%;
  height: 251px;
  background-image: linear-gradient(to bottom, #495057, #6e777f);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image-camera-middle {
  position: absolute;
  top: 30%;
  left: 32%;
  height: 0px;
  width: 0px;
}

.profile-image-camera-side {
  /* position: absolute;
  top: 58%;
  left: 58%;
  height: 0px; */
  width: 0px;
}

.cover-image-upload-icon {
  position: absolute;
  right: 74px;
  left: auto;
  top: 1%;
  height: 0px;
  width: 0px;
}

.user-name-mobile {
  font-family: Helvetica;
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.card-cover-image {
  padding: 0px 16px 0px 16px !important;
}

.profile-upload-image-icon {
  border-radius: 50%;
}

/* video css */

.video-react .video-react-big-play-button {
  background: url("/images/play.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
  height: 40px !important;
  width: 39.8px !important;
  border-radius: 50% !important;
  /* top: 38% !important;
  left: 41% !important; */
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  margin: auto !important;
}

.video-react-icon-play-arrow:before,
.video-react .video-react-bezel .video-react-bezel-icon-play:before,
.video-react .video-react-play-control:before,
.video-react .video-react-big-play-button:before {
  content: "" !important;
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  width: 100%;
  max-width: 100%;
  height: 251px !important;
  border-radius: 7px;
}

.video-title {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  padding: 2px 10px;
}

/* end video css */

/*end register profile, profile, update profile common css */

/* put all padding related classes here */

.padding-left-0 {
  padding-left: 0px;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-left-13 {
  padding-left: 13px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-18 {
  padding-left: 18px;
}

.padding-right-15 {
  padding-right: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.padding-top-0 {
  padding-top: 0px !important;
  padding-right: 15px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.margin-top-0 {
  margin-top: 0px;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-15 {
  padding: 15px;
}

.padding-right-47px {
  padding-right: 47px;
}

.position-absolute {
  position: absolute;
}

/* put all margin related classes here */

.margin-5 {
  margin: 5px;
}

.margin-right-16 {
  margin-right: 16px;
}

.margin-left-4 {
  margin-left: 4px;
}

head .margin-right-25 {
  margin-right: -25px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-11 {
  margin-left: 11px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-150 {
  margin-top: 150px;
}

.margin-top-53 {
  margin-top: 53px;
}

.margin-left--10 {
  margin-left: -10px;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-top-40 {
  margin-top: 40px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

/* put all width related classes here */

.width-100 {
  width: 100%;
}

.width-0 {
  width: 0px;
}

.icon-width {
  width: 16px
}

/* put all text related classes here */

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

/* height related css */

.height-100 {
  height: 100%;
}

.height-15 {
  height: 15px;
}

/* end height related css */

/* put all display related classes here */

.display-none {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

/* modal related classes */

.modal-dialog {
  box-shadow: none !important;
}

.modal {
  height: 100vh !important;
}

/* input related css */

.required-input {
  border: 1px solid #fdbb1f !important;
}

.required-field {
  color: #fdbb1f;
}

input {
  font-size: 14px !important;
}

input::placeholder {
  text-transform: capitalize !important;
}

/*end input related css */

.card-yellow-border {
  border: 1px solid #fdbb1f !important;
}

.about-jkadvantage-title {
  font-family: Helvetica;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.about-jkadvantage-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #6e777f;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-7 {
  border-radius: 7px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.card-count {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: right; */
  color: #495057;
}

.error {
  color: red;
  font-size: 12px;
}

.modal-title {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

label {
  font-family: Helvetica;
  margin-bottom: 5px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.profile-cancel-button {
  width: 116px;
  height: 35px;
  border: 0px !important;
  border-radius: 17.5px !important;
  background-color: #e0e2e4 !important;
  font-family: Helvetica !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal;
  text-align: center;
  color: #6e777f !important;
}

.profile-save-button {
  width: 116px;
  height: 35px;
  border: 0px !important;
  border-radius: 17.5px !important;
  border: solid 1px #fdbb1f !important;
  background-color: #fdbb1f !important;
  font-family: Helvetica !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495057 !important;
}

.lable-bckground {
  margin-left: 30px;
  background-color: #feeabb !important;
  margin-top: 55px;
  min-height: 40px;
  padding-top: 7px;
  border-radius: 5px;
}

.lable-bckground-pink {
  margin-left: 30px;
  background-color: #f3d0d0 !important;
  color: #ef2b57;
  margin-top: 55px;
  min-height: 40px;
  padding-top: 7px;
  border-radius: 5px;
}

.title-pink {
  height: 18px !important;
  font-family: Helvetica !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #ef2b57;
  margin-top: 5px;
  cursor: pointer;
}

.title-case {
  text-transform: capitalize;
}

.capital-case {
  text-transform: uppercase;
}

.swal-modal {
  width: 570px;
  opacity: 0;
  pointer-events: none;
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
  position: static;
  margin: 20px auto;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  z-index: 10001;
  transition: opacity 0.2s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s, -webkit-transform 0.3s;
}

@media (max-width: 500px) {
  .swal-modal {
    width: calc(100% - 20px);
  }
}

.padding18px {
  padding: 18px;
}

.max-width-100percnt {
  max-width: 100%;
}

.swal-button--confirm {
  color: #000000;
  background-color: #e0e2e4;
}

.swal-button--confirm:focus {
  overflow: hidden;
  box-shadow: none;
}

.swal-button:not([disabled]):hover {
  background-color: #e0e2e4;
}

.swal-button--cancel {
  color: #000000;
  background-color: #fdbb1f;
}

.swal-button--cancel:not([disabled]):hover {
  background-color: #fdbb1f;
}

.swal-text {
  font-size: 16px;
  display: block;
  text-align: center;
  color: #61534e;
}

.swal-title {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  text-transform: none;
  position: relative;
  display: block;
  padding: 0px 16px;
  line-height: normal;
}

.swal-icon--warning {
  width: 71px;
  height: 71px;
}

input[type="text"]::placeholder {
  /* Firefox, Chrome, Opera */
  color: grey;
}

input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: grey;
}

input[type="text"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: grey;
}

/*  spinner  */

.spinner-middle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  color: #fdbb1f;
}

.spinner-middle-con {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.25);
  z-index: 119;
}

.white-space-nowrap {
  white-space: nowrap;
}

.display-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-pull-right {
  float: right;
}

.custom-anchor-tag {
  color: #545cd8 !important;
  text-decoration: underline;
  cursor: pointer;
}

.send-verify-otp-modal {
  width: 350px;
}

.overflow-auto {
  overflow: auto;
}

.current-page {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.prev-page-link {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

.min-height-163 {
  min-height: 163px;
}

.max-height-190 {
  max-height: 190px;
}

/* #region :: offset score card(primary report all), warranty score card (secondary report all) css  */

.card-point-header {
  /* width: 80px;
  height: 17px; */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #6e777f;
  line-height: 18px;
}

.card-point-value {
  /* width: 81px;
  height: 29px; */
  font-family: Helvetica;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.card-icon {
  width: 100%;
  height: 88px;
  object-fit: contain;
}

.card-content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* text-align: center; */
}

.single-dealer-table-card-header {
  /* width: 235px;
  height: 24px; */
  font-family: Helvetica;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

.download-report-text {
  /* width: 145px;
  height: 14px; */
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #545cd8;
}

.black-color {
  color: #000000;
}

.opacity-1-0 {
  opacity: 1 !important;
}

.opacity-0-4 {
  opacity: 0.4 !important;
}

.report-search {
  position: absolute;
  top: 10px;
  right: 25px;
}

.report-search-input {
  width: 395px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: #ffffff;
}

.download-report {
  width: 100px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545cd8;
}

.download-report-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.reset-filter-wrapp:hover {
  cursor: pointer;
}

.drawer-header {
  padding-top: 25px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
}

.drawer-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #495057;
}

.drawer-title-addCustomer {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #495057;
  margin-left: 15px;
}

.subsdiaries-sidebar {
  position: Fixed !important;
  width: 655px;
  max-width: 90%;
}

.customer-management-filter {
  position: Fixed !important;
  width: 39%;
  max-width: 90%;
  /* overflow-y: auto !important; */
}

.display-hidden {
  display: none;
}

.link-blue {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8 !important;
}

.link-blue:hover {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.drawer-footer {
  padding-bottom: 15px;
  background-color: #fafafa;
  padding-top: 10px;
}

::-webkit-scrollbar {
  width: 8px !important;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #f7b933;
}

::-webkit-scrollbar-track {
  /* background-color: #F5F5F5; */
  background-color: lightgray;
}

/* #endregion */

.drawer-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #495057;
}

.cursor-pointer:hover {
  cursor: pointer !important;
}

.no-data-hypen {
  width: 6px;
  height: 0;
  border: 1px solid #757575;
  display: flex;
}

@media screen and (min-width: 220px) and (max-width: 991px) {
  .send-verify-otp-modal {
    width: 92%;
  }
}

.text-none {
  text-transform: none !important;
}

/* #custom drawer sticky */

.custom-drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.custom-drawer-content {
  height: 100%;
  overflow-y: auto;
}

.custom-drawer-footer {
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-top: auto;
}

.opacity-0-5 {
  opacity: 0.5;
}

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.line-height-2 {
  line-height: 2;
}

.color-green {
  color: green;
}

/* radio button */

.container-rb {
  display: inline;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.container-rb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark-rb {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.container-rb:hover input~.checkmark-rb {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

.container-rb input:checked~.checkmark-rb {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark-rb:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.container-rb input:checked~.checkmark-rb:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.container-rb .checkmark-rb:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdbb1f;
}

/* checkbox */

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
}

/* Hide the browser's default checkbox */

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */

.container-checkbox:hover input~.checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */

.container-checkbox input:checked~.checkmark {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.container-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.container-checkbox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.width-30-pecentage {
  width: 30% !important;
}

.confirm-modal-title {
  width: 138px;
  height: 19px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.06;
  letter-spacing: 0.06px;
  color: #495057;
}

.confirm-modal-title-wrapp {
  padding: 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.page-title {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

/* slider design */

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: hidden !important;
}

.rc-slider-tooltip {
  top: 280px !important;
}

.rc-slider-tooltip-inner {
  padding: 0 8px;
  min-width: 0px !important;
  height: 0px !important;
  line-height: normal !important;
  color: #495057 !important;
  text-align: center;
  text-decoration: none;
  background-color: transparent !important;
  /* border-radius: 0.25rem; */
  /* box-shadow: 0 0.46875rem 2.1875rem rgba(29, 20, 0, 0.03), 0 0.9375rem 1.40625rem rgba(29, 20, 0, 0.03), 0 0.25rem 0.53125rem rgba(29, 20, 0, 0.05), 0 0.125rem 0.1875rem rgba(29, 20, 0, 0.03); */
  font-weight: normal !important;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.tr-f6f6f th {
  color: #545a60;
  font-size: 13px;
  background-color: #f6f6f6;
}

.font-size-18px {
  font-size: 18px;
}

.rodal-center {
  display: flex;
}

.rodal-center .rodal-dialog {
  position: relative;
  margin: auto;
  top: 2%;
}

.rodal-close {
  display: none;
}

.no-data-without-border {
  font-size: 14px;
  color: #838c95;
  font-style: italic;
  text-align: center;
  margin-top: 10px;
  margin-top: 12px;
  width: 80%;
  margin: auto;
  display: flex;
}

.form-control:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #ced4da !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.form-control::-webkit-input-placeholder {
  font-size: 14px;
  text-transform: none !important;
}

select {
  /* -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/images/down_black.png");
  background-repeat: no-repeat; */

  /* background-position-x: 97%;
  background-position-y: 13px;
  background-size: 10px 6px; */

  /* 
  background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: center;
    background-size: 10px 6px; */
}

.line-height-18px {
  line-height: 18px;
}

.ReactModal__Overlay--after-open {
  z-index: 1050 !important;
}

.input-group-text {
  position: absolute !important;
  top: 3px !important;
  right: 4px !important;
  z-index: 101 !important;
  background-color: #fff !important;
  border: 0 !important;
}

.display-botttom-datepicker .react-datepicker-popper {
  transform: none !important;
  margin-top: 35px;
  margin-left: -35px;
}

.display-margin-left-n-35 .react-datepicker-popper {
  margin-left: -35px;
}

.filter-icon {
  width: 16px;
  height: 30px;
  object-fit: contain;
}

.filter-icon-selected {
  width: 20px;
  height: 25px;
  object-fit: contain;
}

.Add-Customer-Visit-btn {
  width: 200px;
  display: flex !important;
}

.border-0-5-dee2e6 {
  border: 0.5px solid #dee2e6;
  height: 30px;
  margin-left: 23px;
  margin-right: 15px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  display: none;
}

.font-size-16px {
  font-size: 16px;
}

.font-size-15px {
  font-size: 15px;
}

.font-size-13px {
  font-size: 13px;
}

.action-menu {
  position: absolute;
  top: 34px;
  left: -91px;
  box-shadow: 1px 1px 16px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
  background-color: white;
}

.action-menu-content {
  padding: 10px;
  /* background-color: white; */
  z-index: 10;
  font-family: Helvetica;
  font-size: 12px;
  text-align: center;
  color: #495057;
}

select:focus {
  border-color: #fdbb1f;
  box-shadow: 0 1px 1px white inset, 0 0 8px white;
  outline: 0 none;
}

.dashboard-banner-full-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.textarea-autosize:focus {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.m-top-24px {
  margin-top: 24px;
}

.swal-text {
  font-size: 15px;
}