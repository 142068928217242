.overdue-service-lbl{
font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
color: #495057;
}
.over-due-services-modal.modal-dialog{
  width: 542px !important;
}
.over-due-services-list{
  border: 1.001px solid #CED4DA;
box-sizing: border-box;
border-radius: 8px;
padding: 15px 0px 15px 0px;
}
.overdue-service-note{
  font-family: Roboto;
font-style: italic;
font-weight: normal;
font-size: 12px;
line-height: 14px;
color: #92969A;
text-transform: none;
}
.overdue-servic-vehicle-type{
  max-width: 95px !important;
    padding-right: 0 !important;
}
.overdue-servic-vehicle-detail{
  padding-left: 5px !important;
    padding-right: 5px !important;
    max-width: 120px !important;
}
.overdue-servic-vehicle-purpose{
  padding-left: 5px !important;
  padding-right: 5px !important;
  max-width: 100px !important;
}
.overdue-servic-vehicle-service{
  padding-left: 5px !important;
  padding-right: 5px !important;
  max-width: 100px !important;
}
.overdue-servic-vehicle-overdue-date{
  padding-left: 5px !important;
  padding-right: 5px !important;
  max-width: 85px !important;
}