#gift-catalog .category-bg {
  background-color: rgba(241, 244, 246, 0.8);
}

#gift-catalog .menu-title {
  width: 36px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#gift-catalog .ho-filter-sidebar {
  width: 480px;
  max-width: 90%;
  margin-top: 60px;
}

#gift-catalog .category-title {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 29px;
  letter-spacing: normal;
  color: #495057;
  /* margin-left: -15px; */
  padding-left: 15px;
  width: 100%;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(110, 119, 127, 0.2);
}

#gift-catalog .category {
  /* width: 28px; */
  /* height: 17px; */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#gift-catalog .sub-category {
  /* width: 28px; */
  /* height: 17px; */
  margin-left: 10px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057db;
}

#gift-catalog .category-selected {
  font-weight: bold;
}

#gift-catalog .gift-bg {
  min-height: 100vh;
  background-color: white;
  margin-top: -29px;
  padding-top: 15px;
  margin-bottom: -30px;
  padding-bottom: 30px;
}

#gift-catalog .logo-thumb-img {
  /* width: 70px !important;
    height: 100% !important; */
  height: 143px;
  object-fit: contain !important;
  /* margin-top: 10px; */
  /* max-height: 70px !important; */
}

#gift-catalog .point-bg {
  width: 125px;
  height: 18px;
  border-radius: 9px;
  /* background-color: #fdbb1f; */
  background-color: #fdbb1f63;
  border-radius: 9px 0px 0px 9px;
  line-height: 18px;
  /* width: 99px;
  height: 14px; */
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.37px;
  color: #495057;
  position: absolute;
  right: -5px;
  top: -11px;
  text-align: center;
}

#gift-catalog .label-bg {
  width: 84px;
  height: 18px;
  border-radius: 9px;
  background-color: #fdbb1f;
  /* background-color: #fdbb1f63; */
  border-radius: 0px 9px 9px 0px;
  line-height: 18px;
  /* width: 99px;
  height: 14px; */
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.37px;
  color: #495057;
  position: absolute;
  left: -5px;
  top: -11px;
  text-align: center;
}

#gift-catalog .card-title {
  /* width: 144px; */
  /* height: 34px; */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.44px;
  margin-top: 20px;
  color: #495057;
}

#gift-catalog .content-center {
  /* display: flex;
    justify-content: center;
    align-items: center; */
  margin: auto;
  padding: 0px;
}

#gift-catalog .code {
  /* width: 103px; */
  height: 12px;
  font-family: Helvetica;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#gift-catalog .description {
  /* width: 103px; */
  /* height: 12px; */
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#gift-catalog .merchandise-wallet-count-lbl-wrapp {
  padding: 15px !important;
  padding-bottom: 10px !important;
}

#gift-catalog .merchandise-wallet-count-lbl-wrapp.customcss {
  padding-top: 5px !important;
}

#gift-catalog .merchandise-wallet-count-card-redemption {
  background-image: linear-gradient(120deg, #ffdf42, #fdbb1f);
  height: 80px;
}

#gift-catalog .merchandise-wallet-coins-lbl {
  font-size: 14px;
}

#gift-catalog .rc-slider {
  width: 89%;
}

#gift-catalog .primary-report-tabs .rc-tabs-top .rc-tabs-nav-wrap {
  padding: 0px;
  overflow: unset;
}

#gift-catalog .card-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-left: 5px;
  margin-right: 5px;
}

#gift-catalog .back-to-top-button {
  position: fixed;
  top: 100px;
  z-index: 100;
}

#gift-catalog .more-css {
  font-size: 14px;
  text-decoration: underline;
}

#gift-catalog .redeem-points {
  color: #545cd8;
  line-height: 18px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
}

/* Range slider css */

.input-range__label-container {
  left: -50%;
  position: relative;
  font-family: Helvetica;
}

.input-range__slider-container {
  transition: none !important;
}

.input-range__track {
  transition: none !important;
}

.input-range__slider {
  transition: none !important;
  background: #fff;
  border-color: #4950572e;
  transform: scale(1.3);
}

#gift-catalog .select {
  position: relative;
  -webkit-appearance: none;
  background: url("/images/down-arrow.svg") no-repeat right #fff;
  background-position-x: 96%;
  font-family: Helvetica;
}

#gift-catalog .view-report {
  position: absolute;
  color: rgb(84, 92, 216);
}

#gift-catalog .cart-count-lable {
  font-size: 14px;
  font-family: Helvetica;
  color: #545cd8;
  margin-left: 10px;
  font-weight: bold;
}

#gift-catalog .cart-count-count {
  font-size: 20px;
  font-family: Helvetica;
  color: #545cd8;
  margin-left: 10px;
}

.input-range__label {
  color: #495057;
}

.input-range {
  /* margin-top: 30px; */
  width: 90%;
}

.input-range__track--active {
  background: #fdbb1f;
}

.input-range__label--min {
  left: 0;
  display: none;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
  display: none;
}

.min-input-range {
  margin-right: 10%;
}

.max-input-range {
  margin-left: 10%;
}

.infinite-loader-css {
  width: 100%;
  text-align: center;
  margin-top: 18px;
  color: #fdbb1f;
}

#EntryScreenCatelog .modal {
  display: block;
  max-height: 98%;
  overflow: hidden;
}

.highlight-text {
  color: #fdbb1f;
}

.points-range {
  padding: 0px;
  margin-top: 10px;
  margin-left: 7px;
}

#inputRange .input-range__label-container {
  display: none;
}

.minValue {
  font-size: 10px;
  margin-left: -3px;
}

.maxValue {
  font-size: 10px;
  float: right;
}

.error-msg {
  font-size: 12px;
  color: red;
  font-family: Helvetica;
  margin-top: -10px;
  margin-left: 15px;
}

.radion-container {
  display: inline;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radion-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}

.radion-container:hover input~.radio-checkmark {
  background-color: #fff;
}

.radion-container input:checked~.radio-checkmark {
  background-color: #fff;
}

.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radion-container input:checked~.radio-checkmark:after {
  display: block;
}

.radion-container .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdbb1f;
}

#gift-catalog .cart-grey-title {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a5a8aa;
  margin-top: 5px;
  margin-bottom: 5px;
}

.table-total {
  height: 30px;
  background-color: #feeabb !important;
  padding-top: 5px;
  padding-left: 10px;
}