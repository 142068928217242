.network-header {
  height: 60px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(29, 20, 0, .03), 0 0.9375rem 1.40625rem rgba(29, 20, 0, .03), 0 0.25rem 0.53125rem rgba(29, 20, 0, .05), 0 0.125rem 0.1875rem rgba(29, 20, 0, .03);
  background-color: #fafbfc;
  padding: 0 1.5rem;
}

.network-sidebar {
  height: 100%;
  width: 60px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(29, 20, 0, .03), 0 0.9375rem 1.40625rem rgba(29, 20, 0, .03), 0 0.25rem 0.53125rem rgba(29, 20, 0, .05), 0 0.125rem 0.1875rem rgba(29, 20, 0, .03);
  background-color: #ffffff;
  padding: 30px;
}

html, body, #root {
  height: auto;
}

.no-network-img {
  /* height: 420px;
  width: 420px; */
  object-fit: contain;
}

.NO-NETWORK-CONNECTION {
  font-family: Roboto;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  color: var(--gunmetal);
}

.Could-not-connect-to {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: var(--gunmetal);
}

.Try-Again {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

.Try-Again :hover {
  cursor: pointer;
}

.no-network-header {
  padding: 0 1.5rem;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: width .2s;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;
}

@media (max-width: 900px) {
  .NO-NETWORK-CONNECTION {
    font-family: Roboto;
    font-size: 18px;
  }
  .Could-not-connect-to {
    font-family: Roboto;
    font-size: 14px;
  }
}