#footer .footer{
  background-color: white;
  color: #6f7880;
  padding: 10px 0px 10px 100px;
  font-size: 11px;
  text-align: center;
}

@media only screen and (max-width: 768px){
  #footer .footer{
    padding: 10px 26px 10px 26px;
  }
}
