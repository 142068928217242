.success-title{
  font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #495057;
}
.success-message{
  font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: center;
color: #495057;
}