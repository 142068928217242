.page-title-wrapper {
  box-shadow: 0 0.46875rem 2.1875rem rgba(29, 20, 0, 0.03), 0 0.9375rem 1.40625rem rgba(29, 20, 0, 0.03), 0 0.25rem 0.53125rem rgba(29, 20, 0, 0.05), 0 0.125rem 0.1875rem rgba(29, 20, 0, 0.03);
  border-radius: 7px;
  background-color: #fff;
  padding: 10px 15px;
}

.page-title-wrapper .page-title-icon {
  font-size: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 2px;
  margin: 0 15px 0 0;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgba(29, 20, 0, 0.03), 0 0.9375rem 1.40625rem rgba(29, 20, 0, 0.03), 0 0.25rem 0.53125rem rgba(29, 20, 0, 0.05), 0 0.125rem 0.1875rem rgba(29, 20, 0, 0.03);
  border-radius: 0.25rem;
  width: 60px;
  height: 60px;
  /* margin-right: 20px; */
  overflow: hidden;
}
.page-title{
  font-size: 20px;
  font-weight: 600;
}

.page-title-wrapper .page-title-icon .titleImg{
  width: 56px;
  height: 56px;
  object-fit: cover;
}