.MuiDrawer-paper {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  margin-top: 62px;
  border-radius: 4px;
  background-color: #fdfdfb !important;
  -webkit-overflow-scrolling: touch;
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  touch-action: none;
  justify-content: center;
  background-color: none !important;
  -webkit-tap-highlight-color: transparent;
}

.MuiBackdrop-invisible {
  background-color: transparent;
}

.backbtn {
  background: transparent !important;
  border: transparent !important;
  color: blue !important;
  font-size: 16px !important;
}

.resetfilter {
  width: 90px;
  height: 19px;
  font-family: Helvetica;
  font-size: 13px;
  margin-left: 438px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: blue;
}

.selectgroup {
  padding-left: 27px;
  padding-top: 16px;
  color: #8e8e8e;
  opacity: 0.4;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

.selectgrplabel {
  width: 250px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #848482;
}

.padding-left-10 {
  padding-left: 10px !important;
  color: #848482;
  font-weight: bold;
}

.datepickerfrom {
  width: 160px !important;
  height: 32px;
  opacity: 1;
  border-radius: 2px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.startingfrom {
  width: 85px;
  height: 17px;
  opacity: 0.4;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

.to {
  display: inline;
  padding-left: 213px;
  margin-top: -48px;
}

.vehicledetails {
  display: inline;
  margin-top: 10px;
  margin-left: 10px;
}

.vehicledetailsmk {
  display: inline;
  margin-top: 10px;
}

.dropdownCampaign {
  max-width: 61%;
  max-height: 30px;
}

.txtarea {
  width: 505px;
  height: 210px;
  opacity: 0.4;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
  position: absolute;
}

.testwithtextarea {
  width: 427px;
  height: 31px;
  opacity: 0.5;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: var(--gunmetal);
  position: absolute;
  z-index: 100;
  padding-top: 70px;
  padding-left: 121px;
}

.campaign-drawer {
  position: Fixed !important;
  width: 600px;
  max-width: 90%;
  overflow-y: unset !important;
}

#parrent-campanign-customize {
  padding-right: 15px;
  padding-left: 15px;
}

.drawer-title-campaign {
  padding-top: 20px;
  padding-left: 15px;
  font-weight: bold;
}

.custmgtfltr {
  margin-left: 25px !important;
  margin-right: 15px !important;
  margin-top: 18px;
}

.Select-Group {
  width: 150px;
  height: 17px;
  opacity: 0.4;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

.Rectangle-Copy-3 {
  width: 160px;
  height: 32px;
  opacity: 0.4;
  border-radius: 2px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.Rectangle-Copy-2 {
  width: 91;
  height: 32px;
  opacity: 0.4;
  border-radius: 2px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.Rectangle-Copy-12 {
  width: 160px;
  height: 32px;
  opacity: 0.4;
  border-radius: 2px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.Group-13 {
  width: 18px;
  height: 18px;
  object-fit: contain;
  opacity: 0.4;
  margin-left: -44px;
  margin-top: 6px;
}

.Rectangle-Copy-11 {
  width: 160px;
  height: 32px;
  opacity: 0.4;
  border-radius: 2px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.Rectangle-Copy-13 {
  width: 160px;
  height: 32px;
  opacity: 0.4;
  border-radius: 2px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.Rectangle-Copy-14 {
  width: 200px;
  height: 32px;
  opacity: 0.4;
  border-radius: 2px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.Rectangle-Copy-8 {
  width: 160px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
  margin-top: -6px;
}

.Rectangle-Copy-18 {
  width: 505px;
  height: 210px;
  opacity: 0.4;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
  position: absolute;
}

.You-can-directly-cop {
  width: 419px;
  height: 50px;
  opacity: 0.5;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: var(--gunmetal);
  position: absolute;
  padding-top: 80px;
  padding-left: 100px;
}

/* .otherlang:hover + .otherlangcontent-wrapp {
  display: block;
}

#editorimage:hover + .entermsgcontent-wrapp {
  display: block;
}

.entermsgcontent-wrapp {
  display: none;
}

.entermsgcontent-wrapp :hover + .entermsgcontent-wrapp {
  display: block;
} */
