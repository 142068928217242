.About-the-Program {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.The-Advantage-progra {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #495057;
}

.enroll-li {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #495057;
}

.point-structure-card {
  width: 220px !important;
  /* opacity: 0.8; */
  border-radius: 12.6px;
  background-color: #f4dfad;
  height: 144px;
  -webkit-overflow-scrolling: touch;
  /* Lets it scroll lazy */
  /* min-width: 220px; */
  min-width: 195px;
}

.point-structure-card:hover {
  background-color: #fdbb1f;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.activeCategory {
  background-color: #fdbb1f;
  color: #495057;
  opacity: 1;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.35);
}

.point-structure-type {
  opacity: 1;
  font-family: Helvetica;
  /* font-size: 15px; */
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.51px;
  color: #495057;
}

.point-structure-type-wrapp {
  padding-top: 17px;
  display: flex;
  min-height: 68px;
}

.point-structure-img {
  width: 74px;
  height: 50.2px;
  object-fit: contain;
  margin-left: auto;
  margin-top: -8px;
}

.Tyre {
  opacity: 1;
  font-family: Helvetica;
  /* font-size: 14.4px; */
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.point-structure-point {
  opacity: 1;
  font-family: Helvetica;
  /* font-size: 23px; */
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-top: 5px;
}

.point-structure-data {
  display: flex;
  flex-direction: column;
}

.point-structure-point-wrapp {
  display: flex;
  padding-bottom: 10px;
  flex-direction: row;
}

.d-flex-row {
  display: flex;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.pasanger-car-redial-card {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.tbl-about th,
.tbl-about td {
  text-align: center;
}

#about-program tr {
  border: solid 0.9px #e9ecef;
}

#about-program th {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  border: 1px solid #e9ecef;
}

#about-program td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
  border: 1px solid #e9ecef;
}

.PASSENGER-CAR-RADIAL-label {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-bottom: 15px;
}

.tbl-offtake {
  width: 15% !important;
  margin-top: 33px;
  margin-right: 1.5rem !important;
}

.pasanger-car-redial-wrapp {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin-left: 30px;
  width: 93%;
}

.point-structure-wrapp {
  margin-top: 0px;
  margin-left: 2px;
  /* overflow: auto;
  -webkit-overflow-scrolling: touch; */
  /* Lets it scroll lazy */
}

.Enrollment-ul {
  list-style: none;
  padding-left: 26px;
}

.Enrollment-ul li::before {
  content: "\2022";
  color: #fdbb1f;
  font-size: 22px;
  font-weight: bold;
  display: inline-block;
  width: 0.8em;
  margin-left: -1em;
  position: absolute;
  margin-top: -6px;
}

.table-responsive {
  display: table !important;
}

.carousel-about-item {
  /* padding-left:10px;
  padding-right:10px; */
}

.tbl-offtake {
  margin-right: 1.5rem !important;
}

/* .point-structure-wrapp::-webkit-scrollbar {
  display: none;
} */

.container1 {
  overflow: hidden;
  position: relative;
}

.Base-Points-80-Tyre {
  width: 138px;
  border-radius: 1px;
  border: solid 1px #b5babe;
  margin-left: auto;
  margin-bottom: 10px;
  display: flex;
}

.Base-Points-80-Tyre-label {
  font-size: 12px;
  margin: auto;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b5babe;
  padding: 5px;
}

.truckbus-note {
  width: 60%;
  border-radius: 1px;
  border: solid 1px #b5babe;
  margin-left: auto;
  margin-bottom: 10px;
  display: flex;
  margin: auto;
}

.two-three-wheeler-note {
  margin-right: 20px;
}

.tdd-size-td {
  background-color: white;
  text-align: center;
  border: solid 0.9px #e9ecef;
}

.tbl-tbb {
  margin-right: 20px;
}

.two-three-wheeler-multilple-offer-note {
  margin-top: 20px;
}

.two-three-wheel-lable {
  font-size: 14px;
}

.two-three-wheel-lable-wrapp {
  margin-top: -40px;
}

.about-tab .rc-tabs-nav-wrap {
  padding-left: 0 !important;
  padding-top: 10px !important;
}

.secondary-point-struct-wrapp {
  max-width: 316px !important;
}

.about-tab-header .card-header-tab {
  padding-top: 0;
}

.about-tab-header .card-header > .nav .nav-link {
  opacity: 0.4;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  text-align: center;
  color: #4a5157;
}

.about-tab-header .card-header > .nav .nav-link.active {
  opacity: 1;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  text-align: center;
  color: #4a5157;
}

.Point-Structure-for {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.padding-15px-15px-0 {
  padding: 15px 15px 0;
}

.show-more-copy {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fdbb1f;
}

.two-three-wheel-chart-wrapp {
  margin-left: auto;
  margin-right: auto;
}

.all-pcrtype-wrapp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pcrtype-wrapp {
  padding-right: 15px;
}

.date-visit-input-lbl {
  background-color: #e9ecef;
  opacity: 1;
  padding: 7px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.fy-label {
  position: absolute;
  right: 147px;
  background-color: white;
  padding: 7px;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.set-right-dropdown {
  text-align: right;
  text-align: -webkit-right;
}
#about-program .form-control {
  width: 130px;
}
@media screen and (min-width: 900px) and (max-width: 2000px) {
  .point-structure-card {
    max-width: 250px !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 899px) {
  .point-structure-card {
    max-width: 250px !important;
  }
  .point-structure-wrapp {
    flex-wrap: wrap !important;
  }
}

@media screen and (min-width: 220px) and (max-width: 480px) {
  /* .col-md-12, .col-lg-12 {
    padding-left: 0px !important;
  } */
  .point-structure-wrapp {
    flex-wrap: wrap !important;
  }
  .point-structure-card {
    width: 100% !important;
  }
  .secondary-point-structure {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pasanger-car-redial-wrapp {
    margin-left: 15px;
  }
  .About-the-Program {
    font-size: 14px;
  }
  .table-responsive {
    display: block !important;
  }
  .point-structure-type {
    font-size: 14px;
  }
  .point-structure-point {
    opacity: 1;
    font-family: Helvetica;
    font-size: 22.2px;
    margin-top: 10px;
  }
  .tbl-offtake {
    margin-right: 0.5rem !important;
  }
  .PASSENGER-CAR-RADIAL-label {
    font-size: 14px;
  }
  .truck-bus-wrapper {
    flex-wrap: wrap;
  }
  .tbl-tbb {
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .truckbus-note {
    width: 100%;
  }
  .two-three-wheeler-note {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .two-three-wheel-chart-wrapp {
    flex-wrap: wrap;
  }
  .two-three-wheel-chart {
    margin-left: -20px;
  }
  .secondary-point-struct-wrapp {
    max-width: 100%;
  }
  .about-tab-header .card-header-tab {
    padding-left: 0;
    padding-right: 0;
  }
  .about-tab-header .card-header-tab .nav-link {
    padding: 0;
  }
  .Point-Structure-for {
    font-family: Helvetica;
    font-size: 16px;
  }
  .about-main-tab .nav-item {
    width: 100px;
  }
  .pcrtype-wrapp {
    margin-bottom: 10px;
  }
  .Base-Points-80-Tyre {
    margin-left: unset;
  }
  .all-pcrtype-wrapp {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }
}
