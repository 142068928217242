.filer-popup {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: block !important;
  padding: 24px;
  border-radius: 2px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  min-height: 436px;
  max-height: inherit;
  position: absolute;
  z-index: 100;
  max-width: 600px;
  margin-left: 250px;
  margin-top: 10px;
  overflow: unset;
}

.dropbox-purpose {
  height: 36px;
  width: 400px !important;
  background-color: white;
  border-color: rgb(169, 169, 169);
}

.filter {
  margin-top: 25px;
  margin-bottom: 2rem;
  font-size: 20px;
  color: black;
}

.filter-option {
  display: inline-flex;
  margin-bottom: -45px;
  margin-top: 50px;
}

.d-flex-row {
  display: flex !important;
  flex-direction: row;
}
.w-input-select,
.w-input-text {
  width: 100%;
  font-size: 14px;
  height: 32px;
  padding: 4px 20px;
  display: inline-block;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  background-color: #fff;
  box-sizing: border-box;
  color: #757575;
}

#filterpopup .date-picker-left-plus-15 .react-datepicker-popper {
  left: 150px !important;
}
