input::placeholder {
  text-transform: none !important;
}

.padding-top-bottom-0 {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
}

.field-label {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-top: 15px;
}

.option-text {
  font-family: Helvetica;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 10px; */
  letter-spacing: normal;
  color: #495057;
}
.filter-section-sub-title {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #838c95;
  margin-top: 4px;
}

.padding-left-3 {
  padding-left: 3px;
}

.link-blue {
  font-weight: bold;
}

.data-container {
  overflow-y: auto;
  height: 82%;
  height: calc(100% - 126px);
}

.width-75 {
  width: 75%;
}

.margin-top-29 {
  margin-top: 29px;
}

::-webkit-scrollbar {
  width: 7px;
}

#profile-detail .profile-image {
  min-width: 80px;
  min-height: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 89.5px;
  border: solid 1px #b5babe;
}

.link {
  color: #545cd8;
}

.drawer {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-transform: none;
  letter-spacing: normal;
  color: #495057;
}
.business-name {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}
