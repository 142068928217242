.circle{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border:1px solid black;
  line-height: 40px;
  text-align: center;
  margin-top: 22px;
}
.ho-remark{
  margin-top:56px;
}
.ho-remarkk{
  margin-top: 24px;
}
.chariman-club{
  background:#E0E2E4 !important;
  border-color:#F1F4F7 !important;
  border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}
.other-club{
  background:#E0E2E4 !important;
  border-color:#F1F4F7 !important;
  border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}
.circle-status{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /* border:1px solid black; */
  line-height: 40px;
  text-align: center;
  background: #FDBB1F;
  /* margin-top: 22px; */
}
.circle-rejected{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border:1px solid #A3ABB3;
  line-height: 40px;
  text-align: center;
  background: #A3ABB3 !important;
  /* margin-top: 22px; */
}
.circle-statuss{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border:1px solid black;
  line-height: 40px;
  text-align: center;
  background: #838C95;
  /* margin-top: 22px; */
}
.recce-status{
  margin-top: 48px;
  margin-left: 20%;
  margin-right: 10%;
  margin-bottom: 12px;
}
.approval-status{
  font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 13px;
    /* margin-left: -97%; */
}
.status-font{
  font-family: Helvetica;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 13px;
    /* margin-left: -97%; */
  font-weight: 400;
}
.dealer-info{
  font-family: Helvetica;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 18px;

color: #495057;
}