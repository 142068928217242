.YOUR-REWARDS-ARE-READY-WRAPP {
  min-height: 64px;
  height: auto;
  background-image: linear-gradient(to right, rgba(30, 87, 248, 0.55), #491b9f);
  color: white;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.YOUR-REWARDS-ARE-REA {
  font-family: Helvetica;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: AUTO;
  margin-bottom: auto;
  margin-left: 30px;
}

.Your-opportunity-to {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: AUTO;
  margin-bottom: auto;
  margin-left: 30px;
}

#primary-point-report .gradient-title {
  background-image: linear-gradient(to right, rgba(30, 87, 248, 0.55), #491b9f);
  color: white;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* background-color: #5583b3; */
}


#primary-point-report .gradient-title.gradientBgImg{
  background-image: url('../../assets/images/clubGroupBgJk.png');
  background-position: center;
  /* background-size: cover; */
  background-repeat: no-repeat;
  max-width: 66%;
}

#primary-point-report .gradient-sub-title {
  font-family: Helvetica;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}


.Points-Report {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-bottom: 12px;
}

.hr-line {
  border: solid 1px #fdbb1f;
}

.points-card-wrapp {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.points-card {
  /* width: 360px; */
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;
  min-height: 130px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  /* margin-right: 14px; */
  max-width: 32.33333%;
}

.points-img-wrapp {
  margin-top: 20px;
  margin-bottom: auto;
  margin-left: 15px;
}

.points-data-wrapp {
  margin-top: 25px;
  margin-bottom: auto;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.points-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.points {
  font-family: Helvetica;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
  margin-top: 10px;
}

.Youre-yet-to-reach {
  width: 686px;
  height: 19px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: normal;
  color: #6e777f;
}

.Learn-more-about {
  cursor: pointer;
  text-decoration: underline;
}

.rc-tabs-tab {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.rc-tabs-tab-active, .rc-tabs-tab-active:hover {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057 !important;
}

.quarter-points-wrapp {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.QUARTER-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  padding-bottom: 15px;
}

.quarter-points-inner {}

.QUARTER-tables {
  display: flex;
  margin-top: 15px;
}

.QUARTER-table {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
}

.Total-Offtake {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.Till-Date {
  font-family: Helvetica;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b5babe;
}

.quarter-tbl-left {
  margin-right: 2%;
}

.primary-report-tabs .rc-tabs-top .rc-tabs-tab-arrow-show {
  margin-top: 25px;
}

.primary-report-tabs .rc-tabs-tab-prev-icon, .rc-tabs-tab-next-icon {
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold !important;
  color: #495057;
}

.total-points-card {
  background-image: linear-gradient(111deg, #ffdf42, #fdbb1f);
}

.total-point-lable {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.earned-label {
  width: 145px;
}

.Provisional-label {
  width: 170px;
}

.card-offtake-summary {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.Total-Earned-Points {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.offtake-tbl-th {
  width: 15%;
}

.primary-report-tabs .rc-tabs-nav-wrap {
  padding-bottom: 13px !important;
  padding-top: 30px !important;
  padding-left: 0 !important;
}

.These-Points-are-pr {
  opacity: 0.6;
  font-family: Helvetica;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #6e777f;
}

.tbl-offtake-summary {
  width: auto !important;
  max-width: 80%;
}

.Download-Full-Report {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 2px;
  color: #545cd8;
  margin: auto;
}

.no-data {
  color: #a9a9a9;
  border: solid 0.9px #e9ecef;
  border-top: none;
  padding: 20px;
  font-size: 16px;
}

.primary-report-tabs .rc-tabs-bar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.Download-Full-Report-wrapp {
  margin-top: 15px;
}

.primary-report-tabs .rc-tabs-nav-scroll {
  width: max-content !important;
}

.tbl-tbb-tbr-earnpoints {
  overflow: auto;
  display: block !important;
}

.tbl-tbb-tbr-earnpoints-quarter-table {
  max-width: 50%;
}

.total-offtake-elgible-wrapp {
  justify-content: space-between;
  padding-right: 5px;
  flex-wrap: wrap;
}

.year-filter-position-modal{
  position: absolute;
    right: 22px;
    top: 31px;
    z-index: 10;
}

#primary-point-report  .d-flex-row {
  display: flex;
}

#primary-point-report  .d-flex-column {
  display: flex;
  flex-direction: column;
}



@media screen and (min-width: 481px) and (max-width: 1000px) {
  .points-card {
    width: 295px;
  }
  .QUARTER-tables {
    flex-wrap: wrap;
  }
  .QUARTER-table {
    margin-top: 30px;
    width: 100%;
  }
  .earned-label, .Provisional-label {
    width: 100%;
  }
  .tbl-offtake-summary {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 220px) and (max-width: 770px) {
  .QUARTER-table table td {
    width: 100%;
  }
  .QUARTER-tables {
    margin-top: 0px;
  }
  .YOUR-REWARDS-ARE-READY-WRAPP {
    flex-wrap: wrap;
  }
  .tbl-offtake-summary {
    max-width: 100% !important;
  }
  .YOUR-REWARDS-ARE-REA {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
  .Your-opportunity-to {
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
    margin-bottom: 10px;
    text-align: center;
  }
  .points-card {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    padding-top: 10px;
  }
  .quarter-tbl-left {
    margin-right: 0%;
  }
  .QUARTER-tables {
    flex-wrap: wrap;
  }
  .QUARTER-table {
    margin-top: 20px;
    width: 100%;
  }
  .earned-label, .Provisional-label {
    width: 100%;
  }
  .points-data-wrapp {
    width: 150px;
  }
  .points-label {
    padding-right: 7px;
  }
  .primary-report-tabs .rc-tabs-bar {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 10px;
  }
  .Download-Full-Report-wrapp {
    margin-left: auto;
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .tbl-tbb-tbr-earnpoints-quarter-table {
    max-width: 100%;
  }
}
.total-premium-offtake{
   font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #495057;
    margin-right: 3px;
}
.total-premium-offtake-value{
  font-family: Helvetica;
    font-size: 16px;
    font-weight: bolder;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #495057;
}