#CustomerRating {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url("/images/ref_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#CustomerRating .logo {
  width: 215px;
}

#CustomerRating .ask-ref {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  color: #fdbb1f;
}

#CustomerRating .Please-Provide-Reference {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  color: #495057;
}

.color-545CD8 {
  color: #545cd8;
}

.customer-refence-detail-col {
  max-width: 475px !important;
}

.padding-32px {
  padding: 32px !important;
}

#CustomerRating .store-name {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #495057;
  opacity: 0.4;
}

#CustomerRating .store-address {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #495057;
  opacity: 0.4;
}

.CustomerRatingSucesssLbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 157.1%;
  text-align: center;
  color: #495057;
}

.CustomerRatingSucesssLblWrapper {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media screen and (min-width: 220px) and (max-width: 768px) {
  #CustomerRating .Please-Provide-Reference {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 132.5%;
    color: #495057;
  }
  #CustomerRating {
    background-image: url("/images/ref_background_mobile.png");
  }
}
