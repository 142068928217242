.send-verify-otp-wrapp .Verification {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.send-verify-otp-wrapp .swal-hr {
  margin-left: -20px;
  margin-right: -20px;
}

.send-verify-otp-wrapp {
  text-align: left;
}

.Didnt-get-the-code {
  font-family: Helvetica;
  padding-bottom: 15px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.Didnt-get-the-code .text-style-1 {
  color: #545cd8;
  text-decoration: underline;
}

.Didnt-get-the-code .text-style-1:hover {
  cursor: pointer;
}

.swal-hr {
  margin-top: 10px;
}