
#change-password .modal-title {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#change-password.modal-dialog{
  width: 312px !important;
}

#change-password .modal-title-border {
 border-bottom:1px solid #ced4da;
}


#change-password .request-details {
  width: 99px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#change-password textarea::placeholder {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#change-password .cancel-button {
  width: 116px;
  height: 35px;
  border-radius: 17.5px;
  background-color: #e0e2e4;
border: none;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6e777f;
}

#change-password .send-button {
  width: 116px;
  height: 35px;
  border-radius: 17.5px;
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
}

#change-password .container-cb {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
}

/* Hide the browser's default checkbox */
#change-password .container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
#change-password .checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
#change-password .container-cb:hover input ~ .checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
#change-password .container-cb input:checked ~ .checkmark {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Create the checkmark/indicator (hidden when not checked) */
#change-password .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#change-password .container-cb input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#change-password .container-cb .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


