.ReferralRewardsPreferenceNote {
  font-family: Helvetica;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 166.4%!important;
  /* or 20px */
  color: #6E777F !important;
}

#ReferralRewardsPreference .offer-lbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
}

#ReferralRewardsPreference .inputbox-textarea {
  width: 99%;
  height: 92px;
  border-radius: 4px;
  background-color: var(--white);
  font-size: 13px;
  color: #495057;
}

#ReferralRewardsPreference .text-area-chip {
  border: solid 1px #ced4da;
  padding-left: 5px;
  padding-right: 0px;
}

#ReferralRewardsPreference textarea {
  color: #495057;
}

#ReferralRewardsPreference .rth-content mark {
  /* background-color: #bdbdbd; */
  padding: 1.9px;
  padding-right: 0 !important;
}

#ReferralRewardsPreference .Rectangletag {
  /* width: 107px; */
  height: 30px;
  border-radius: 15px;
  background-color: #eeeeee;
  margin-left: 10px;
  padding: 5px;
}

#ReferralRewardsPreference .Rectangletag {
  width: auto;
  height: 30px;
  border-radius: 15px;
  background-color: #eeeeee;
  margin-left: 10px;
  padding: 5px;
  margin-top: 5px;
  padding-left: 6px;
  font-size: 13px;
}

.same-as-existing-customer {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 127.1%;
  color: #495057;
}

.p-r-30px {
  padding-left: 10px;
  padding-right: 30px;
}

#ReferralRewardsPreference .entermsgcontent {
  width: 257px;
  border-radius: 5px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  position: absolute;
  z-index: 1;
  display: block;
  height: auto;
  padding: 10px;
  min-height: 165px;
  max-height: 350px;
  overflow: auto;
  margin-top: -282px;
}

#ReferralRewardsPreference .closebtn {
  font-size: 17px;
  color: #212529;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  float: right;
  margin-left: 136px;
  display: block;
  background-color: #f1f1f1;
  height: 30px;
  width: 30px;
  text-align: center;
  /* padding-top: 5px !important; */
  border-radius: 50%;
  opacity: 1;
  margin-top: -7px;
  padding-top: 4px;
}
.sent-to-referrer-note{
  font-family: Helvetica;
font-style: italic;
font-weight: normal;
font-size: 12px;
line-height: 14px;
color: #838C95;
margin-top: 7px;
margin-bottom: 13px;
}