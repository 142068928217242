
#pcr-scheme-dealer-modal{
  max-width: 1250px;
}

#pcr-scheme-dealer-modal .modal-content{
  min-height: 500px;
}

#pcr-scheme-dealer-modal  .modal-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  color: #495057;
  margin-top:auto;
  margin-bottom:auto;
}

#pcr-scheme-dealer-modal .modal-dialog{
  width: 70% !important;
}

#pcr-scheme-dealer-modal  .modal-title-border {
 border-bottom:1px solid #ced4da;
}

#pcr-scheme-dealer-modal  .modal-body {
 /* max-height:90vh;
 min-height:90vh;
 overflow-y:hidden; */
}

#pcr-scheme-dealer-modal .steps {
  /* height: 19px; */
  font-family: Helvetica;
  font-size: 16px;
  
  line-height: 21px;
  letter-spacing: normal;
  color: #495057;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}


#pcr-scheme-dealer-modal  .request-details {
  width: 99px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#pcr-scheme-dealer-modal  textarea::placeholder {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#pcr-scheme-dealer-modal  .cancel-button {
  width: 116px;
  height: 35px;
  border-radius: 17.5px;
  background-color: #e0e2e4;
border: none;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6e777f;
}

#pcr-scheme-dealer-modal  .send-button {
  width: 116px;
  height: 35px;
  border-radius: 17.5px;
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
}


#pcr-scheme-dealer-modal  .container {
  display: inline;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
#pcr-scheme-dealer-modal  .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
#pcr-scheme-dealer-modal  .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
#pcr-scheme-dealer-modal  .container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
#pcr-scheme-dealer-modal  .container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
#pcr-scheme-dealer-modal  .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
#pcr-scheme-dealer-modal  .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
#pcr-scheme-dealer-modal  .container .checkmark:after {
 	top: 3px;
	left: 3px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #fdbb1f;
}

