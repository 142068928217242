.back-lbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #545cd8;
  margin-top: auto;
  margin-bottom: auto;
}
.ho-remark:before{
  border-color: #fff #f7f6f4 #fdbb1f #fdbb1f !important;
}
.ma-bt{
  margin-bottom: 56px;
}