#campaignDetail {
  height: 100%;
}

#campaignDetail .Campaign1-Created-o {
  width: 282px;
  height: 25px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

#campaignDetail .text-style-1 {
  font-size: 21px;
  font-weight: bold;
  margin-left: 15px;
}

#campaignDetail .CampaignLabel {
  padding-top: 3%;
}

#campaignDetail .Purpose {
  width: 65px;
  height: 19px;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#campaignDetail .CustomerGroup {
  width: 65px;
  height: 19px;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  padding-left: 2%;
}

#campaignDetail .Birthday-Wish-Detail {
  width: 108px;
  height: 19px;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  padding-left: 1%;
}

#campaignDetail .DeleteIcon {
  width: 18px;
  height: 23px;
  object-fit: contain;
}

#campaignDetail .DuplicateIcon {
  width: 28px;
  height: 22px;
  object-fit: contain;
  background-color: var(--orangey-yellow);
}

#campaignDetail .Details-options {
  display: flex;
}

#campaignDetail .option {
  padding-right: 45px;
  padding-top: 18px;
  cursor: pointer;
}

#campaignDetail .option1 {
  padding-top: 18px;
  cursor: pointer;
}

#campaignDetail .Line-17 {
  width: 664px;
  border: solid 1px #e0e0e0;
}

#campaignDetail .Group-4 {
  background-image: url("/images/tran_halfiphone.png");
  height: 285px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 500px;
  margin-left: -125px;
}

#campaignDetail .sms-previewNotification {
  background-image: url(./../../assets/images/long_android.png);
  height: 285px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 245px;
}

#campaignDetail .notification-content-preview {
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-top: 40px;
}

#campaignDetail .notification_chatbox-wrapper {
  background-color: white;
  padding: 7px;
  font-weight: 900;
  color: #fff;
  position: relative;
  padding-top: 6px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242;
  word-break: break-word;
  min-height: 65px;
  border: 1px solid #9e9e9e;
  box-shadow: 1px 1px 10px #9e9e9e;
}

#campaignDetail .Line-16 {
  width: 285px;
  border: solid 1px #9e9e9e;
  margin-top: 225px;
  z-index: 1000;
  position: absolute;
  margin-left: 10%;
}

#campaignDetail .Group-6 {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

#campaignDetail .Download-Report {
  width: 100px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: blue;
  margin-left: 4px;
}

#campaignDetail .Download-icon {
  /* margin-left: 73%; */
  margin-left: auto;
  margin-top: -4%;
  cursor: pointer;
  margin-right: 8px;
}

#campaignDetail .Rectangle-Copy-7 {
  width: 157px;
  height: 35px;
  border: solid 0.9px #e9ecef;
  background-color: #f6f6f6;
}

#campaignDetail .Rectangle-Copy-6 {
  width: 157px;
  height: 35px;
  border: solid 0.9px #e9ecef !important;
  background-color: var(--white);
}

#campaignDetail .TableContent {
  width: 71px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#campaignDetail .table-bordered {
  border: 1px solid #e9ecef;
  margin-top: 3%;
}

#campaignDetail #editorimage {
  padding-left: 4px;
  height: 11px;
  cursor: pointer;
}

#campaignDetail .entermsgcontent {
  width: 228px;
  height: 219px;
  border-radius: 5px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  position: absolute;
  z-index: 100;
  margin-left: 116px;
  display: none;
}

#campaignDetail .otherlangcontent {
  width: 228px;
  height: 219px;
  border-radius: 5px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  position: absolute;
  z-index: 100;
  margin-left: 116px;
  /* display: none; */
}

#campaignDetail .otherlang:hover + .otherlangcontent {
  display: block;
}

#campaignDetail #editorimage:hover + .entermsgcontent {
  display: block;
}

#campaignDetail .data {
  width: 263px;
  height: 58px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#campaignDetail .contentdiv {
  display: inline;
}

#campaignDetail .picturedemo {
  width: 70px;
  height: 70px;
  opacity: 0.7;
  border-radius: 5px;
  background-color: #000000;
  padding-bottom: 2px;
}

#campaignDetail .picturedit {
  position: absolute;
  z-index: 100;
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-left: -18px;
  margin-top: 4px;
}

#campaignDetail .sms-content-preview {
  font-size: 12px;
  margin-top: 7%;
  padding-left: 159px;
  padding-right: 156px;
  width: 100%;
}

#campaignDetail .chatbox-inner {
  /* max-height: 170px; */
}

#campaignDetail .sms-content-preview-data {
  height: 115px;
  border-radius: 8px;
  padding: 5px;
  width: -webkit-fill-available;
  background-color: #ededed;
}

#campaignDetail .sms-preview {
  width: 389px;
  height: 448px;
}

#campaignDetail .Preview-Message {
  width: 108px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
  margin-left: 40%;
}

#campaignDetail .chatbox-wrapper {
  background-color: #ededed;
  padding: 7px;
  font-weight: 900;
  color: #fff;
  position: relative;
  padding-top: 6px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242;
  border-radius: 8px;
  word-break: break-word;
  min-height: 80px;
  max-height: 350px;
}

#campaignDetail .date-preview {
  font-size: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

#campaignDetail .run-now-link {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}

#campaign .link-warm-blue {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545cd8;
}

#campaignDetail .sms_draft_image_url:hover {
  cursor: pointer;
}
