
#registration .side-column-container{
  display: table;
  height: 100%;
  width:100%;
  text-align: center;
}

#registration .side-column-content{
  display: table-cell;
  vertical-align: middle;
}


#registration .action-ob-button {
  /* width: 278px; */
  padding: 14px 57px;
  /* height: 50px; */
  font-weight: bold;
  font-size: 18px;
  /* opacity: 0.5; */
  border-radius: 32px;
  /* border: solid 1px #fdbb1f;
  background-color: #fdbb1f; */
}


#registration .gradient-title{
  background-image: linear-gradient(to right, rgba(30, 87, 248, 0.55), #491b9f);
  color: white !important;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
  /* background-color: #5583b3; */
}


#registration .form-check{
  display: inline;
} 