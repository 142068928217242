.genAi .charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 40px;
  justify-content: flex-start;
}

.genAi .chart-column {
  flex: 0 0 auto;
  min-width: 300px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.genAi .chart-column:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.genAi .chart-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
  cursor: move;
  padding: 10px;
  background-color: #eaeaea;
  border-radius: 4px;
}

.genAi .resizable-box {
  margin-bottom: 20px;
}

.genAi .chart-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.genAi .chart-container {
  width: 100%;
  height: 100%;
  padding-top: 20px;
}

.genAi .chart-controls {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  z-index: 10;
}

.genAi .chart-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.genAi .chart-button:hover {
  background-color: #e0e0e0;
}

/* Styles for the resize handle */
/* .genAi .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.genAi .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 20px;
  background-color: #ccc;
  transition: background-color 0.3s;
}

.genAi .react-resizable-handle:hover::after {
  background-color: #999;
} */

/* Styles for dragging */
.genAi .chart-column.dragging {
  opacity: 0.5;
}