.tab-menu {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

#CreateCampaign .container {
  display: inline;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#CreateCampaign  .select {
  position: relative;
  -webkit-appearance: none;
  background: url("/images/down-arrow.svg") no-repeat right #fff;
  background-position-x: 98%;
  font-family: Helvetica;
}

#CreateCampaign .switch-container{
  width: 95%;
  height: 35px;
  border:1px solid black;
  padding:3px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  text-align:center;
  line-height: 27px;
  margin-bottom: 15px;
  color:#495057;
}

#CreateCampaign .switch-data{
  margin-top: 0px;
  display: inline-block;
  width: 33%;
  height: 100%;
  cursor:pointer;
}

#CreateCampaign .active-switch{
  color:white;
  font-weight:bold;
  background-color:#fdbb20;
  border-radius:5px;
 }

/* Hide the browser's default radio button */

#CreateCampaign .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Hide the browser's default radio button */

#CreateCampaign .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

#CreateCampaign .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

#CreateCampaign .container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

#CreateCampaign .container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

#CreateCampaign .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

#CreateCampaign .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

#CreateCampaign .container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdbb1f;
}

#CreateCampaign .block-border {
  border: 1px solid black;
}

#CreateCampaign .Rectangle {
  width: 387px;
  height: 50px;
  border: solid 1px #e0e0e0;
  background-color: var(--orangey-yellow);
}

#CreateCampaign .RectangleRow {
  width: 358px;
  height: 50px;
  border: solid 1px #e0e0e0;
  background-color: var(--white);
}

#CreateCampaign .RectangleYellow {
  width: 179px;
  height: 50px;
  border: solid 1px #e0e0e0;
  background-color: #fdcd1f;
  padding-top: 12px;
  padding-left: 45px;
  color: #56585a !important;
}

#CreateCampaign .Rectanglecompleted {
  width: 179px;
  height: 50px;
  border: solid 1px #e0e0e0;
  padding-top: 12px;
  padding-left: 45px;
  color: #56585a !important;
}

#CreateCampaign .Scheduled-campaigns {
  height: 158px;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: oblique;
  line-height: 1.28;
  letter-spacing: normal;
  color: var(--battleship-grey);
  padding-top: 10px;
  padding-left: 10px;
}

#CreateCampaign .Campaign-Management {
  width: 270px;
  height: 29px;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  margin-left: 35px;
  margin-bottom: 8px;
}

#CreateCampaign .Click-on-the-Create {
  width: 703px;
  height: 24px;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: oblique;
  line-height: 1;
  letter-spacing: normal;
  color: var(--gunmetal);
  scroll-padding-left: 10px;
  padding-left: 10px;
  padding: 20px;
}

#CreateCampaign .rectangleButton {
  width: 189px;
  height: 35px;
  border-radius: 17.5px;
  border: solid 1px var(--orangey-yellow);
  background-color: var(--orangey-yellow);
  background-color: #fdbb1f;
  color: #56585a !important;
}

#CreateCampaign .card-body {
  flex: 1 1 auto;
  padding-left: 15px;
}

#CreateCampaign .inputbox-textarea {
  width: 101%;
  height: 92px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

#CreateCampaign .Message-Credits-Used {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#CreateCampaign .Rectangletag {
  width: auto;
  height: 30px;
  border-radius: 15px;
  background-color: #eeeeee;
  margin-left: 10px;
  padding: 5px;
  margin-top: 5px;
  padding-left: 6px;
  font-size: 13px;
}

#CreateCampaign .Rectangletag:hover {
  cursor: pointer;
}

#CreateCampaign .scheduledcheckbox {
  width: 13px;
  height: 13px;
  border: solid 0.8px #fdbb1f !important;
}

#CreateCampaign .container-cc {
  display: block;
  position: relative;
  padding-left: 19px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
}

#CreateCampaign .container-cb {
  display: block;
  position: relative;
  padding-left: 19px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  line-height: 17px;
  float: right;
  padding-right: 0px;
}

#CreateCampaign .container-cc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Hide the browser's default checkbox */

#CreateCampaign .container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

#CreateCampaign .checkmarkrectangle {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #ffffff;
  border: solid 1px rgba(73, 80, 87, 0.66);
  border-color: #fdbb1f;
}

/* On mouse-over, add a grey background color */

#CreateCampaign .container-cb:hover input ~ .checkmarkrectangle {
  background-color: #ffffff;
}

/* On mouse-over, add a grey background color */

#CreateCampaign .container-cc:hover input ~ .checkmarkrectangle {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */

#CreateCampaign .container-cb input:checked ~ .checkmarkrectangle {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* When the checkbox is checked, add a blue background */

#CreateCampaign .container-cc input:checked ~ .checkmarkrectangle {
  background-color: #f7b924;
  border: solid 1px #f7b924;
}

/* Create the checkmark/indicator (hidden when not checked) */

#CreateCampaign .checkmarkrectangle:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

#CreateCampaign .container-cb input:checked ~ .checkmarkrectangle:after {
  display: block;
}

/* Show the checkmark when checked */

#CreateCampaign .container-cc input:checked ~ .checkmarkrectangle:after {
  display: block;
}

/* Style the checkmark/indicator */

#CreateCampaign .container-cb .checkmarkrectangle:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the checkmark/indicator */

#CreateCampaign .container-cc .checkmarkrectangle:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#CreateCampaign .mkcpyfrme {
  padding-left: 20px;
}

#CreateCampaign .sms-preview {
  background-image: url(./../../assets/images/iphonexcampaign.png);
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  /* width: calc(100% + 50px); */
  width: 100%;
  float: right;
  padding-bottom: 200px;
}

#CreateCampaign .sms-previewNotification {
  background-image: url(./../../assets/images/notification-halfIphone.png);
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  /* width: calc(100% + 50px); */
  width: 100%;
  float: right;
  padding-bottom: 200px;
}

#CreateCampaign .chatbox-wrapper {
  background-color: #ededed;
  padding: 7px;
  font-weight: 900;
  color: #fff;
  position: relative;
  padding-top: 6px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242;
  border-radius: 8px;
  word-break: break-word;
  min-height: 114px;
  max-height: 350px;
  margin: 4px;
}

input,
textarea {
  outline: none;
}

.chatbox-dot:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #ededed;
  border-right: 10px solid transparent;
  border-top: 10px solid #ededed;
  border-bottom: 10px solid transparent;
  left: 19px;
  bottom: -19px;
}

.chatbox-inner {
  overflow-y: auto;
  max-height: 280px;
}

#CreateCampaign .sms-content-preview {
  width: 100%;
  font-size: 12px;
  margin-top: 4%;
  padding-left: 25px;
  padding-right: 24px;
  padding-top: 22px;
  /* padding-bottom: 100px */
}

.sms-content-preview-data {
  height: 115px;
  border-radius: 8px;
  padding: 5px;
  width: -webkit-fill-available;
  background-color: #ededed;
}

.sms-preview {
  width: 389px;
  height: 448px;
}

#CreateCampaign .dropbox {
  width: 180px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
  color: #818181;
}

#CreateCampaign .Preview-Message {
  margin-left: auto;
  margin-right: auto;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
  width: auto;
  margin-bottom: 12px;
}

#CreateCampaign .noofwordss {
  width: 46px;
  height: 14px;
  text-align: right;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  /* position: absolute; */
  /* padding-top: 72px;
  margin-left: 293px; */
}

#CreateCampaign .dropbox option {
  width: 214px;
  height: 212px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #ced4da;
  background-color: var(--white);
}

#CreateCampaign .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

#CreateCampaign .choose_file {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  border: #c0c0da solid 1px;
  width: 110px;
  padding: 4px 6px 4px 8px;
  font: normal 14px Myriad Pro, Verdana, Geneva, sans-serif;
  color: #7f7f7f;
  margin-top: 2px;
  background: white;
}

#CreateCampaign .choose_file input[type="file"] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

#CreateCampaign #editorimage {
  padding-left: 4px;
  height: 11px;
  cursor: pointer;
  margin-left: 4px;
}

#CreateCampaign .entermsgcontent {
  width: 257px;
  border-radius: 5px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  position: absolute;
  z-index: 1;
  margin-left: -100px;
  display: block;
  height: auto;
  padding: 10px;
  min-height: 165px;
  max-height: 350px;
  overflow: auto;
}

#CreateCampaign .otherlangcontent-wrapp {
  display: none;
}

#CreateCampaign .otherlangcontent {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  position: absolute;
  z-index: 1;
  margin-left: 0;
  display: block;
  padding: 10px;
  overflow: auto;
  margin-top: -30px;
  margin-left: 100px;
}

.otherlangcontent-wrapps {
  width: 400px;
}

#CreateCampaign .otherlang:hover + .otherlangcontent-wrapp {
  display: block;
}

#CreateCampaign #editorimage:hover + .entermsgcontent-wrapp {
  display: block;
}

#CreateCampaign .entermsgcontent-wrapp {
  display: none;
}

#CreateCampaign .entermsgcontent-wrapp :hover + .entermsgcontent-wrapp {
  display: block;
}

#CreateCampaign .data {
  width: 263px;
  height: 58px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#CreateCampaign .contentdiv {
  display: inline;
}

#CreateCampaign .picturedemo {
  width: 70px;
  height: 70px;
  opacity: 0.7;
  border-radius: 5px;
  background-color: #000000;
  padding-bottom: 2px;
  margin-top: 0px;
}

#CreateCampaign .picturedit {
  position: absolute;
  z-index: 1;
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-left: -18px;
  margin-top: 4px;
  background-color: #000000;
}

#CreateCampaign .Search {
  width: 59px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#CreateCampaign .Group-3 {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-top: 10px;
  margin-left: -25px;
  /* margin-left: 220px; */
}

#CreateCampaign .Group-33 {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-top: 10px;
  margin-left: 23px;
}

#CreateCampaign .Combined-Shape {
  height: 110px;
  background-color: rgba(255, 230, 171, 0.66);
  margin-left: -25px;
  margin-right: -15px;
  margin-top: 25px;
  cursor: pointer;
}

.Combined-Shape-whitebackground {
  height: 110px;
  margin-right: -15px;
  margin-left: -25px;
  margin-top: 19px;
}

#CreateCampaign .Campaign1 {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  padding: 6%;
}

#CreateCampaign .Birthday-Wish {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  padding-left: 6%;
  margin-top: -4%;
}

#CreateCampaign .Showing-scheduled-campaigns {
  margin-left: -25px;
  margin-right: -15px;
  background-color: rgba(241, 244, 246, 0.8);
  margin-top: 0px;
  margin-bottom: -26px;
  position: relative;
}

#CreateCampaign .All-500-Customers {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  margin-left: 14%;
}

#CreateCampaign .Fill-arrow {
  transform: rotate(-270deg);
  background-color: var(--orangey-yellow);
  margin-left: 20%;
}

#CreateCampaign .datefor {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
  margin-left: 6%;
  margin-top: 2%;
}

#CreateCampaign .scheduledfor {
  color: #a6aaae;
  font-weight: normal;
}

#CreateCampaign .One-uni-code-charact {
  font-family: Helvetica;
  font-size: 12px;
  color: var(--battleship-grey);
}

#CreateCampaign ul {
  list-style: none;
}

.dot {
  height: 6px;
  width: 29px;
  background-color: #fdbb1f;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
}

.radio-main-lbl {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.lbl-campaign {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

.rc-time-picker .rc-time-picker-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.rc-time-picker .rc-time-picker-clear {
  top: 8px;
}

@media screen and (min-width: 1400px) and (max-width: 2200px) {
  #CreateCampaign .chatbox-wrapper {
    margin: 13px;
  }
}

.select-picture-wrapper {
  width: 129px;
  height: 34px;
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ced4da;
  background-color: var(--white);
  display: flex;
  margin-top: 20px;
}

.Select-Picture-img {
  width: 18px;
  height: 14px;
  margin-top: auto;
  margin-bottom: auto;
  object-fit: contain;
  margin-left: 10px;
  margin-right: 7px;
  background-color: var(--warm-blue);
}

.Select-Picture {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545cd8;
  margin-top: auto;
  margin-bottom: auto;
}

.rth-container {
  display: inline-block;
  position: relative;
  overflow: hidden !important;
  -webkit-text-size-adjust: none !important;
  background: white;
  font-size: 13px;
}

.rth-backdrop {
  position: absolute !important;
  top: 0 !important;
  right: -99px !important;
  bottom: 0 !important;
  left: 0 !important;
  padding-right: 99px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.rth-highlights {
  width: auto !important;
  height: auto !important;
  border-color: transparent !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  color: transparent !important;
  overflow: hidden !important;
}

.rth-input {
  display: block !important;
  position: relative !important;
  margin: 0;
  padding: 0;
  border-radius: 0;
  font: inherit;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  width: 100%;
  height: 100%;
}

.rth-content {
  background: none transparent !important;
  border: 0;
  display: flex;
  line-height: 1.6;
  flex-wrap: wrap;
}

.rth-content mark {
  color: inherit;
  background-color: #d3d3d3;
  border-radius: 8px;
  height: 20px;
  margin-top: 2px;
  padding-left: 0px;
  /* padding: 1px 4px;
  margin: -1px -4px;
  margin-bottom: 4px;
  font: 400 13.3333px/21.3333px */
}

.tag-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.campaign-popup-notes {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
}

.One-uni-code-charact .Enrollment-ul {
  padding-left: 22px;
}

.scroll-container {
  font-family: Helvetica;
  font-size: 12px;
}

.Message-Details {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.d-linear {
  display: inline-flex;
}

.Create-New-Campaign {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

#CreateCampaign .react-datepicker-wrapper {
  display: block;
  z-index: 0;
}

.rc-time-picker-clear-icon {
  display: none;
}

#CreateCampaign .closebtn {
  font-size: 20px;
  color: #212529;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  float: right;
  margin-left: 136px;
  display: block;
  background-color: #f1f1f1;
  height: 30px;
  width: 30px;
  text-align: center;
  /* padding-top: 5px !important; */
  border-radius: 50%;
  opacity: 1;
  margin-top: -12px;
  padding-top: 4px;
}

.react-time-picker__wrapper {
  width: 120px;
  border-color: #ced4da;
  border: thin solid #ced4da !important;
  height: 35px;
  border-radius: 5px;
}

.react-time-picker__button:enabled {
  cursor: pointer;
  color: #e0e2e4;
  opacity: 0.5;
  margin-left: -10px;
}

/* #CreateCampaign .close {
  font-size: 13px;
  color: #212529;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  float: right;
  margin-left: 190px;
  display: block;
  background-color: #f1f1f1;
  height: 44px;
  width: auto;
  text-align: center;
  padding-top: 8px !important;
  border-radius: 40%;
  opacity: 1;
  margin-top: -5px;
} */

.react-time-picker__wrapper {
  width: 132px !important;
}
.react-time-picker__inputGroup {
  margin-left: 10px;
}

.react-time-picker__inputGroup__input {
  color: #485057;
}
.react-time-picker__inputGroup__input {
  min-width: 1.2em !important;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}

.react-time-picker {
  width: 138px;
}

.react-datepicker__input-container ::placeholder {
  text-transform: uppercase !important;
}

.notification-text {
  font-size: 12px;
  color: #6e777e;
  font-family: Helvetica;
  font-style: oblique;
}

.inputbox-textarea-notificatiion-title {
  width: 101%;
  height: 50px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

.camp-select-sender-id {
  width: 150px !important;
  font-size: 14px !important;
  padding-left: 8px !important;
}

.create-campaign-tooltip .popover.show {
  width: 400px;
  /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  border-radius: 6px; */
  background: #fff7de;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.popover {
  max-width: 400px;
}

.create-campaign-tooltip
  .bs-popover-auto[x-placement^="right"]
  > .arrow::after {
  border-right-color: #fff7de !important;
}
.create-campaign-tooltip .bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #fff7de !important;
}

.create-campaign-tooltip .popover-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
  padding-top: 15px;
  border-bottom: 1px solid #ced4da;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #fff7de;
}
.create-campaign-tooltip .arrow {
  top: 5px !important;
  /* background: #FFF7DE; */
}
.create-campaign-tooltip .bs-popover-right > .arrow::after {
  border-right-color: #fff7de;
}
.create-campaign-tooltip .bs-popover-left > .arrow::after {
  border-left-color: #fff7de;
}
.create-campaign-tooltip .bs-FFF7DE-left > .arrow::before {
  border-left-color: #fff7de;
  border-right-color: #fff7de;
}
.create-campaign-tooltip .bs-popover-right > .arrow::before {
  border-left-color: #fff7de;
  border-right-color: #fff7de;
}

.create-campaign-tooltip .popover-body {
  padding-left: 20px;
}

.create-campaign-tooltip .popover-lbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
}

.create-campaign-tooltip .popover-lbl-value {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #495057;
}

.box-shadow-40px-0-4 {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4) !important;
}

.box-shadow-40px-0-5 {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5) !important;
}

.create-campaign-tooltip .next,
.create-campaign-tooltip .prev {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #2f80ed;
}

.font-size-13px {
  font-size: 13px;
}
.create-campaign-tooltip .next-btn {
  width: 70px;
  font-size: 12px !important;
  padding: 0 !important;
  height: 28px !important;
}
.create-campaign-tooltip .prev-btn {
  width: 76px;
  font-size: 12px !important;
  padding: 0 !important;
  height: 28px !important;
}
