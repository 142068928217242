#graph-mobile .tab-container{
  padding: 0px 0px;
  background-color: white;
}

#graph-mobile .color-block{
    padding: 4px 21px;
    background: rgb(201, 112, 255);
}

#graph-mobile .car{
  padding: 4px 21px;
  background: rgb(201, 112, 255);
}

#graph-mobile .truck{
  padding: 4px 21px;
  background: #9ad6d0
}

#graph-mobile .farm{
  padding: 4px 21px;
  background:#9ad851
}

#graph-mobile .wheeler{
  padding: 4px 21px;
  background: #30b1ff
}

#graph-mobile .lcv{
  padding: 4px 21px;
  background: #9e95e2
}

#graph-mobile .scv{
  padding: 4px 21px;
  background: #fac2c0
}


#graph-mobile .active{
  border-bottom: 1px solid #fccb56;
}

#graph-mobile .image {
  width: 35px;
  height: 28px;
  object-fit: contain;
}

#graph-mobile .total-offtake {

  font-family: Helvetica;
  font-size: 11.2px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#graph-mobile .counts{
    font-family: Helvetica;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #495057;
  
}