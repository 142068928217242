#marketing-coupon .disabled-text-box:focus {
  background-color: #ffffff;
  display: block;
  width: 100%;
  height: 35px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  /* border: 1px solid #ced4da; */
  border-radius: 4px;
  /* border-color: #fdbb1f; */
  box-shadow: 0 1px 3px 0px grey;
  outline: 0 none;
}

#marketing-coupon .disable-text-box-margin {
  margin-top: 15px;
}

#marketing-coupon .enable-text-box-margin {
  margin-top: -15px;
}

#marketing-coupon .input-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#marketing-coupon .disabled-input-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#marketing-coupon .disabled-input-value {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#marketing-coupon .required-star {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fdbb1f;
  margin-bottom: 0 !important;
}

#marketing-coupon .next-btn {
  width: 176px;
  border-radius: 17.5px;
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
}

#marketing-coupon .custom-textarea:focus {
  border-color: #fdbb1f !important;
  box-shadow: 0 1px 1px white inset, 0 0 8px white !important;
  outline: 0 none !important;
  background-color: rgba(253, 187, 31, 0.1) !important;
}

#marketing-coupon .textarea:focus,
textarea:focus,
input[type="text"]:focus,
input[type="textarea"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: #fdbb1f;
  box-shadow: 0 1px 1px white inset, 0 0 8px white;
  outline: 0 none;
  background-color: rgba(253, 187, 31, 0.1);
}

#marketing-coupon .custom-checkboax--container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  height: 33px;
  width: max-content;
  border: solid 2px #fdbb1f;
}

/* Hide the browser's default checkbox */

#marketing-coupon .custom-checkboax--container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

#marketing-coupon .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 29px;
  width: 30px;
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */

#marketing-coupon .custom-checkboax--container input:checked ~ .checkmark {
  background-color: #fdbb1f;
}

/* Create the checkmark/indicator (hidden when not checked) */

#marketing-coupon .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

#marketing-coupon .custom-checkboax--container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

#marketing-coupon .custom-checkboax--container .checkmark:after {
  left: 12px;
  top: 3px;
  width: 8px;
  height: 19px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#marketing-coupon .custom-checkboax1--container {
  /* display: block; */
  position: relative;
  padding-left: 15px;
  margin-bottom: -4px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  height: 17px;
  width: max-content;
  border: solid 2px #fdbb1f;
}

/* Hide the browser's default checkbox */

#marketing-coupon .custom-checkboax1--container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

#marketing-coupon .checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  width: 15px;
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */

#marketing-coupon .custom-checkboax1--container input:checked ~ .checkmark1 {
  background-color: #fdbb1f;
}

/* Create the checkmark/indicator (hidden when not checked) */

#marketing-coupon .checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

#marketing-coupon .custom-checkboax1--container input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the checkmark/indicator */

#marketing-coupon .custom-checkboax1--container .checkmark1:after {
  left: 5px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

#marketing-coupon .custom-checkboax-radio--container {
  /* display: block; */
  position: relative;
  padding-left: 15px;
  margin-bottom: -4px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  height: 19px;
  width: max-content;
  border: solid 2px #fdbb1f;
  border-radius: 25px;
}

/* Hide the browser's default checkbox */

#marketing-coupon .custom-checkboax-radio--container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

#marketing-coupon .checkmark-radio {
  position: absolute;
  top: 0px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 25px;
}

/* When the checkbox is checked, add a blue background */

#marketing-coupon .custom-checkboax-radio--container input:checked ~ .checkmark-radio {
  background-color: #fdbb1f;
}

/* Create the checkmark/indicator (hidden when not checked) */

#marketing-coupon .checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

#marketing-coupon .custom-checkboax-radio--container input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the checkmark/indicator */

#marketing-coupon .custom-checkboax-radio--container .checkmark-radio:after {
  left: 0px;
  top: 0.4px;
  width: 15px;
  height: 14.5px;
  border: 2px solid white;
  /* border-width: 0 3px 3px 0; */
  /* transform: rotate(45deg); */
  border-radius: 25px;
}

#marketing-coupon .left-tyre-car-checkbox {
  position: absolute;
  margin-top: 20%;
  margin-left: -4%;
}

#marketing-coupon .front-left-right-tyre-car {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 23%;
  width: 370px;
  margin-top: 0%;
  margin-left: -74px;
  top: 23%;
}

#marketing-coupon .back-left-right-tyre-car {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 360px;
  margin-top: 0%;
  margin-left: -70px;
  top: 58%;
}

#marketing-coupon .square-tyre-car {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 230px;
  margin-top: 0%;
  top: 82%;
}

#marketing-coupon .square-tyre-activa {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 82%;
  margin-top: 0%;
  top: 38%;
}

#marketing-coupon .front-left-right-tyre-activa {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 55%;
  margin-top: 0%;
  margin-left: 6%;
  top: 102%;
}

#marketing-coupon .custom-checkbox-lbl {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#marketing-coupon .horizontal-OR-line {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: auto;
  margin-left: 5px;
}

#marketing-coupon .serach-icon {
  font-size: 15px;
  position: relative;
  margin-left: -23px;
  margin-top: 7px;
}

#marketing-coupon .vehical-sku-id-search .rbt {
  width: 100%;
}

#marketing-coupon .vehical-make-wrapp {
  border-radius: 10.5px;
  border: solid 0.7px #979797;
  height: 123.2px;
  margin-bottom: 15px;
  margin-right: 10px;
}

#marketing-coupon .vehical-make-img {
  width: 84.2px;
  height: 62px;
  object-fit: contain;
}

#marketing-coupon .vehical-make-label {
  margin-top: 10px;
  font-family: Helvetica;
  font-size: 11.2px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #6e777f;
  text-align: center;
}

#marketing-coupon .custom-secondary:hover {
  color: #fff !important;
  background-color: #fdbb1f !important;
  border-color: #fdbb1f !important;
}

#marketing-coupon .gradient-sub-title {
  font-family: Helvetica;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

#marketing-coupon .custom-secondary-selected {
  color: #fff !important;
  background-color: #fdbb1f !important;
  border-color: #fdbb1f !important;
}

#marketing-coupon .Note---If-you-can-no {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #6e777f;
}

#marketing-coupon .year-date-wrapp,
#marketing-coupon .year-date-wrapp .react-datepicker,
#marketing-coupon .year-date-wrapp input[type="text"] {
  width: 100%;
}

#marketing-coupon .vehical-make-wrapp:hover {
  cursor: pointer;
  border-radius: 10.5px;
  border: solid 1.4px #fdeba0;
  background-color: #fdeba0;
}

#marketing-coupon .active-vehical-make-wrapp {
  border-radius: 10.5px;
  border: solid 1.4px #fdeba0;
  background-color: #fdeba0;
}

#marketing-coupon .km {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-top: 7px;
  margin-left: 5px;
}

#marketing-coupon .text-overflow-dots {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#marketing-coupon .filled-data-field {
  border-color: #fdbb1f;
  box-shadow: 0 1px 1px white inset, 0 0 8px white;
  outline: 0 none;
}

#marketing-coupon .box-shadow-apply {
  box-shadow: 0 0.46875rem 2.1875rem rgba(29, 20, 0, 0.03),
    0 0.9375rem 1.40625rem rgba(29, 20, 0, 0.03),
    0 0.25rem 0.53125rem rgba(29, 20, 0, 0.05),
    0 0.125rem 0.1875rem rgba(29, 20, 0, 0.03);
}

#marketing-coupon .box-shadow-remove {
  box-shadow: unset !important;
}

#marketing-coupon .input-focus-white:focus {
  background-color: white !important;
}

#marketing-coupon .registration-number-help-label {
  opacity: 0.4;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  text-align: center;
}

#marketing-coupon .vehicle-type-image-bike {
  width: 90%;
  object-fit: contain;
  margin-left: 10px;
}

#marketing-coupon .vehicle-type-image-car {
  width: 100%;
  object-fit: contain;
}

#marketing-coupon .skuid-example-text {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: #6e777f;
}

#marketing-coupon .stencil-number-textbox {
  text-transform: capitalize;
  /* width: 86px; */
}

#marketing-coupon .stencil-number-textbox-rear-right {
  margin-left: 80%;
  display: block;
  width: 66% !important;
}

#marketing-coupon .err-msg {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: red;
}

#marketing-coupon .skuIdBack {
  height: calc(1.5em + 0.5rem + 2px);
}

/*Custom Check Box*/

#marketing-coupon .checkbox-container {
  display: flex;
  align-items: center;
}

#marketing-coupon .checkbox-container label {
  cursor: pointer;
  display: flex;
}

#marketing-coupon .checkbox-container input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

#marketing-coupon .checkbox-container label::before {
  content: "";
  width: 1em;
  height: 1em;
  /* margin-right: .5em; */
  border: solid 2px #fdbb1f;
  margin-left: auto;
}

#marketing-coupon .checkbox-container label::after {
  content: "";
  position: absolute;
  display: none;
}

#marketing-coupon .checkbox-container label:hover::before,
#marketing-coupon .checkbox-container input[type="checkbox"]:hover + label::before {
  background-color: #fff;
}

#marketing-coupon .checkbox-container input[type="checkbox"]:disabled + label,
#marketing-coupon .checkbox-container input[type="checkbox"]:disabled {
  color: #aaa;
  cursor: default;
}

#marketing-coupon .checkbox-container input[type="checkbox"]:checked + label::before {
  content: "\002713";
  background-color: #fdbb1f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 0px;
  margin-top: 2px;
}

#marketing-coupon .checkbox-container input[type="checkbox"]:disabled + label::before {
  background-color: #ccc;
  border-color: #999;
}

#marketing-coupon .checkbox-container {
  font-size: 2.3em;
}

#marketing-coupon .checkbox-name-wrapp {
  display: flex;
  flex-direction: column;
}

#marketing-coupon .checkbox-name {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(73, 80, 87);
}

#marketing-coupon .product-detail-edit-car-img {
  margin-top: -19px;
}

#marketing-coupon .checkbox-container-wrapp {
  display: flex;
  flex-direction: column;
}

#marketing-coupon .checkbox-container-right {
  display: flex;
  justify-content: flex-end;
}

#marketing-coupon .stencil-number-textbox-car-right-wrapp {
  margin-left: 0%;
  width: 46%;
  margin-top: 12%;
  position: absolute;
}

#marketing-coupon .stencil-number-textbox-bike-right-wrapp {
  margin-left: 84%;
  width: 30%;
  margin-top: 9%;
  position: absolute;
}

#marketing-coupon .right-tyre-bike-checkbox {
  width: 470px;
  position: absolute;
  margin-top: 106px;
}

#marketing-coupon .product-detail-edit-bike-img {
  margin-top: 52px;
  width: 78%;
}

#marketing-coupon .front-left-right-tyre-bike {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 325px;
  margin-top: 0%;
  margin-left: 45px;
  top: 101%;
}

#marketing-coupon .bottom-stencil-number-textbox-bike-right-wrapp {
  margin-left: -15%;
  width: 46%;
  margin-top: 13%;
  position: absolute;
}

#marketing-coupon .stencil-number-textbox-bike-left-wrapp {
  margin-left: -53px;
  width: 140px;
}

#marketing-coupon .front-left-car-sten-num {
  margin-left: -100px;
}

/* @media screen and (min-width: 220px) and (max-width: 991px) {
  .front-left-car-sten-num {
    margin-left: 0px;
  }
  .front-left-car-sten-num {
    width: 50%;
  }
  .front-left-right-tyre-car {
    margin-top: 10%;
  }
  .checkbox-container {
    font-size: 1.3em;
  }
  .product-detail-edit-car-img {
    margin-top: 0px;
    max-width: 120px;
  }
  .stencil-number-textbox-car-left {
    font-size: 12px !important;
  }
  .err-msg {
    font-family: Helvetica;
    font-size: 12px;
  }
  .checkbox-container-right input[type="checkbox"]:checked + label::before {
    margin-top: 0px;
  }
  .front-left-right-tyre-car {
    width: 271px;
  }
  .stencil-number-textbox-car-right {
    font-size: 12px !important;
  }
  .stencil-number-textbox-car-right-wrapp {
    margin-left: 1%;
    width: 35%;
    margin-top: 10%;
  }
  .back-left-right-tyre-car {
    width: 271px;
    margin-left: -74px;
    top: 62%;
  }
  .square-tyre-car {
    width: 115px;
    margin-left: unset;
  }
  .product-detail-edit-bike-img {
    margin-top: 52px;
    width: 68%;
  }
  .stencil-number-textbox-bike-right-wrapp {
    margin-left: 77%;
    max-width: 100%;
    margin-top: 9%;
    position: absolute;
    width: 37%;
  }
  .front-left-right-tyre-bike {
    width: 180px;
    margin-left: 5%;
  }
  .stencil-number-textbox-bike-left-wrapp {
    margin-left: -15px;
    width: 80% !important;
  }
  .stencil-number-textbox-bike-left {
    font-size: 12px !important;
  }
  .bottom-stencil-number-textbox-bike-right-wrapp {
    margin-left: 1%;
    width: 70%;
    margin-top: 13%;
  }
  .right-tyre-bike-checkbox {
    width: 79%;
    position: absolute;
    margin-top: 85px;
  }
} */

#marketing-coupon .number-of-tyres-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#marketing-coupon .disable-vehicle {
  background-color: lightgray;
}

#marketing-coupon .gradient-title {
  background-image: linear-gradient(to right, rgba(30, 87, 248, 0.55), #491b9f);
  color: white;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* background-color: #5583b3; */
}

#marketing-coupon .filter-type-lbl-fr {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #475466;
  text-transform: capitalize;
}

#marketing-coupon .coupon-filter {
  position: Fixed !important;
  width: 450px;
  max-width: 90%;
  overflow-y: unset !important;
}

#marketing-coupon .coupon-slider {
  position: Fixed !important;
  width: 622px;
  max-width: 90%;
  overflow-y: unset !important;
}

#marketing-coupon .coupon-list-table-header {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

#marketing-coupon .coupon-mg-card .entries-selected {
  margin-top: 10px;
  margin-left: 15px;
}

#marketing-coupon .coupon-list-row {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#marketing-coupon .active-row {
  background-color: rgba(255, 230, 171, 0.66);
}

#marketing-coupon .label-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
}

#marketing-coupon .marg-pad { 
  padding-left: 5px !important;
  margin-top: 2px !important;
}

#marketing-coupon .marg-pad-generate { 
  margin-right: 30px !important;
  padding-left: 5px !important;
  margin-top: 2px !important;
}

#marketing-coupon .filter-select {
  height: 40px;
  border-radius: 5px;
  border: solid 1px #ced4da;
  background-color: var(--white);
}

#marketing-coupon .filter-bolde-label {
    height: 17px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--gunmetal);
}

#marketing-coupon .react-datepicker__month-select, .react-datepicker__year-select {
  color: #141313;
  background-color: white;
  /* border: 1px solid yellow; */
}

#marketing-coupon .react-datepicker__day--selected {
  background-color: #fdbb1f !important;
  color: #fff !important;
}

#marketing-coupon .react-datepicker__day--keyboard-selected {
  background-color: #fdbb1f !important;
  color: #fff;
}

#marketing-coupon .form-group-select {
  font-size: 14px!important;
}

#marketing-coupon .search .rbt {
  width: 100%;
}

#marketing-coupon .serach-icon {
  font-size: 15px;
  position: relative;
  margin-left: -23px;
  margin-top: 7px;
}

#marketing-coupon .input-group-text {
  position: absolute !important;
  top: 4px !important;
  right: 1px !important;
  z-index: 101 !important;
  background-color: white !important;
  border: 0px !important;
}

#marketing-coupon .input-group-text-serch {
  color: #fdbb1f !important;
  right: -10px !important;
  padding-right: 5px;
  margin-top: 6px;
}

#marketing-coupon .mail-label {
  height: 19px;
  font-family: Helvetica;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

#marketing-coupon .input-group-text { 
  color: #fdbb1f !important;
}

#marketing-coupon .css-tlfecz-indicatorContainer {
  color: #fdbb1f !important;
}

#marketing-coupon [data-tooltip] {
  cursor: pointer !important;
  display: inline-block;
  position: relative;
  cursor: help;
  padding: 4px;
}
/* Tooltip styling */
#marketing-coupon [data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.4;
  width: max-content;
  /* max-width: 100px; */
  text-align: center;
  border-radius: 4px;
}
/* Dynamic horizontal centering */
#marketing-coupon [data-tooltip-position="top"]:before,
#marketing-coupon [data-tooltip-position="bottom"]:before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
/* Dynamic vertical centering */
/* #marketing-coupon [data-tooltip-position="right"]:before,
#marketing-coupon [data-tooltip-position="left"]:before {
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
} */
#marketing-coupon [data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 6px;
}

/* Tooltip arrow styling/placement */
#marketing-coupon [data-tooltip]:after {
  content: '';
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
/* Dynamic horizontal centering for the tooltip */
#marketing-coupon [data-tooltip-position="top"]:after,
#marketing-coupon [data-tooltip-position="bottom"]:after {
  left: 50%;
  margin-left: -6px;
}
/* Dynamic vertical centering for the tooltip */
/* #marketing-coupon [data-tooltip-position="right"]:after,
#marketing-coupon [data-tooltip-position="left"]:after {
  top: 50%;
  margin-top: -6px;
} */
#marketing-coupon [data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}
/* Show the tooltip when hovering */
#marketing-coupon [data-tooltip]:hover:before,
#marketing-coupon [data-tooltip]:hover:after {
  font-size: 11px;
  display: block;
  z-index: 50;
}

#marketing-coupon .hover-download {
  cursor:pointer;
}

#marketing-coupon .react-datepicker__input-container ::placeholder {
  text-transform: capitalize !important;
}

#marketing-coupon .select {
  position: relative;
  -webkit-appearance: none;
  background: url("/images/down-arrow.svg") no-repeat right #fff;
  background-position-x: 96%;
  font-family: Helvetica;
}

#marketing-coupon .css-1okebmr-indicatorSeparator {
  background-color: hsl(0, 0%, 100%);
}

#marketing-coupon .cn-title {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 14px;
  color: #495057;
}

#marketing-coupon .textwrap {
    line-height: 1.5;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }