.existing-customer-eligibility {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

.select-vehicle-label {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.select-vehicle-title {
  font-size: 22px;
  /* margin-left: 6px; */
}

.no-details {
  font-family: Helvetica;
  font-size: 21px;
  font-weight: 300;
  font-style: oblique;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #6e777f;
}

.warranty-check-label {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-top: -2px;
}

.warranty-check-table-head {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

#wc-table-head th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color: #f6f6f6;
}

#wc-table-body td {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
  background-color: white;
}

.product-detail-label {
  font-family: Helvetica;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.product-detail-desc {
  font-family: Helvetica;
  font-size: 17px;
  font-weight: 300;
  font-style: oblique;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #6e777f;
}

.enter-stencil-number {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.no-match-found {
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-top: 12px;
}
