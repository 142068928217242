
#trade-scheme-report th{
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color:#f6f6f6;
}

#trade-scheme-report th{
  color: #545a60;
  border: solid 0.9px #e9ecef;
}


#trade-scheme-report td{
  width: 25px;
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
  border: solid 0.9px #e9ecef;
}

#trade-scheme-report .link-blue {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8 !important;
  cursor: pointer;
  text-decoration: underline;
}

#trade-scheme-report .overall-card{
  background-image: linear-gradient(101deg, #f8e184, #f7d174) !important;
}

#trade-scheme-report .overall-table-bg{
  background-color: white;
}

#trade-scheme-report .overall-card th{
  background-image: linear-gradient(101deg, #f8e184, #f7d174) !important;
}
