.scorcard {
  position: absolute !important;
  left: 40%;
  top: 25%;
  z-index: 9999;
  /* right: 29%; */
  width: 30% !important;
}

.label {
  font-size: 15px;
  padding-top: 10px;
  margin-bottom: -6px;
  padding-left: -15px;
}

.border {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: -15px;
  margin-right: -15px;
}

.label-select {
  font-family: Helvetica;
  color: #6e777f;
}

.wrapper-scorcard {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.modelCard {
  width: "80%" !important;
}

#ViewRemark .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 380px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: 0;
}

#ViewRemark .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0px;
}

.CD-note-reminder {
  height: 35px;
  box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff2d3;
  margin-top: 10px;
  border-radius: 5px;
  background-image: url(/images/note_bg_icon.png);
  background-position-x: right;
  background-size: 100% 70px;
}