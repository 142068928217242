.tbl-f6f6f6 th{
  border: 1px solid #e9ecef;
    padding: 7px;
}
.tbl-f6f6f6 td{
  font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 14px;
border: 1px solid #e9ecef;
line-height: 16px;
/* identical to box height */
color: #495057;
padding: 7px;
}
.cip-summary-collpase-lbl{
  color: #495057;
  font-weight: bold;
}
.cip-collapse-row{
  margin-left: 15px !important;
  padding-bottom: 10px;
  padding-top: 5px;
}

.cip-end-icon{
  color: #FDBB1F;
  font-size: 14px;
  font-weight: bold;
}



#CustomToolTip [data-tooltip] {
  display: inline-block;
  position: relative;
  cursor: default;
  padding: 4px;
}
/* Tooltip styling */
#CustomToolTip [data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  font-size: 13px;
  line-height: 1.4;
  /* min-width: 130px; */
  text-align: center;
  border-radius: 4px;
  /* max-width: 250px; */
  width: 135px;
}
/* Dynamic horizontal centering */
#CustomToolTip [data-tooltip-position="top"]:before,
[data-tooltip-position="bottom"]:before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
/* Dynamic vertical centering */
#CustomToolTip [data-tooltip-position="right"]:before,
#CustomToolTip [data-tooltip-position="left"]:before {
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
#CustomToolTip [data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 6px;
}
#CustomToolTip [data-tooltip-position="right"]:before {
  left: 100%;
  margin-left: 6px;
}
#CustomToolTip [data-tooltip-position="bottom"]:before {
  top: 100%;
  margin-top: 6px;
}
#CustomToolTip [data-tooltip-position="left"]:before {
  right: 100%;
  margin-right: 6px;
}

/* Tooltip arrow styling/placement */
#CustomToolTip [data-tooltip]:after {
  content: '';
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
/* Dynamic horizontal centering for the tooltip */
#CustomToolTip [data-tooltip-position="top"]:after,
[data-tooltip-position="bottom"]:after {
  left: 50%;
  margin-left: -6px;
}
/* Dynamic vertical centering for the tooltip */
#CustomToolTip [data-tooltip-position="right"]:after,
#CustomToolTip [data-tooltip-position="left"]:after {
  top: 50%;
  margin-top: -6px;
}
#CustomToolTip [data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}
#CustomToolTip [data-tooltip-position="right"]:after {
  left: 100%;
  border-width: 6px 6px 6px 0;
  border-right-color: #000;
}
#CustomToolTip [data-tooltip-position="bottom"]:after {
  top: 100%;
  border-width: 0 6px 6px;
  border-bottom-color: #000;
}
#CustomToolTip [data-tooltip-position="left"]:after {
  right: 100%;
  border-width: 6px 0 6px 6px;
  border-left-color: #000;
}
/* Show the tooltip when hovering */
#CustomToolTip [data-tooltip]:hover:before,
#CustomToolTip [data-tooltip]:hover:after {
  display: block;
  z-index: 101;
}


.CustomToolTip-text-align-left[data-tooltip]:before {
  text-align: left !important;
}
.end-cip-lbl::before {
  width: 85px !important;
}
#cip-add-participant::before {
  width: 110px !important;
}


.common-popover .popover.show {
  width: 252px;
  /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  border-radius: 6px; */
  background: #FFF7DE;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.common-popover .bs-popover-auto[x-placement^="right"] > .arrow::after{
  border-right-color: #FFF7DE !important;
}
.common-popover .bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #FFF7DE !important;
}

.common-popover .popover-title {
  font-family:Helvetica;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
  padding-top: 15px;
  border-bottom: 1px solid #CED4DA;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #FFF7DE;
}
.common-popover .arrow{
  top:5px !important;
  /* background: #FFF7DE; */
}
.common-popover .bs-popover-right > .arrow::after{
  border-right-color: #FFF7DE;
}
.common-popover .bs-popover-left > .arrow::after {
  border-left-color: #FFF7DE;
}
.common-popover .bs-popover-left > .arrow::before {
  border-left-color: #FFF7DE;
  border-right-color: #FFF7DE;
}
.common-popover .bs-popover-right > .arrow::before{
  border-left-color: #FFF7DE;
  border-right-color: #FFF7DE;
}

.common-popover .popover-body {
  padding-left: 20px;
}

.common-popover .popover-lbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;  
  color: #495057;
}

.common-popover .popover-lbl-value {
  font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
color: #495057;
}

.box-shadow-40px-0-4 {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4) !important
}

.box-shadow-40px-0-5 {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5) !important
}

.common-popover .next, .common-popover .prev {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #2F80ED;
}

.font-size-13px {
  font-size: 13px;
}
.common-popover .next-btn{
  width: 70px;
  font-size: 12px !important;
  padding: 0 !important;
  height: 28px !important;
}
.common-popover .prev-btn{
  width: 76px;
  font-size: 12px !important;
  padding: 0 !important;
  height: 28px !important;
}