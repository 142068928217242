#generativeAIPage .page-title-wrapper {
  box-shadow: 0 0.46875rem 2.1875rem rgba(29, 20, 0, 0.03),
    0 0.9375rem 1.40625rem rgba(29, 20, 0, 0.03),
    0 0.25rem 0.53125rem rgba(29, 20, 0, 0.05),
    0 0.125rem 0.1875rem rgba(29, 20, 0, 0.03);
  border-radius: 7px;
  background-color: #fff;
  padding: 0 15px;
}

#generativeAIPage .page-title {
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
}

#generativeAIPage .page-title-wrapper .page-title-icon .titleImg {
  width: 56px;
  height: 56px;
  object-fit: cover;
}

#generativeAIPage .chatWrap {
  margin-top: 20px;
  padding: 15px;
}

#generativeAIPage .searchInputWrap {
  display: flex;
  margin: 5px;
}

#generativeAIPage .searchInputWrap2 input {
  color: #495057;
  padding-right: 70px;
}

#generativeAIPage .searchInputWrap2 {
  width: 80%;
  margin-right: 50px;
  position: relative;
}

#generativeAIPage .searchInput,
.searchInputWrap select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 20px !important;
}

#generativeAIPage .searchInputWrap select {
  height: 50px;
  position: relative;
  -webkit-appearance: none;
  background: url("/images/down-arrow.svg") no-repeat right #fff;
  background-position-x: 92%;
  font-family: Helvetica;
  cursor: pointer;
  margin-bottom: 10px;
}

#generativeAIPage .searchInputWrap select:focus,
.searchInput:focus {
  color: #212529 !important;
  /* background-color: rgba(253, 187, 31, 0.1) !important; */
  background-color: #fff !important;
  border-color: orange !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(253, 197, 13, 0.25) !important;
}
#generativeAIPage .searchIcon {
  position: absolute;
  top: -2px;
  right: 20px;
  font-size: 35px;
  color: orange;
  cursor: pointer;
}

#generativeAIPage .resetIcon {
  position: absolute;
  top: -2px;
  right: 50px;
  font-size: 35px;
  color: orange;
  cursor: pointer;
}

#generativeAIPage .saveIcon {
  position: absolute;
  top: 11px;
  right: 10px;
  font-size: 30px;
  color: orange;
  cursor: pointer;
}

#generativeAIPage .searchIcon2 {
  position: absolute;
  top: 7px;
  right: 20px;
  font-size: 20px;
  padding: 3px 10px;
  color: orange;
  border: 1px solid orange;
  border-radius: 10px;
  cursor: pointer;
}

#generativeAIPage .aiCard {
  display: flex;
  height: 120px;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  overflow: hidden;
  /* width: 444px; */
  cursor: pointer;
}

#generativeAIPage .aiCard p {
  margin: 0;
}

#generativeAIPage .AI-icon {
  width: 79px;
  padding: 15px;
  font-size: 30px;
  /* color: orange; */
  background-color: rgba(153, 253, 31, 0.3);
  border-radius: 10px;
  margin-right: 20px;
  text-align: center;
}

#generativeAIPage .AI-text {
  max-width: 420px;
}

#generativeAIPage .ans-card {
  height: 400px;
  width: 93%;
  margin: 20px 5px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid black;
  font-size: 18px;
}
