/* #pagination-container { */

    #pagination-container {
    display: flex;
    justify-content: flex-end;
    }
    
    #pagination {
    display: flex;
    color: #505050;
    }
    
    #pagination-list {
    display: flex;
    list-style: none;
    margin: 0 0 0 0px;
    padding: 0;
    }
    
    .pagination-button {
    display: flex;
    height: 30px;
     width:auto;
    padding: 8px;
    /* height: 23px; */
    /* width: 21px; */
    /* border: 1px solid #dee2e6; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color:#757575;
    border-radius: 3%;
    font-size: 16px;
    }
    
    .pagination-list-item {
    margin-right: 0px;
    }
    
    .pagination-list-item.active {
    /* background: #45a4aa; */
    color:#545cd8;
    /* color: white; */
    }
    
    .pagination-go-first {
    margin-right: 40px;
    position: relative;
    /* margin-left: 10px; */
    }
    
    .pagination-go-first:before {
    position: absolute;
    content: '...';
    right: -33px;
    }
    
    .pagination-go-last {
    margin-left: 40px;
    position: relative;
    /* margin-right: 10px; */
    }
    
    .pagination-go-last:before {
    position: absolute;
    content: '...';
    left: -33px;
    }

    .showing-text {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #9e9e9e;
    }

    .range{
      font-family: Helvetica;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #757575;
    }
/* }     */