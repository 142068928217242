#ob1 .on-board-image{
  position: absolute;
  top: 0;
  bottom:0;
  right: 0;
  left: 0;
  margin: auto;
}


#ob1 .parent-div{
  /* display: table; */
  line-height: 100%;
}

#ob1 .vert-align{
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  height:  -moz-available;
  height: 80vh;
}

#ob1 .page-title {
  font-family: Helvetica;
  font-size: 26px;
  font-weight: 300;
  font-style: oblique;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

@media (max-width: 1200px){
  #ob1 .vert-align{
    height: auto;
  }
}