
#profile .margin-left-4{
  margin-left: 4px !important;
}

#profile .profile-page-top{
  top:9%;
}

#profile .display-none{
  display: none !important;
}


#profile .form-check{
  display: inline;
}
.action-screen-sidebar{
  width: 300px
}

#profile .action-button{
  font-weight: bold;
  font-size: 16px;
}