.yearWrap{
  /* width: 200px; */
  /* display: flex;
  gap: 30px; */
}
.yearWrap select{
  width: 175px;
}
.yearWrap label{
  margin-left: 10px;
  font-weight: 600;
}