#ob .page-title {
  font-family: Helvetica;
  font-size: 26px;
  font-weight: 300;
  font-style: oblique;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#ob .logout{
  position:fixed;
  bottom: 10px;
  left: 5px;
  padding: 0px 10px;
  z-index: 100;
}