#downloadModal .downloadArea .errorIcon {
  font-size: 42px;
  margin-bottom: 20px;
  margin-top: 20px;
}

#downloadModal .downloadArea .errorText {
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-bottom: 25px;
}

#downloadModal .btnGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#downloadModal .btnGroup .downloadModalBtn {
  color: #495057;
  background: #FDBB1F;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px;
  margin: 10px 4px;
  min-width: 68px;
}

#downloadModal .btnGroup .downloadModalBtn.noBtn {
  background-color: #e0e2e4;
}