.send-verify-otp {
  background-color: lightgray !important;
  border-color: lightgray !important;
  opacity: 1;
}

.send-verify-otp.disabled {
  background-color: lightgray !important;
  border-color: lightgray !important;
  opacity: 0.6;
}

/* #dashboard .page-title {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard .gradient-title{
  background-image: linear-gradient(to right, rgba(30, 87, 248, 0.55), #491b9f);
  color: white;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

#dashboard .gradient-sub-title{
  font-family: Helvetica;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

#dashboard .warranty-block {
  border-radius: 8px;
  background-color: #fdbb1f80;
}

#dashboard .warranty-text-bold {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard .warranty-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#dashboard .text-input {

  border-radius: 24px !important;
  background-color: #ffffff !important;
}

#dashboard .dashboard-page-card-title-big {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard .count-big{
  width: 109px;
  height: 34px;
  font-family: Helvetica;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #545cd8;
}

#dashboard .dashboard-page-card-title-small-gray {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #92969a;
}

#dashboard .dashboard-page-title {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}


#dashboard .image {
  width: 54px;
  height: 28px;
  object-fit: contain;
}

#dashboard .count-small {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #545cd8;
}

#dashboard .card-count{  
    font-family: Helvetica;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 28px;
    letter-spacing: normal;
    color: #495057;
} */