#secondary-point-all-delear-report .card-margin {
  margin: 18px 0px;
}

#secondary-point-all-delear-report .card-bg-image {
  background-image: url("/images/card-bg.svg");
  background-size: 192%;
  background-position: center;
}

#secondary-point-all-delear-report th,
#secondary-point-all-delear-report tr {
  border: 1px solid rgb(0, 0, 0, 0.1);
}

#secondary-point-all-delear-report td {
  border: 1px solid rgb(0, 0, 0, 0.1);
}

#secondary-point-all-delear-report .link {
  color: #545cd8;
  font-family: Helvetica;
  cursor: pointer;
}

#secondary-point-all-delear-report th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color: #f6f6f6;
}

#secondary-point-all-delear-report td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#secondary-point-all-delear-report .padding-2 {
  padding: 2px;
}

#secondary-point-all-delear-report .Apr-Jun’20 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #495057;
  mix-blend-mode: normal;
  opacity: 0.75;
}
#secondary-point-all-delear-report .card-point-lbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #495057;
}
#secondary-point-all-dealer-report .card-point-label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Text-Color */

  color: #495057;
}
#total_card .card-point-label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Text-Color */

  color: #495057;
}
.card_background {
  background: linear-gradient(134.26deg, #ffdf42 100%, #fdbb1f 100%);
  mix-blend-mode: normal;
  /* opacity: 0.6; */
}
.row_container {
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  width: 100%;
  margin: 12px 0 9px 24px;
}
.card_main_heading {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 14px 0 0 24px;
  color: #495057;
}
.card_main_value {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #495057;
  margin: 12px 29px 0 29px;
}

.card_main_subheading {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin: 19px 0 0 24px;
  color: #495057;
}
.vehicle_type_heading {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: justify;
  color: #495057;
}
.vehicle_type_value {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #495057;
}

.download-monthly-report {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: center;
  color: #545cd8;
}
#total_card .card-point-value {
  font-family: Helvetica;
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  color: #495057;
}
.point-card-point-value {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: #495057;
}

.points_card_value {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #5d6166;
}

.points_card_label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #495057;
}

.padding-bottom-10px {
  padding-bottom: 10px !important;
}
.content {
  position: absolute;
  left: 53%;
  bottom: 0;
}
.custom-datepicker {
  width: auto !important;
  right: 0 !important;
  position: absolute !important;
  z-index: 2;
  padding: 10px;
}

.dropdown-toggle-ho-all-india-filter {
  background-color: #ffffff !important;
  border-radius: 4px !important;
  border: solid 1px #ced4da !important;
  display: flex !important;
  min-width: 160px;
}

.dropdown-toggle-ho-all-india-filter-wrapper .dropdown-toggle::after {
  margin-left: auto !important;
  margin-top: auto;
  margin-bottom: auto;
  height: 10px;
}

.dropdown-toggle-ho-all-india-filter-wrapper .dropdown-menu::before {
  display: none;
}

.dropdown-toggle-ho-all-india-filter-wrapper .dropdown-menu::after {
  display: none;
}

.dropdown-menu-ho-all-india-filter {
  width: 330px;
}

.custom-dropdown-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.custom-dropdown-header-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}
.download-monthly-report {
  width: 50% !important;
}
.heading {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 18px;
  color: #495057;
  margin: 25px 5px 8px 5px;
}

.warranty_registered_card {
  height: 165px !important;
}

.card_container {
  display: flex;
}

.suggestion-container {
  width: 342px;
  height: 35px;
}
/* #total_card .card-body{
  padding:10px !important
} */
.hide_chart {
  display: none;
}
.show_chart {
  display: block;
}

.summary_card_main_heading {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #495057;
}

#warranty_total_card .card-body {
  padding: 10px !important;
}
#sensor_total_card .card-body {
  padding: 10px !important;
}
.zonal-report {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #495057;
}

.download_report span {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin-left: 10px;
  /* identical to box height */
  text-align: center;
  color: #545cd8;
}
@media screen and (max-width: 1350px) {
  .warranty_registered_card {
    max-height: 165px !important;
    /* padding: 5px; */
  }
}

@media screen and (min-width: 1200px) and (max-width: 1350) {
  input_searchBar_container {
    width: max-content;
  }
}

@media screen and (max-width: 600px) {
  #total_card .card-point-value {
    font-size: 12px;
  }
  .points_card_value {
    font-size: 16px;
  }
}
@media screen and (max-width: 300px) {
  #total_card .card-point-value {
    font-size: 12px;
  }
  .points_card_value {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px) {
  .download_report p {
    margin-left: 4px;
  }
}
@media screen and (max-width: 1199px) {
  /* .warranty_registered_card {
    margin-bottom: 10px;
  } */
  .warranty_card_heading {
    height: 40px;
  }
  .download-report-icon {
    margin-bottom: 0px;
  }
  .heading {
    width: -webkit-fill-available;
  }
  .download_report p {
    margin-top: 0px;
  }
}

/* for Card */
#warranty_total_card {
  height: 155px;
}
#sensor_total_card {
  height: 155px;
}
.points_card .card-body {
  height: 155px !important;
}

@media screen and (max-width: 1063px) and (min-width: 992px) {
  #warranty_total_card {
    height: 175px;
  }
  #sensor_total_card {
    height: 175px;
  }
  .points_card .card-body {
    height: 175px !important;
  }
}
