#WarrantyFilter {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
}
.select-input {
  width: 75% !important;
}
.line_break {
  border-top: 1px solid #c0c0c0;
  width: 100%;
  margin: 5px 0;
}
#WarrantyFilter.hide {
  transform: translate3d(-100vw, 0, 0);
}

#WarrantyFilter.show {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  #WarrantyFilter {
    justify-content: space-evenly;
  }
  .radion-container {
    font-size: 10px;
  }
  .dropdown-toggle-ho-all-india-filter-wrapper .form-group {
    margin-bottom: 0.5rem;
  }
  /* .filter_button_container{
    border-top: 0;
    margin-top:30px ; 
    justify-content: flex-end;
    position: absolute; 
    padding: 11px; 
    right: 15px;
    border: 1px solid #c0c0c0;
  } */
}

.filter_button_container {
  margin: 0px;
  position: absolute;
  bottom: 80px;
  border-top: 1px solid #ced4da;
  width: 100%;
  padding: 11px;
  justify-content: flex-end;
  display: flex;
}
.filterheading {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;

  color: #495057;
}

.cursor-pointer-image {
  width: 18px;
  height: 18px;
  left: 1402px;
  top: 86px;
  color: #fdbb1f;
}

#WarrantyFilter .radion-container {
  display: inline;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

#WarrantyFilter .radion-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

#WarrantyFilter .radio-checkmark {
  position: absolute;
  top: 0px;
  left: 3px;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #fdbb1f;
  border-radius: 50%;
}
.filter_minmax_input_container {
  display: flex;
}

/* On mouse-over; add a grey background color */

#WarrantyFilter .radion-container:hover input ~ .radio-checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

#WarrantyFilter .radion-container input:checked ~ .radio-checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

#WarrantyFilter .radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

#WarrantyFilter .radion-container input:checked ~ .radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

#WarrantyFilter .radion-container .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdbb1f;
}
.filterContainer {
  overflow-y: scroll;
  height: 100%;
}
.login-input {
  width: 44%;
}

@media screen and (max-width: 768px) {
  #WarrantyFilter {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
  }
  .filter_button_container {
    /* bottom: -90px; */
    border-top: 0;
    padding: 5px;
  }
}
.error {
  margin: 0;
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .filter_button_container {
    /* bottom: -60px; */
    border-top: 0;
    padding: 5px;
  }
  /* .filterContainer{
    height: 100vh;
  } */
  .error {
    margin: 0;
  }
}
@media screen and (min-width: 1023px) {
  .filterContainer {
    height: 100vh;
    overflow-y: hidden;
  }
  .filter_button_container {
    bottom: 50px;
  }
}
@media screen and (max-width: 320px) {
  #WarrantyFilter {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }
  .filterContainer {
    height: 430px;
  }
  .filter_minmax_input_container .login-input {
    margin-right: 10px;
  }
  .filter_button_container {
    /* bottom: -60px; */
    border-top: 0;
    padding: 5px;
  }
  .error {
    margin: 10px 10px 0 0 !important;
  }
  .dropdown-toggle-ho-all-india-filter-wrapper .form-group {
    margin-bottom: 3px !important;
  }
}
