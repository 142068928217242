#secondary-point-report .secondary-report-image {
  width: 52px;
  height: 30px;
  object-fit: contain;
}

#secondary-point-report tr,
#secondary-point-report td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #6e777f;
}

#secondary-point-report .track-pop-warranty {
  position: absolute;
  background-color: white;
  /* border: 1px solid rgb(0,0,0,0.1); */
  top: -15px;
  /* padding: 10px; */
  line-height: 23px;
  z-index: 100;
  left: 130px;
  /* right: -89px; */
  /* right: -25px; */
  border: 1px solid #dbdad6;
}

#secondary-point-report .track-sensor-popup {
  position: absolute;
  background-color: white;
  /* border: 1px solid rgb(0,0,0,0.1); */
  top: -29px;
  /* padding: 10px; */
  line-height: 23px;
  z-index: 100;
  left: 90px;
  /* right: -89px; */
  /* right: -25px; */
  border: 1px solid #dbdad6;
}

#secondary-point-report .arrow_box_report {
  /* position: relative; */
  background: white;
  /* border: 4px solid #fefefe; */
}

#secondary-point-report .arrow_box_report:after,
#secondary-point-report .arrow_box_report:before {
  top: 11px;
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

#secondary-point-report .arrow_box_report:after {
  /* border-color: rgba(136, 183, 213, 0);
	border-bottom-color: white;
	border-width: 30px;
	margin-left: -30px; */
}

#secondary-point-report .arrow_box_report:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: #e6e5e5;
  border-width: 11px;
  /* margin-left: -36px; */
}

.marg-pad {
  padding-left: 5px !important;
}

#secondary-point-report .input-group-text {
  position: absolute !important;
  top: 4px !important;
  right: 1px !important;
  z-index: 101 !important;
  background-color: white !important;
  border: 0px !important;
}

#secondary-point-report .input-group-text-serch {
  color: #fdbb1f !important;
  right: -10px !important;
  padding-right: 5px;
  margin-top: 6px;
}

#secondary-point-report th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
  background-color: #f6f6f6;
}

#secondary-point-report td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#secondary-point-report th,
#secondary-point-report tr {
  /* width: 100px !important; */
  border: 1px solid rgb(0, 0, 0, 0.1);
}

#secondary-point-report td {
  border: 1px solid rgb(0, 0, 0, 0.1);
}

#secondary-point-report .warranty-block {
  border-radius: 8px;
  /* background-color: #fdbb1f4d; */
  background-color: #ffff;
  border: 1px solid #f8e184;
  /* background-image: linear-gradient(101deg, #f8e184, #f7d174); */
}

#secondary-point-report .warranty-text-bold {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#secondary-point-report .warranty-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.custom-datepicker {
  width: 40% !important;
  right: 0 !important;
  position: absolute !important;
  z-index: 2;
  padding: 10px;
}

.margin-TB-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.margin-LR-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.gradient-title-nrml {
  /* background-color: #f7b924; */
  background-image: linear-gradient(111deg, #ffdf42, #fdbb1f);
  color: white;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.gradient-sub-title_nrml {
  font-family: Helvetica;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

.fyear {
  color: #545a60;
  font-weight: normal;
  font-size: 16px;
}

.second-point-report-card-lbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #495057;
}

.second-point-report-card-lbl-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #6e777f;
}

.second-point-report-card-lbl-value {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */
  text-transform: uppercase;
  color: #495057;
}
.points_card_container {
  height: 120px;
}

@media screen and (max-width: 1200px) {
  .points_card_container {
    height: max-content;
    margin-bottom: 10px;
  }
}
.max-width-420px {
  max-width: 440px !important;
}
.point-card-value {
  font-size: 24px !important;
}
.max-width-410px {
  max-width: 410px !important;
}

.second-point-report-search::placeholder {
  text-transform: none !important;
}

.second-point-report-search::-webkit-input-placeholder {
  text-transform: none;
  font-size: 14px;
}

.backgrond-image-f8e184-without-hover {
  background-image: linear-gradient(101deg, #f8e184, #f7d174);
  border-radius: 8px !important;
}

.you-can-check-redeemed {
  font-family: Helvetica;
  font-size: 13px;
  letter-spacing: normal;
  color: #6e777f;
  padding-left: 0px;
  text-align: left;
}

.warranty-report .popover.show {
  width: 400px;
  /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  border-radius: 6px; */
  background: #fff7de;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.popover {
  max-width: 400px;
}

.warranty-report .bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #fff7de !important;
}
.warranty-report .bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #fff7de !important;
}

.warranty-report .popover-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
  padding-top: 15px;
  border-bottom: 1px solid #ced4da;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #fff7de;
}
.warranty-report .arrow {
  top: 5px !important;
  /* background: #FFF7DE; */
}
.warranty-report .bs-popover-right > .arrow::after {
  border-right-color: #fff7de;
}
.warranty-report .bs-popover-left > .arrow::after {
  border-left-color: #fff7de;
}
.warranty-report .bs-FFF7DE-left > .arrow::before {
  border-left-color: #fff7de;
  border-right-color: #fff7de;
}
.warranty-report .bs-popover-right > .arrow::before {
  border-left-color: #fff7de;
  border-right-color: #fff7de;
}

.warranty-report .popover-body {
  padding-left: 20px;
}

.warranty-report .popover-lbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
}

.warranty-report .popover-lbl-value {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #495057;
}

.box-shadow-40px-0-4 {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4) !important;
}

.box-shadow-40px-0-5 {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5) !important;
}

.warranty-report .next,
.warranty-report .prev {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #2f80ed;
}

.font-size-13px {
  font-size: 13px;
}
.warranty-report .next-btn {
  width: 70px;
  font-size: 12px !important;
  padding: 0 !important;
  height: 28px !important;
}
.warranty-report .prev-btn {
  width: 76px;
  font-size: 12px !important;
  padding: 0 !important;
  height: 28px !important;
}
.marginLeft {
  margin-left: -0.5rem !important;
}

.dealer_filter_button_container {
  bottom: 15px;
}
.addNewWarrantyButton {
  outline: none;
  border: none;
  padding: 10px 15px;
  background-color: #fdbb1f;
  font-weight: bold;
  border-radius: 50px;
  color: #495057;
  font-size: 14px;
  box-shadow: 0 16px 26px -10px rgba(253, 187, 31, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(253, 187, 31, 0.2);
}
.closeButton {
  border-radius: 50px !important;
  background-color: #c1c0c0 !important;
  padding: 9px 40px !important;
  font-weight: bold !important;
  color: #495057 !important;
  border: 1px solid #c0c0c0 !important;
  width: 43%;
}
.send-verify-Otp {
  border-radius: 50px !important;
  background-color: #fdbb1f !important;
  padding: 9px 30px !important;
  font-weight: bold !important;
  color: #495057 !important;
  border: 1px solid #fdbb1f !important;
  width: 43%;
}
.custom_modal_content {
  width: 70% !important;
  margin-left: 15% !important;
}

.custom_modal_content .modal-title {
  font-size: 16px !important;
}

.custom_modal_content .modal-header {
  background-color: white !important;
  border-bottom: 1px solid #c0c0c0 !important;
}
.custom_modal_content .modal-footer {
  background-color: white !important;
  padding: 5px !important;
}
.custom_modal_content .modal-body {
  padding-top: 0 !important;
}
.custom_modal_content .modal-footer {
  border-top: none !important;
  justify-content: space-around !important;
  margin-top: 20px;
  margin-bottom: 10px;
}

.guideButton {
  outline: none;
  border: none;
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: #fdbb1f;
  font-weight: bold;
  border-radius: 50px;
  color: #495057;
  font-size: 14px;
  margin-left: 3px;
}
.disabledGuideButton {
  outline: none;
  opacity: 0.5;
  border: none;
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: #fdbb1f;
  font-weight: bold;
  border-radius: 50px;
  color: #495057;
  font-size: 14px;
  margin-right: 3px;
}

.subdealerGuideModal {
  width: 70% !important;
  margin: auto !important;
  border: none !important;
  border-radius: 8px !important;
  /*box-shadow: 0 16px 26px -10px rgba(253, 187, 31, 0.56),
  0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(253, 187, 31, 0.2)!important; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
}

.subdealerGuideModal .modal-header {
  background-color: white !important;
  border-bottom: 0;
}

.subdealerGuideModal .modal-body {
  padding: 10px !important;
}

.subdealerGuideModal .modal-footer {
  background-color: white !important;
  padding: 10px 15px !important;
  border-top: none !important;
  justify-content: left !important;
  text-align: justify !important;
}

.custom_guidebutton-active {
  opacity: 1;
  padding: 33px;
  width: max-content;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  background-color: #fdbb1f;
  color: white;
}

.custom-guidebutton-deactive {
  opacity: 0.7;
  padding: 33px;
  width: max-content;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  background: white;
  color: #495057;
}

.smallWidthTable {
  width: 18%;
}
