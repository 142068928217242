#RetailMarketingHOStoreInformation .status {
  font-family: Helvetica;
  font-weight: normal;
  font-size: 12px;
  line-height: 14.05px;
  color: #545a60;
}
.modal-backdrop.show {
  opacity: 0.2 !important;
}
.customer-management-filter{
  overflow-y: auto !important;
}
.thumnail-img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin: 0 5px;
}
.Download-icon{
  margin-top: 0;
  margin-bottom: 10px;
}