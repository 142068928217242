#detail-card .card-body{
  padding: 10px 1rem ;
}

#detail-card .header-border{
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px
}

#detail-card .content-padding{
  padding-top: 10px
}