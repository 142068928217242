#poster-list th {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  color: #545a60;
  border: 1px solid #e9ecef;
}

#poster-list td {
  font-family: Helvetica;
  font-size: 12px;
  color: #545a60;
  border: 1px solid #e9ecef;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#poster-list thead {
  background-color: rgb(246, 246, 246);
}

#poster-list .filter-container {
  width: 325px;
  border-radius: 5px;
  border: solid 1px #ced4da;
  background-color: #fff;
  padding: 16px;
  position: absolute;
}

#poster-list .margin-top-12 {
  margin-top: 12px;
}

#poster-list .button {
  width: 48%;
  height: 35px;
  border-radius: 17.5px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #495057;
}

#poster-list .create-button {
  border: solid 1px #fdbb1f;
  background-color: #fdbb1f;
}

#poster-list .cancel-button {
  border: solid 1px #e0e2e4;
  background-color: #e0e2e4;
}

#poster-list .filter-icon {
  height: 34px;
}

#poster-list .filter-icon-selected {
  height: 34px;
}

#poster-list .padding-left-0 {
  padding-left: 0px !important;
}

#poster-list .count {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  color: #545cd8;
  cursor: pointer;
  text-align: center;
}

.poster-list-tooltip {
  /* left: 30% !important; */
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 12px;
  color: #cfaaaa;
  width: 250px;
  white-space: break-spaces;
  padding: 10px;
  text-align: left;
  background-color: #000000;
  font-size: 11px;
  opacity: 1 !important;
}

.no-data {
  color: #a9a9a9;
  border: solid 0.9px #e9ecef;
  border-top: none;
  padding: 20px;
  font-size: 16px;
  text-align: center;
}

.tbl-offtake-summary {
  width: auto !important;
}

.select-category .select {
  position: relative;
  -webkit-appearance: none;
  background: url("/images/down-arrow.svg") no-repeat right #fff;
  background-position-x: 96%;
  font-family: Helvetica;
}

.drop-down-control {
  font-size: 12px !important;
}
