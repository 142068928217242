.form-group-space-between {
  margin-bottom: 0;
  height: 83%;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

.select-height-70rem {
  height: calc(1.5em + 0.7rem) !important;
}

.dealership-details-title-wrapp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.auto-suggest .react-autosuggest__container {
  width: 100%;
}

.auto-suggest {
  width: 100%;
}

.layout-stage table thead th {
  max-width: 100px;
}

.project-stage-status-tbl {
  max-height: 275px;
  overflow: auto;
}
