#dashboard-mobile .page-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard-mobile .gradient-title{
  background-image: linear-gradient(to right, rgba(30, 87, 248, 0.55), #491b9f);
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* background-color: #5583b3; */
}

#dashboard-mobile .gradient-sub-title{
  font-family: Helvetica;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

#dashboard-mobile .warranty-block {
  border-radius: 8px;
  background-color: #ffff;
  border: 1px solid #f8e184;
}

#dashboard-mobile .warranty-text-bold {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard-mobile .warranty-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#dashboard-mobile .warranty-sub-text {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}

#dashboard-mobile .text-input {

  border-radius: 24px !important;
  background-color: #ffffff !important;
}

#dashboard-mobile .dashboard-page-card-title-big {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#dashboard-mobile .count-big{
  width: 109px;
  height: 34px;
  font-family: Helvetica;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #545cd8;
}

#dashboard-mobile .dashboard-page-card-title-small-gray {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #92969a;
}

#dashboard-mobile .dashboard-page-title {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}


#dashboard-mobile .image {
  width: 54px;
  height: 28px;
  object-fit: contain;
}

#dashboard-mobile .count-small {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #545cd8;
    margin-top: 20px;
}

#dashboard-mobile .card-count{  
    font-family: Helvetica;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 28px;
    letter-spacing: normal;
    /* text-align: right; */
    color: #495057;
}

#dashboard-mobile .card-count-title{
   
    font-family: Helvetica;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #6f777e;
    font-weight: bold;
    margin-top: 5px;
}

#dashboard-mobile .card-count-period{
  
  font-family: Helvetica;
  /* margin-top: 5px; */
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #6f777e;
}

#dashboard-mobile .count-block-border{
  border: solid 1px rgba(253, 187, 31, 0.25);
  padding:5px 0px;
}

#dashboard-mobile .yearly-offtake-link {
  /* width: 144px;
  height: 19px; */
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  text-decoration: underline;
}

#dashboard-mobile .text-align-left {
  text-align: left !important;
}

#dashboard-mobile .content-center{
  display: flex;
  vertical-align: middle;
}

#dashboard-mobile  .seconadary-warranty {

  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  text-align: center;
}

#dashboard-mobile .warranty-background{
  background-image: url('/images/group-26.svg');
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: center;
}

#dashboard-mobile .padding-bottom-10{
  padding-bottom: 10px;
}

#dashboard-mobile .no-warranty-points {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: oblique;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: center;
  color: #6e777f;
}