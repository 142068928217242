#profile-detail .user-name {
  font-family: Helvetica;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
}

#profile-detail .request-send-text-container {
  border-radius: 5px;
  background-color: rgba(181, 186, 190, 0.42);
  padding: 5px 0px 4px 7px;
  margin-bottom: 8px;
}

#profile-detail .request-send-text{
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  width: 85%;
  line-height: 22px;
  margin-left: 6px;
}

#profile-detail .profile-image {
  min-width: 125px;
  min-height: 125px;
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 89.5px;
  border: solid 1px #b5babe;
}

#profile-detail .edit-icon{
  background-color: #fdbb1f;
  height: 37.6px;
  width: 37.6px;
  border-radius: 50%;
  line-height: 33.6px;
  cursor: pointer;
}

#profile-detail .req-button{
  color: #fdbb1f;
  line-height: 30px;
  border-top: 1px solid #e5e5e5; 
  font-weight:bold;
  font-size: 14px ;
  cursor: pointer;
  margin-bottom: -8px;
}

#profile-detail .req-button-container{
  /* position: absolute;
  bottom: 0px;
  width: 95%; */
  margin-top: 15px;
}

#profile-detail .req-button-container-mobile{
  position: relative;
}

#profile-detail .profile-spinner{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#profile-detail .link{
  /* text-decoration: underline; */
  color: #545cd8;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

#profile-detail .create-icon-con{
  margin-top: -54px;
    margin-right: -64px;
}