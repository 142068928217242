#dealer-dashboard {
  font-family: Helvetica;
  height: 116%;
}

.pl-7px {
  padding-left: 7px !important;
}

.pr-7px {
  padding-right: 7px !important;
}

.backgrond-image-f8e184 {
  background-image: linear-gradient(101deg, #f8e184, #f7d174);
  border-radius: 8px !important;
}

.card-box-shadow-10px {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05) !important;
}

.backgrond-image-f8e184:hover {
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.25) !important;
  cursor: pointer !important;
}

.backgrond-image-4270f3 {
  background-image: url("/images/scheme-bg.svg");
  background-size: 192%;
  background-position: 50%;
  border-radius: 8px !important;
}

.backgrond-image-4270f3:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.total-customer-small-lbl {
  border-radius: 8px;
  background-color: #547def;
  margin-left: 15px;
  padding-left: 10px;
  height: 26px;
  display: flex;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  width: 33%;
}

.dealer-dashobard-card-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.dealer-dashobard-card-sbtitle {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.dealer-dashobard-card-text {
  margin-top: 20px;
  font-family: Helvetica;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.font-size-14px {
  font-size: 14px;
}

.color-ffffff {
  color: #ffffff;
}

.padding-13px {
  padding: 13px !important;
}

.dealer-card-point {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.margin-bottom-13px {
  margin-bottom: 13px;
}

.margin-bottom-5px {
  margin-bottom: 5px;
}

.color-495057 {
  color: #495057 !important;
}

.background-color-495057 {
  background-color: #f8f9fa !important;
}

.border-right-E1CD7B {
  border-right: 1px solid #e1cd7b;
}

.border-right-ECEDEE {
  border-right: 1px solid #ecedee;
}

.border-left-ECEDEE {
  border-left: 1px solid #ecedee;
}

.border-radius-8px {
  border-radius: 8px !important;
}

.nine-one-rect {
  width: 45px !important;
  height: 35px !important;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: #ffffff !important;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.49;
  letter-spacing: normal;
  color: #6e777f !important;
  padding-left: 7px !important;
  background-color: #e0e2e4 !important;
}

.enter-number-otp {
  background-color: #ffffff !important;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.49;
  letter-spacing: normal;
  color: #6e777f !important;
  padding-left: 7px !important;
  min-width: 205px;
}

input[type="text"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
}

input[type="text"]::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
}

input[type="text"]:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px;
}

input[type="text"]:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px;
}

.react-multi-carousel-item {
  display: flex;
}

.react-multiple-carousel__arrow {
  border-radius: 9px;
  background-color: #f8f9fa;
  height: 90%;
  min-width: 30px;
}

.react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #fdbb1f;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
  font-weight: bold;
}

.react-multiple-carousel__arrow:hover {
  background: #e9ecef;
}

.dealer-banner-img {
  width: 71px;
  height: 60px;
  object-fit: contain;
}

.dealer-banner-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

.dealer-banner-desc {
  opacity: 0.9;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #292929;
}

.grow-business-div {
  width: 85%;
  height: 68px;
  border-radius: 9px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.react-multiple-carousel__arrow--left {
  left: 0;
}

.react-multiple-carousel__arrow--right {
  right: 0;
}

.react-multi-carousel-dot-list {
  margin-bottom: -3px;
}

.react-multi-carousel-dot--active button {
  background: #fdbb1f;
}

.grow-business-img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.grow-business-desc {
  font-family: Helvetica;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
}

.You-have-earned-134 {
  font-family: Helvetica;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #495057;
}

.grow-business-carousel-container {
  padding-bottom: 25px;
  /* width: 77%; */
}

/* 
.grow-business-carousel-container .react-multi-carousel-track {
  width: 115%;
} */

.grow_business_list_data {
  /* margin-left: auto;
  margin-right: auto; */
  width: 100%;
}

.grow_business_list_data:hover {
  cursor: pointer;
}

.grow-business-carousel-container .react-multiple-carousel__arrow--right {
  right: -7px;
}

.grow-business-carousel-container .react-multi-carousel-dot-list {
  margin-bottom: -0px;
}

.grow-business-carousel-container .react-multiple-carousel__arrow {
  height: 75%;
  background-image: url("/images/right_arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  right: 0;
  background-size: 20px;
}

.grow-business-carousel-container .react-multiple-carousel__arrow--left {
  height: 75%;
  background-image: url("/images/right_arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  right: 0;
  transform: rotate(180deg);
  background-size: 20px;
}

.carousel-container-banner .react-multiple-carousel__arrow {
  height: 86%;
  background-image: url("/images/right_arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  right: 0;
  background-size: 20px;
}

.carousel-container-banner .react-multiple-carousel__arrow--left {
  height: 90%;
  background-image: url("/images/right_arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  right: 0;
  transform: rotate(180deg);
  background-size: 20px;
}

.carousel-container-banner .react-multiple-carousel__arrow::before {
  display: none;
}

.grow-business-carousel-container .react-multiple-carousel__arrow::before {
  display: none;
}

.Showing-Counts-for-F {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a60;
}

.carousel-container {
  padding-bottom: 15px;
}

.custom-dropdown .custom-dropdown-selected-lbl {
  border-radius: 13px !important;
  background-color: #f1f5fe !important;
  padding-left: 13px !important;
  padding-right: 16px !important;
  color: #545cd8 !important;
  font-weight: bold !important;
  font-size: 10px !important;
}

.custom-dropdown .dropdown-item {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a5157;
}

.custom-dropdown .dropdown-menu::before {
  display: none;
}

.custom-dropdown .dropdown-item {
  border-bottom: 1.4px solid #ededee;
}

.custom-dropdown .dropdown-menu {
  padding: 0px;
}

.custom-dropdown .dropdown-menu::after {
  display: none !important;
}

.custom-dropdown .dropdown-menu::after {
  display: none !important;
}

.custom-dropdown .dropdown-menu.show {
  z-index: 10000;
}

.card-box-shadow-hover-30px:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25) !important;
  cursor: pointer !important;
}

.bannner-item {
  width: 100px !important;
}

.notification-oval {
  width: 7px;
  height: 7px;
  background-color: #426ed8;
  margin-top: 2px;
  position: absolute;
  border-radius: 50%;
  margin-left: 17%;
}

.Apr---Jun209 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: right;
  color: #495057;
  mix-blend-mode: normal;
  opacity: 0.75;
}

.Please-provide-custo {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

.slider-grow-business {
  width: 105%;
}

.slider-banner {
  width: 99%;
}

.dropdown-item.active {
  background-color: #f1f5fe !important;
  color: #545cd8 !important;
}

.padding-left-7px {
  padding-left: 7px !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto !important;
}

.slick-active .div-banner {
  height: 100%;
}

.slick-slide div {
  height: 100%;
}

.div-banner {
  min-height: 120px;
}

.slider-banner .slick-list {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px;
}

.dealer-popover .popover.show {
  width: 320px;
  /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  border-radius: 6px; */
  background: #fff7de;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.dealer-popover .bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #fff7de !important;
}
.dealer-popover .bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #fff7de !important;
}

.dealer-popover .popover-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
  padding-top: 15px;
  border-bottom: 1px solid #ced4da;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #fff7de;
}
.dealer-popover .arrow {
  top: 5px !important;
  /* background: #FFF7DE; */
}
.dealer-popover .bs-popover-right > .arrow::after {
  border-right-color: #fff7de;
}
.dealer-popover .bs-popover-left > .arrow::after {
  border-left-color: #fff7de;
}
.dealer-popover .bs-popover-left > .arrow::before {
  border-left-color: #fff7de;
  border-right-color: #fff7de;
}
.dealer-popover .bs-popover-right > .arrow::before {
  border-left-color: #fff7de;
  border-right-color: #fff7de;
}

.dealer-popover .popover-body {
  padding-left: 20px;
}

.dealer-popover .popover-lbl {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
}

.dealer-popover .popover-lbl-value {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #495057;
}

.box-shadow-40px-0-4 {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4) !important;
}

.box-shadow-40px-0-5 {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5) !important;
}

.dealer-popover .next,
.dealer-popover .prev {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #2f80ed;
}

.font-size-13px {
  font-size: 13px;
}
.dealer-popover .next-btn {
  width: 70px;
  font-size: 12px !important;
  padding: 0 !important;
  height: 28px !important;
}
.dealer-popover .prev-btn {
  width: 76px;
  font-size: 12px !important;
  padding: 0 !important;
  height: 28px !important;
}
#not-set-pref {
  background: #ffffff;
  border: 1px solid #fdbb1f;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0;
}
#not-set-pref .setting-icon-wrapp {
  width: 51px;
  height: 50px;
  background: #fdbb1f;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
#not-set-pref .owner-name {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #495057;
}
#not-set-pref .to-enable {
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #495057;
}

#not-set-pref .click-here {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #545cd8;
}

#ModalServicePref .header-note {
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6e777f;
}

#ModalServicePref table th {
  background: #f6f6f6;
  border: 0.85px solid #e9ecef;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}

#ModalServicePref table td {
  border: 1px solid #e9ecef;
}
#ModalServicePref .vehicle-type-note {
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  color: #6e777f;
}

#ModalServicePref .commercial-personal {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #545a60;
}

/* dropdown checkbox */
#ModalServicePref .custom-drop-down .container-checkbox .checkmark:after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

#ModalServicePref .custom-drop-down .checkmark {
  display: inline;
  line-height: 30px;
  height: 13px !important;
  width: 13px !important;
  border: 1px solid #f7b924 !important;
  border-radius: unset !important;
}

#ModalServicePref .custom-drop-down {
  position: absolute;
  z-index: 105;
  background-color: white;
  width: 100%;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.37);
  /* padding: 5px; */
  margin-top: 3px;
  border-radius: 5px;
}
#ModalServicePref .custom-drop-down-item {
  padding: 10px;
  border-bottom: 1px solid #e9ecef;
}

#ModalServicePref .custom-drop-down-arrow {
  position: absolute;
  top: 6px;
  right: 11px;
}
#ModalServicePref .dropdown-vehicle-name {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
}
#ModalServicePref .dropdown-vehicle-no-name {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6e777f;
}
#ModalServicePref .no-span-req {
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6e777f;
  opacity: 0.8;
}
