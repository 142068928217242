#entry-screen-catalog .Illustration {
  width: 100%;
  padding: 10px;
  object-fit: contain;
  max-width: 803px;
  max-height: 470px;
}
#entry-screen-catalog.image {
  background-color: white;
  border-radius: 12px;
  /* background-image:url('/images/illustration.png'); */
  /* height: 100vh; */
  /* background-size: cover; */
}

#entry-screen-catalog .logo {
  /* position: absolute; */
  top: 80px;
  left: 80px;
  margin-top: 110px;
  /* margin-left: 80px; */
}

#entry-screen-catalog .title{
  /* width: 399px; */
  /* height: 147px; */
  margin-top: 44px;
  font-family: Helvetica;
  font-size: 25px;
  /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  /* position: absolute; */
  /* top: 166px; */
  /* margin-left: 80px; */
}


#entry-screen-catalog .sub-title{
  width: 399px;
  /* height: 147px; */
  font-family: Helvetica;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gunmetal);
  /* position: absolute; */
  /* top: 166px; */
  /* margin-left: 80px; */
  margin-top: 8px;
}

#entry-screen-catalog .redemption-button{
  width: auto;
  margin-top: 70px;
  height: auto;
  border-radius: 35px !important;
  padding: 6px 33px;
  cursor: pointer;
  /* margin-left: 36px !important; */
}


#entry-screen-catalog .link{
  color: #545cd8;
  margin-top: 30px;
  text-decoration: underline;
  font-size: 12px;
}

