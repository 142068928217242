#RetailMarktingUploadPhotograph .upload-photo-div {
  background: #ffffff;
  border: 2px solid #dadfec;
  border-radius: 6px;
  width: 125px;
  height: 125px;
}
#RetailMarktingUploadPhotograph .upload-photo-div:hover {
  cursor: pointer;
}

#RetailMarktingUploadPhotograph .upload-photo-wrapper {
  margin-left: 8px;
  margin-right: 8px;
}

#RetailMarktingUploadPhotograph .add-more {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #545cd8;
}

#RetailMarktingUploadPhotograph .count {
  position: absolute;
  margin-left: 8px;
}
#RetailMarktingUploadPhotograph .photo-view {
  border: 2px solid #dadfec;
  border-radius: 6px;
  width: 123px;
  height: 123px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

#RetailMarktingUploadPhotograph .photo-view:hover {
  cursor: pointer;
}

#RetailMarktingUploadPhotograph .photo-lbl {
  font-size: 13px;
}
#RetailMarktingUploadPhotograph .edit-photo {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

#RetailMarktingUploadPhotograph .delete-board {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #545cd8;
}
#RetailMarktingUploadPhotograph .note {
  font-size: 14px;
  font-weight: normal;
}
#RetailMarktingUploadPhotograph .prview-photo-note {
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #838c95;
  margin-left: 11px;
}
