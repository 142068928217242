.cd-breadcrumb, .cd-multi-steps {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: .5em 1em;
  margin: 1em auto;
  background-color: #edeff0;
  border-radius: .25em
}

.cd-breadcrumb::after, .cd-multi-steps::after {
  clear: both;
  content: "";
  display: table
}

.cd-breadcrumb li, .cd-multi-steps li {
  display: inline-block;
  float: left;
  margin: .5em 0
}

.cd-breadcrumb li::after, .cd-multi-steps li::after {
  display: inline-block;
  content: '\00bb';
  margin: 0 .6em;
  color: #959fa5
}

.cd-breadcrumb li:last-of-type::after, .cd-multi-steps li:last-of-type::after {
  display: none
}

.cd-breadcrumb li>*, .cd-multi-steps li>* {
  display: inline-block;
  font-size: 14px;
  color: #2c3f4c;
}

.cd-breadcrumb li.current>*, .cd-multi-steps li.current>* {
  color: #96c03d
}

.no-touch .cd-breadcrumb a:hover, .no-touch .cd-multi-steps a:hover {
  color: #96c03d
}

.cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
  content: '';
  height: 16px;
  width: 16px;
  /* background: url(../img/cd-custom-separator.svg) no-repeat center center; */
  vertical-align: middle
}

.cd-breadcrumb.custom-icons li>*::before, .cd-multi-steps.custom-icons li>*::before {
  content: '';
  display: inline-block;
  height: 20px;
  width: 8px;
  /* margin-right: .4em; */
  margin-top: -2px;
  /* background: url(../img/cd-custom-icons-01.svg) no-repeat 0 0; */
  vertical-align: middle
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(2)>*::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(2)>*::before {
  background-position: -20px 0
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(3)>*::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(3)>*::before {
  background-position: -40px 0
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(4)>*::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(4)>*::before {
  background-position: -60px 0
}

.cd-breadcrumb.custom-icons li.current:first-of-type>*::before, .cd-multi-steps.custom-icons li.current:first-of-type>*::before {
  background-position: 0 -20px
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(2)>*::before, .cd-multi-steps.custom-icons li.current:nth-of-type(2)>*::before {
  background-position: -20px -20px
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(3)>*::before, .cd-multi-steps.custom-icons li.current:nth-of-type(3)>*::before {
  background-position: -40px -20px
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(4)>*::before, .cd-multi-steps.custom-icons li.current:nth-of-type(4)>*::before {
  background-position: -60px -20px
}

@media only screen and (min-width:768px) {
  .cd-breadcrumb, .cd-multi-steps {
    padding: 0 1.2em
  }
  .cd-breadcrumb li, .cd-multi-steps li {
    margin: 1.2em 0
  }
  .cd-breadcrumb li::after, .cd-multi-steps li::after {
    margin: 0 1em
  }
  .cd-breadcrumb li>*, .cd-multi-steps li>* {
    font-size: 14px;
  }
}

@media only screen and (min-width:768px) {
  .cd-breadcrumb.triangle {
    background-color: transparent;
    padding: 0
  }
  .cd-breadcrumb.triangle li {
    position: relative;
    padding: 0;
    margin: 4px 4px 4px 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .cd-breadcrumb.triangle li:last-of-type {
    margin-right: 0
  }
  .cd-breadcrumb.triangle li>* {
    position: relative;
    padding: 5px;
    color: #2c3f4c;
    background-color: #edeff0;
    border-color: #edeff0;
    height: 100%;
    font-size: 12px;
    text-align: center;
    min-height: 54px;
  }
  .cd-breadcrumb.triangle li.current-before>* {
    color: #000000;
    background-color: #96c03d;
    border-color: #96c03d
  }
  .cd-breadcrumb.triangle li.current>* {
    color: #000000;
    background-color: #fdbb1f;
    border-color: #fdbb1f
  }
  .cd-breadcrumb.triangle li:first-of-type>* {
    /* padding-left: 1.6em; */
    border-radius: .25em 0 0 .25em
  }
  .cd-breadcrumb.triangle li:last-of-type>* {
    padding-right: 1.6em;
    border-radius: 0 .25em .25em 0
  }
  .no-touch .cd-breadcrumb.triangle a:hover {
    color: #fff;
    background-color: #2c3f4c;
    border-color: #2c3f4c
  }
  .cd-breadcrumb.triangle li::after, .cd-breadcrumb.triangle li>*::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    border: 24px solid transparent;
    border-right-width: 0;
    border-left-width: 15px
  }
  .cd-breadcrumb.triangle li::after {
    z-index: 1;
    -webkit-transform: translateX(4px);
    -moz-transform: translateX(4px);
    -ms-transform: translateX(4px);
    -o-transform: translateX(4px);
    transform: translateX(4px);
    border-left-color: #fff;
    margin: 0
  }
  .cd-breadcrumb.triangle li>*::after {
    z-index: 2;
    border-left-color: inherit
  }
  .cd-breadcrumb.triangle li:last-of-type::after, .cd-breadcrumb.triangle li:last-of-type>*::after {
    display: none
  }
  .cd-breadcrumb.triangle.custom-separator li::after {
    background-image: none
  }
  .cd-breadcrumb.triangle.custom-icons li::after, .cd-breadcrumb.triangle.custom-icons li>*::after {
    border-top-width: 29px
    /* border-bottom-width: 15px */
  }
  @-moz-document url-prefix() {
    .cd-breadcrumb.triangle li::after, .cd-breadcrumb.triangle li>*::after {
      border-left-style: dashed
    }
  }
}

@media only screen and (min-width:768px) {
  .no-touch .cd-breadcrumb.triangle.custom-icons li:first-of-type a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:first-of-type em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:first-of-type a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:first-of-type em::before {
    background-position: 0 -40px
  }
  .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(2) a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(2) em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(2) a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(2) em::before {
    background-position: -20px -40px
  }
  .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(3) a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(3) em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(3) a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(3) em::before {
    background-position: -40px -40px
  }
  .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(4) a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(4) em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(4) a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(4) em::before {
    background-position: -60px -40px
  }
}