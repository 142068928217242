.user-box-area-manager {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#user-box.modal-dialog {
  max-width: 380px;
  margin: 1.75rem auto;
  position: absolute;
  top: 40px;
  right: 10px;
}


#user-box .modal-content {
  border: none;
}

#user-box .modal-backdrop.show {
  opacity: 0;
}

#user-box .modal-body{
  padding: 0px;
}

.user-box-backdrop{
  opacity: 0 !important;
}

#user-box .user_box_arrow_box {
  position: relative;
  background: white;
  /* border: 4px solid #fefefe; */
}

#user-box .user_box_arrow_box:after,#user-box  .user_box_arrow_box:before {
  bottom: 100%;
  right: 30% !important; 
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

#user-box .user_box_arrow_box:after {
  /* border-color: rgba(136, 183, 213, 0);
	border-bottom-color: white;
	border-width: 30px;
	margin-left: -30px; */
}

#user-box .user_box_arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: white;
  border-width: 15px;
  /* margin-left: -36px; */
}

#user-box .title {
  width: 250px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#user-box .button-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

#user-box .progress-bar-container {
  width: 100%;
  border-radius: 4px;
  background-color: #e9ecef;
}

#user-box .progress-bar {
  color: #495057;
  font-weight: bold;
}

#user-box .btn-secondary{
  background-color: transparent;
  text-align: left;
  border: none;
}

#user-box .user-detail{
  display: inline-flex;
    flex-direction: column;
    vertical-align: middle;
    margin-left: 10px;
}

#user-box .dropdown-menu .dropdown-item{
  padding: 0px;
  width: 380px;
}

#user-box .card-width{
  width: 380px;
}

#user-box .dropdown-menu{
  padding: 0px;
  top:4px !important;
}

#user-box .dropdown-menu::before,#user-box .dropdown-menu::after {
  right: 50px;
  top: -5px;
  width: 28px;
  left: auto;
}

