.bg-white {
  background-color: #ffffff;
}

.header-right-menu-fonts {
  font-weight: bold;
  width: 110px;
}

.header-menus {
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 50%;
}

#header .button-text {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545cd8;
}

#header .title {
  width: 250px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  white-space: pre-wrap;
}

#header .scroll-area-xs {
  height: auto;
  overflow-x: hidden;
}

#header .progress-bar-container {
  width: 100%;
  border-radius: 4px;
  background-color: #e9ecef;
}

#header .progress-bar {
  color: #495057;
  font-weight: bold;
}

.arrow_box {
  position: relative;
  background: white;
  /* border: 4px solid #fefefe; */
}

.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 18%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  /* border-color: rgba(136, 183, 213, 0);
	border-bottom-color: white;
	border-width: 30px;
	margin-left: -30px; */
}

.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: white;
  border-width: 15px;
  /* margin-left: -36px; */
}

#header .user-name {
  /* width: 103px;
  height: 17px; */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#header .sap-id {
  width: 54px;
  height: 17px;
  opacity: 0.5;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#header .arrow-color {
  color: rgba(0, 0, 0, 0.1);
}

.notification-desc {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #6e777f;
  white-space: initial;
}

.notification-date-time {
  opacity: 0.7;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
}

#notification-box .dropdown-menu .dropdown-item {
  padding: 0px;
  width: 553px;
}

#notification-box .card-width {
  width: 553px;
}

#notification-box .dropdown-menu {
  padding: 0px;
  top: 4px !important;
  left: -178px !important;
}

#notification-box .dropdown-menu::before,
#notification-box .dropdown-menu::after {
  right: 91px;
  top: -5px;
  width: 28px;
  left: auto;
  height: 24px;
}

#notification-box .btn-secondary {
  background-color: transparent;
  text-align: left;
  border: none;
}

.box-shadow-40px-0-5 {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5) !important;
}
