#dealerProfileModal.modal-dialog {
  max-width: 95%;
}
#dealerProfileModal .modal-header .close{
  font-size: 40px;
  font-weight: normal;
  color: #fdbb1f;
}
#dealerProfileModal .modal-title{
  font-size: 18px;
}