.fleet-truck-img {
  height: 60px;
  object-fit: contain;
}

.border-right-1px {
  border-right: 1px solid rgba(0, 0, 0, .1);
}

.min-add-btn {
  margin-top: -2px;
  color: blue
}

.mt-3px {
  margin-top: 3px;
}

#customer-intraction .address {
  min-height: 122px;
}

#customer-intraction .react-datepicker-wrapper {
  z-index: unset;
}

#customer-intraction .table th {
  font-size: 13px;
  color: #545a60;
}

#customer-intraction .table td {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  color: #6e777f;
}

#customer-intraction .react-autosuggest__input {
  padding-left: 5px;
}

.min-width-100px {
  min-width: 100px;
}

.min-width-120px {
  min-width: 120px;
}

.min-width-245px {
  min-width: 245px;
}

#customer-intraction .form-control {
  font-size: 14px !important;
}

.font-size-14px {
  font-size: 14px !important;
}

#customer-intraction .business-related-detail-td {
  vertical-align: top;
}

#customer-intraction .react-autosuggest__suggestions-container--open {
  overflow: auto;
  max-height: 352px;
}

#customer-intraction .react-autosuggest__input {
  color: #495057;
  font-size: 14px !important;
}

@media screen and (min-width: 220px) and (max-width: 425px) {
  #customer-intraction .page-header {
    font-size: 18px;
  }
  #customer-intraction .back-wrapp-cip-inst {
    justify-content: space-between;
    display: flex;
    margin-top: 17px;
  }
  #customer-intraction .back-cip-summary-link {
    width: 70%;
  }
  #customer-intraction .cip-summary-dealer-name {
    width: 100%;
  }
  .next-atendee-wrapp {
    display: flex;
  }
}