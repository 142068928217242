#coupon .scorcard {
  position: absolute !important;
  left: 40%;
  top: 25%;
  z-index: 9999;
  /* right: 29%; */
  width: 20% !important;
}

#coupon .label-redeem {
  font-size: 14px !important;
  padding-top: 10px;
  margin-bottom: -6px;
  padding-left: 25px;
}

#coupon .border {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: -15px;
  margin-right: -15px;
}

#coupon .label-header {
  font-family: Helvetica;
  color: #495057;
  font-family: Helvetica;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

#coupon .label-select {
  font-family: Helvetica;
  color: #6e777f;
}

#coupon .wrapper-scorcard {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

#coupon .modelCard {
  width: '50%' !important;
}

#redeem-coupon .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  margin-left: 60px !important;
}

#redeem-coupon .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0px;
}