.event-page{
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
box-shadow: -2px 1px 12px rgba(0, 0, 0, 0.118801);
border-radius: 8px;
padding-top: 18px;
padding-bottom: 18px;
max-width: 500px;
}
.customer-registration-tag{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  color: #495057;
  margin-top: 20px;
}

#event-registration .row{
  margin-left: 0px;
  margin-right: 0px;
}

#event-registration .city-type{
  width: 216% !important;
}
#event-registration .rbt-menu .dropdown-menu{
  width: 20% !important;
}
.swal-text{
  font-weight: bold !important;
}
#event-registration ul{

  min-width: 183px !important; 
  position: absolute !important;
 display: block !important;
 max-height: 300px !important;
 overflow: auto !important;
 will-change: transform !important;
 top: 0px !important;
 left: 0px !important;
 transform: translate3d(0px, 35px, 0px) !important;
 z-index: 1000 !important;
   /* transform: auto !important; 
   
  width: 15% !important; 
   max-height: 170px !important;  */
}

@media only screen and (max-width:360px){
  #event-registration .city-type{
    
    width: 144% !important;
  }
  .popover, .dropdown-menu {
    position: absolute;
   display: block;
   max-height: 300px;
   overflow: auto;
   will-change: transform;
   top: 0px;
   left: 0px;
   transform: translate3d(0px, 35px, 0px);
   z-index: 1000;
  }
}
.offer-detail{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
  margin-top: 16px;
}
.personal{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  margin-top: 24px;
  color: #6E777F;
}
.title-heading{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #6E777F;
  white-space: nowrap;
}

.straight-line{
    width: 90.4%;
    margin-left: 9px;
    align-self: center;
  }
  .hrizontal-line{
    opacity: 0.4;
    border: 1px solid #FDBB1F;
    margin-top: 8px;
    height: 0px;
}