#logout-modal .modal-title{
  font-family: Helvetica;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  
}

#logout-modal .modal-text{
  
    /* width: 439px;
    height: 17px; */
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    /* text-align: center; */
    color: #495057;
  
}

/* #logout-modal .modal{
  display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
} */

#logout-modal .modal-dialog {
  width: 100% !important; 
}