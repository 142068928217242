#editGroupDetail .Group-3 {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-top: 10px;
  margin-left: -25px;
  /* margin-left: 220px; */
}

#editGroupDetail .search {
  display: block;
  width: 96%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

#editGroupDetail .login-input {
  width: 324px !important;
  height: calc(1.2em + 0.75rem + 2px) !important;
  border-radius: 4px !important;
  border: solid 1px #ced4da !important;
  background-color: var(--white) !important;
}

.addnewgroup-filter {
  cursor: pointer;
  margin-left: 19px;
  width: 16.8px;
  height: 15.9px;
  object-fit: contain;
  background-color: var(--orangey-yellow);
  /* margin-top: 10px; */
}

#editGroupDetail .group_name {
  height: calc(1.2em + 0.75rem + 2px) !important;
}

#editGroupDetail input,
input::-webkit-input-placeholder {
  font-size: 12px;
}

#editGroupDetail .member-count {
  height: 14px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545cd8;
  float: right;
  cursor: auto;
}

#editGroupDetail .delete {
  cursor: pointer;
  margin-left: 54px;
  object-fit: contain;
  background-color: var(--orangey-yellow);
  margin-top: 4px;
}

#editGroupDetail .remove {
  width: 53px;
  height: 17px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #545a60;
}
#editGroupDetail .range,
#editGroupDetail .showing-text,
#editGroupDetail .pagination-button {
  font-size: 14px !important;
}
