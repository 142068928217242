#RetailMarketingStoreInformation .react-autosuggest__suggestions-list {
  max-height: 350px;
  overflow: auto;
  font-weight: 400;

}
.upload-photo-wrapper{
  margin-left: 8px;
  margin-right: 8px;
}
.upload-photo-div {
  background: #ffffff;
  border: 1px solid #dadfec;
  border-radius: 6px;
  width: 125px;
  height: 125px;
}
.footer-drawer {
  margin-top: 12px;
  background-color: whitesmoke;
  padding-top: 12px;
  padding-bottom: 12px;
}
.photo-view{
  border: 1px solid #dadfec;
    border-radius: 6px;
    width: 123px;
    height: 123px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}
.date-picker{
  z-index: 1;
}
/* .signage-save-button{
  background: #545CD8 !important;
  width: 120px;
} */
