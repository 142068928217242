#custom-time-picker .form-group-input-h-m {
  width: 45px;
}

#custom-time-picker Input {
  font-size: 16px !important;
}

.ml-13px {
  margin-left: 13px;
}

.time-colon {
  color: #FCBC34;
  font-size: 50px;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-set-cancel {
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 16px;
}

.btn-set-cancel:hover {
  background-color: rgba(0, 0, 0, .1);
  cursor: pointer;
}

.am-pm-lbl {
  border: 1px solid #FCBC34;
  border-radius: 2px;
  transition: background .2s;
  margin: auto;
  margin-top: 23px;
  margin-left: 15px;
  color: #FCBC34;
  padding: 7px;
  font-size: 17px;
}

.am-pm-lbl:hover {
  background-color: #FCBC34;
  color: #fff;
  cursor: pointer;
}

.clock-icon {
  position: absolute;
  top: 10px;
  color: #757575;
  right: 10px
}