.redemption-catalogue-v2-merchandise-wallet-count-card-redemption {
  background-image: linear-gradient(120deg, #ffdf42, #fdbb1f);
  height: 100px;
  width: 40%;
}
.redemption-catalogue-v2-merchandise-wallet-count-lbl-wrapp {
  padding: 15px !important;
  padding-top: 15px;
}
.redemption-catalogue-v2-merchandise-text {
  font-family: Helvetica;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e777f;
}
.redemption-catalogue-v2-merchandise-count {
  font-family: Helvetica;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 40px;
  color: #495057;
  margin-top: 5px;
}
.redemption-catalogue-v2-gift-catalogue-wrapper {
  min-height: 100vh;
  background-color: #fff;
  margin-top: -29px;
  padding-top: 15px;
  margin-bottom: -30px;
  padding-bottom: 30px;
  padding: 15px 40px;
}
.redemption-catalogue-v2-catalogue-card-container {
  display: grid;
  grid-template-columns: repeat(3, 0.7fr);
  gap: 30px;
  margin-top: 30px;
}
.redemption-catalogue-v2-catalogue-card {
  /* border: 1px solid #ccc; */
}
.redemption-catalogue-v2-catalogue-card-image {
  height: 320px;
  object-fit: contain !important;
  width: 100%;
}
.redemption-catalogue-v2-card-heading {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.44px;
  color: black;
  text-align: center;
  margin-bottom: 6px;
}
.redemption-catalogue-v2-card-heading.main-heading{
  font-size: 18px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.redemption-catalogue-v2-card-heading.label-text{
  color: var(--battleship-grey);;
}
.redemption-catalogue-v2-card-details {
  color: #6e777f;
  font-size: 13px;
}
.redemption-catalogue-v2-list-wrapper {
  height: 165px;
  margin: 0px 0px;
  border-bottom: 1px solid #ccc;
  padding: 0 30px;
}
.redemption-catalogue-input {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 55%;
}
.redemption-catalogue-term-condition-heading {
  font-size: 28px;
  font-weight: 700;
}
.redemption-catalogue-v2-submit {
  background-color: #ffdf42;
  color: black;
  padding: 10px 70px;
  border: none;
  outline: none;
  border-radius: 5px;
}
.redeptiom-catalog-v2 {
  width: 60%;
}

@media screen and (max-width: 940px) {
  .redemption-catalogue-v2-catalogue-card-image {
    height: 200px;
  }
  .redemption-catalogue-v2-list-wrapper {
    height: max-content;
    padding-bottom: 15px;
  }
  .redemption-catalogue-v2-gift-catalogue-wrapper {
    padding: 15px 10px;
    margin-top: 10px;
  }
  .redemption-catalogue-v2-catalogue-card-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .redemption-catalogue-v2-merchandise-wallet-count-card-redemption {
    width: 100%;
  }
  .redeptiom-catalog-v2 {
    width: 100%;
  }
}

@media screen and (min-width: 941px) and (max-width: 1051px) {
  .redemption-catalogue-v2-list-wrapper {
    height: 250px;
  }
}

@media only screen and (min-width: 1052px) and (max-width: 1360px) {
  .redemption-catalogue-v2-list-wrapper {
    height: 210px;
  }
}
